import { Middleware, SWRResponse } from 'swr';

export function augmentSWR<Data = any, Error = any>(
  alias: string,
  selector: (swr: SWRResponse<Data, Error>) => any,
): Middleware {
  return useSWRNext => {
    return (key, fetcher, config) => {
      const swr = useSWRNext<any, any>(key, fetcher, config);

      return Object.defineProperty(swr, alias, {
        enumerable: true,
        get() {
          return selector(swr);
        },
      });
    };
  };
}
