import { lowerCase } from 'lodash';

import {
  MixedReviewSkill,
  MixedReviewSkills,
} from '@gmm/sdk/mixedReviewSkills';
import { PropertyPath } from '~/lib/paths';

import { Labels } from './common';
import { eventFactory, getColumnName } from './utils';

const removeSkill = 'remove skill';
const setSkillActiveStr = 'set skill active';

const mixedReviewEvent = eventFactory('mixed review');

export const cancelRemoveSkill = (): void =>
  mixedReviewEvent({ action: removeSkill, label: Labels.Cancel });

export const confirmRemoveSkill = (): void => {
  mixedReviewEvent({ action: removeSkill, label: Labels.Submit });
};

export const createAssignmentFromSkills = (): void =>
  mixedReviewEvent({ action: 'create assignment from selected skills' });

export const clickRemoveSkill = (): void =>
  mixedReviewEvent({
    action: removeSkill,
    label: Labels.Click,
  });

export const setSkillActive = (skills: MixedReviewSkills): void => {
  mixedReviewEvent({
    action: setSkillActiveStr,
    label: 'active',
    value: skills.length,
  });
};

export const setSkillInactive = (skills: MixedReviewSkills): void => {
  mixedReviewEvent({
    action: setSkillActiveStr,
    label: 'inactive',
    value: skills.length,
  });
};

export const sortTable = (columnPath: PropertyPath<MixedReviewSkill>): void =>
  mixedReviewEvent({
    action: 'sort table',
    label: lowerCase(getColumnName(columnPath)),
  });

export const toggleColumn = (
  columnPath: PropertyPath<MixedReviewSkill>,
  on: boolean,
): void =>
  mixedReviewEvent({
    action: 'toggle column',
    label: lowerCase(getColumnName(columnPath)),
    value: Number(on),
  });

export const toggleDetailPanel = (_: MixedReviewSkill, open: boolean): void =>
  mixedReviewEvent({
    action: 'preview skill',
    label: open ? 'expand preview' : 'collapse preview',
  });

export const viewedSkillProficiency = (): void =>
  mixedReviewEvent({ action: 'view skill proficiency details ' });

export const viewedSkillAccuracy = (): void =>
  mixedReviewEvent({ action: 'view skill accuracy details' });
