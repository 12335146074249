import { ExerciseAssignmentBase } from './exercise';
import { TeacherShared } from './teacher';

export interface QuestionType {
  available: boolean;
  displayName: string;
  id: QuestionTypeIds;
}

export enum QuestionTypeIds {
  MULTI_SELECT = 'MULTI_SELECT',
  MATCH_TABLE = 'MATCH_TABLE',
  FRACTION_MODEL = 'FRACTION_MODEL',
  INLINE_CHOICE = 'INLINE_CHOICE',
  DRAG_AND_DROP = 'DRAG_AND_DROP',
  HOT_SPOT = 'HOT_SPOT',
}

export enum Subject {
  Grade3 = 'Grade3',
  Grade4 = 'Grade4',
  Grade5 = 'Grade5',
  Grade6 = 'Grade6',
  Grade7 = 'Grade7',
  Grade8 = 'Grade8',
  AlgebraOne = 'AlgebraOne',
  Geometry = 'Geometry',
  AlgebraTwo = 'AlgebraTwo',
  MathOne = 'MathOne',
  MathTwo = 'MathTwo',
  MathThree = 'MathThree',
  Trig = 'Trig',
  Stats = 'Stats',
  Other = 'Other',
}

export interface KlassShared {
  id: string;
  active: boolean;
  generalPractice: boolean;
  name: string;
  subject: string;
  teacherId: string;
}

export interface KlassDetail extends KlassShared {
  allowedWebGames?: string[];
  allowExamsOnMobile: boolean;
  allowRaisedHands: boolean;
  allowReplacementsWhenTeacherOnline: boolean;
  allowWorkOnAnyProblems: boolean;
  autoUnlockExamsProblems: boolean;
  code: string;
  coteachers: TeacherShared[];
  currentAssignment?: ExerciseAssignmentBase;
  enrollmentOpen: boolean;
  requireStudentsToFinishTargetedSkills: boolean;
  requireStudentsToFinishDefaultAssignment: boolean;
  gamesEnabled: boolean;
  penalizeGuessing: boolean;
  pointsPerGameCredit: number;
  replacementsPerDay: string;
  questionTypes: QuestionType[];
  readAloudEnabled: boolean;
  studentCount: number;
  subjects: string[];
  teacher: TeacherShared;
  teacherId: string;
  todaysGoal: number | null;
  useCalculator: boolean;
}

export interface KlassItem extends KlassShared {
  currentAssignment?: ExerciseAssignmentBase;
  teacher: TeacherShared;
}
