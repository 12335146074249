import { eventFactory } from './utils';

const skillPreviewEvent = eventFactory('skill preview');

export const addToAssignment = (): void =>
  skillPreviewEvent({ action: 'add to practice set' });

export const viewSkillSample = (): void => {
  skillPreviewEvent({ action: 'view skill sample' });
};
