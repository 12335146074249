import { AxiosRequestConfig } from 'axios';

import { Provider } from './list';

export type Create = CreateItem[];

export interface CreateItem {
  id: string;
  provider: Provider;
  rostered: boolean;
  subject: string;
}

interface ListConfig extends AxiosRequestConfig {
  method: 'POST';
  url: '/roster';
  body: Create;
  data: Create;
}

/**
 * POST /api/v2/roster
 */
export const create = (create: Create): ListConfig => {
  return {
    method: 'POST',
    url: '/roster',
    body: create,
    data: create,
  };
};
