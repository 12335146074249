import { AxiosRequestConfig } from 'axios';

import { BASE_API } from '../utils';

interface Search {
  q: string;
  state: string | null;
}

interface SearchConfig extends AxiosRequestConfig {
  method: 'GET';
  params: Search;
  url: `/collections/search`;
}

export interface CollectionsSearchResponse {
  hits: Record<string, number>;
  totalHits: number;
}

/**
 * GET /v1/collections/search?q={query}?state={geographicalState}
 */
export const search = (params: Search): SearchConfig => {
  return {
    baseURL: BASE_API,
    method: 'GET',
    params,
    url: `/collections/search`,
  };
};
