import { AxiosRequestConfig } from 'axios';

interface Create {
  name: string;
  parentFolderId: string;
}

interface CreateConfig extends AxiosRequestConfig {
  body: Create;
  data: Create;
  method: 'POST';
  url: '/exercise_folders';
}

interface ExerciseFolderDetail {
  id: string;
  name: string;
  optlock: number;
  position: number;
  sourceId: string;
}

// TODO: Use this response
export type ExerciseFoldersCreateResponse = ExerciseFolderDetail;

/**
 * POST /api/v2/exercise_folders
 *
 * { name: string, parentFolderId: string, }
 */
export const create = (data: Create): CreateConfig => {
  return {
    method: 'POST',
    url: '/exercise_folders',
    body: data,
    data,
  };
};
