export enum OrganizationType {
  InvalidType = 0,
  School = 1,
  District = 2,
  Region = 3,
}

export interface OrganizationBase {
  id: string;
  name: string;
  organizationType: OrganizationType;
}
