import { AxiosRequestConfig } from 'axios';

interface Create {
  examTemplateId: string;
  multipleChoiceAtEnd: boolean;
  scramble: boolean;
  static: boolean;
}

interface CreateConfig extends AxiosRequestConfig {
  body: Create;
  data: Create;
  method: 'POST';
  url: `/classes/${string}/exams`;
}

/**
 * POST /api/v2/classes/{id}/exams
 *
 * { examTemplateId: string, multipleChoiceAtEnd: boolean, scramble: boolean, static: boolean }
 */
export const create = (classId: string, data: Create): CreateConfig => {
  return {
    method: 'POST',
    url: `/classes/${classId}/exams`,
    body: data,
    data,
  } as const;
};
