import { IconButton, styled, iconButtonClasses } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Row } from 'react-table';

import { ChevronRight } from '@gmm/icons';

interface RootProps {
  isExpanded?: boolean;
}

const Root = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'isExpanded',
})<RootProps>(({ isExpanded, theme }) => ({
  transform: `rotate(${isExpanded ? 90 : 0}deg)`,
  transition: theme.transitions.create('transform'),
  [`&.${iconButtonClasses.sizeSmall}`]: {
    margin: theme.spacing(-1, 0, -1, -0.5),
  },
}));

interface Props extends RootProps {
  canExpand?: boolean;
  children?: ReactNode;
  onClick?: () => void;
  row?: Row<any>;
}

export const ExpandButton: FC<Props> = ({
  canExpand,
  children = <ChevronRight titleAccess="expand" />,
  isExpanded,
  onClick,
  row,
}) => {
  if (!canExpand && !row?.canExpand) return null;

  const props = row?.getToggleRowExpandedProps?.() ?? { onClick };

  return (
    <Root {...props} isExpanded={isExpanded || !!row?.isExpanded}>
      {children}
    </Root>
  );
};
