import { useRouteMatch } from 'react-router-dom';
import useSWR, { Fetcher, SWRResponse, useSWRConfig } from 'swr';
import { PublicConfiguration } from 'swr/dist/types';

import { KlassesShowResponse as Response } from '@gmm/sdk/classes';
import { is404 } from '~/lib/config/axios';
import { useSnackbar } from '~/lib/hooks/useSnackbar';
import { ReadOnlyOptions } from '~/lib/sdk';
import { swrKeyFor } from '~/lib/swr';

export type UseGetClassDetail = SWRResponse<Response, unknown>;

export const useGetClassDetail = ({
  readOnly,
}: ReadOnlyOptions = {}): UseGetClassDetail => {
  const { onErrorRetry } = useSWRConfig();
  const { enqueueGenericError } = useSnackbar();
  const { classId } =
    useRouteMatch<{ classId?: string }>('/classes/:classId')?.params || {};
  const swrKey = swrKeyFor(() => (classId ? ['classes.show', classId] : null));

  return useSWR<Response>(swrKey, {
    onError: error => {
      if (is404(error)) return;

      enqueueGenericError();
    },

    // This maybe should become our default behavior. But I want to audit some
    // things before we make that decision
    onErrorRetry: (error, key, config, ...rest) => {
      // Received not found, so don't retry
      if (is404(error)) return;

      return onErrorRetry(
        error,
        key,
        // This is pretty terrible, but the type can't be un-narrowed, for whatever reason
        config as Readonly<PublicConfiguration<any, any, Fetcher>>,
        ...rest,
      );
    },
    revalidateIfStale: !readOnly,
    revalidateOnFocus: !readOnly,
    revalidateOnReconnect: !readOnly,
  }) as UseGetClassDetail;
};
