import { startCase } from 'lodash';

import { trackIntercomEvent } from '~/lib/config/intercom';

import { Labels } from './common';
import { eventFactory } from './utils';

const add = 'add';
const setTodayGoal = "set today's goal";

const classEvent = eventFactory('class');

export const classMessageSent = (): void => {
  classEvent({ action: 'send message to class' });
};

export const cancelAddClass = (): void =>
  classEvent({ action: add, label: Labels.Cancel });

export const cancelSetTodaysGoal = (): void =>
  classEvent({ action: setTodayGoal, label: Labels.Cancel });

export const clearTodaysGoal = (): void => {
  classEvent({ action: setTodayGoal, label: 'clear' });
};

export const clickAdd = (): void =>
  classEvent({ action: add, label: Labels.Click });

export const clickBreadcrumb = (): void =>
  classEvent({ action: 'clicked breadcrumb' });

export const clickModeChip = (): void =>
  classEvent({ action: 'clicked mode chip' });

export const confirmAddClass = (): void => {
  classEvent({ action: add, label: Labels.Submit });
  trackIntercomEvent('created-class');
};

export const printClassRoster = (value: string): void => {
  classEvent({
    action: 'print class roster',
    label: startCase(value),
  });
};

export const setMode = (mode: 'assignment' | 'mixedReview'): void => {
  const lowerMode = mode.toLowerCase();

  classEvent({ action: 'set mode', label: lowerMode });
};

export const setTodaysGoal = (): void =>
  classEvent({ action: setTodayGoal, label: 'set' });

export const toggleClassListDrawer = (open: boolean): void =>
  classEvent({ action: open ? 'collapse class list' : 'expand class list' });

export const toggleClassSubMenuDrawer = (open: boolean): void =>
  classEvent({
    action: open ? 'collapse class sub-menu' : 'expand class sub-menu',
  });

export const updateTodaysGoal = (value: number): void => {
  classEvent({ action: setTodayGoal, label: 'update', value });
};
