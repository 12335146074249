import { get } from 'lodash';
import { isEqualWith } from 'lodash/fp';
import fastCompare from 'react-fast-compare';

// I took these from https://github.com/garbles/useful-types
// I tweaked `KeyPathValue` to make it smaller and easier to read
// and limited everything to 4 levels of depth, which is more than enough
type InternalKeyList<
  T,
  P1 = null,
  P2 = null,
  P3 = null,
  P4 = null,
> = P4 extends null
  ? T extends object
    ? {
        [K in keyof T]-?: [K, InternalKeyList<T[K], T, P1, P2, P3>];
      }[keyof T]
    : null
  : null;

// prettier-ignore
type InternalFlattenList<T> =
  T extends [infer A, infer B] ?
  B extends null ? readonly [A] :
  B extends [infer C, infer D] ?
  D extends null ? readonly [A] | readonly [A, C] :
  D extends [infer E, infer F] ?
  F extends null ? readonly [A] | readonly [A, C] | readonly [A, C, E] :
  F extends [infer G, infer H] ?
  H extends null ? readonly [A] | readonly [A, C] | readonly [A, C, E] | readonly [A, C, E, G] :
  [] : [] : [] : [] : [];

/**
 * Returns all valid key paths for a given type alias `T`.
 */
type KeyPath<T> = InternalFlattenList<InternalKeyList<T>>;
// Allows for string or [string, string, string]
export type PropertyPath<T> = keyof T | KeyPath<T>;

/**
 * Returns the value type from a key path `KP` on a type alias `T`.
 */
type KeyPathValue<T, KP extends [] | KeyPath<T>> = KP extends [
  (infer A)?,
  (infer B)?,
  (infer C)?,
  (infer D)?,
]
  ? A extends keyof T
    ? B extends keyof T[A]
      ? C extends keyof T[A][B]
        ? D extends keyof T[A][B][C]
          ? T[A][B][C][D]
          : T[A][B][C]
        : T[A][B]
      : T[A]
    : T
  : never;

export type PropertyPathValue<
  T,
  KP extends any[] | keyof T | undefined,
> = KP extends undefined
  ? T
  : KP extends [] | KeyPath<T>
  ? KeyPathValue<T, KP>
  : KP extends keyof T
  ? T[KP]
  : never;

type ValidKeyOf = string | number | symbol;
type PossiblePath = ValidKeyOf | readonly ValidKeyOf[];

export const isValidKeyOfType = (val: any): val is ValidKeyOf =>
  typeof val === 'string' || typeof val === 'number' || typeof val === 'symbol';

const compareKeyPaths = (path1: ValidKeyOf, path2: PossiblePath): boolean => {
  if (isValidKeyOfType(path2)) {
    return path1 === path2;
  }

  if (path2.length > 1) {
    return false;
  }

  return path1 === path2[0];
};

export const pathsAreEqual = isEqualWith((value, other) => {
  if (other && isValidKeyOfType(value)) {
    return compareKeyPaths(value, other);
  }

  if (value && isValidKeyOfType(other)) {
    return compareKeyPaths(other, value);
  }

  return fastCompare(value, other);
});

// This is essentially a type-safe version of `lodash.get`
// but `path` can only be `undefined`, keyof Item, or [keyof Item, ...]
export const getByPath = <
  Item extends Record<string, any>,
  Path extends PropertyPath<Item> | undefined = undefined,
>(
  item: Item,
  path?: Path | null,
): PropertyPathValue<Item, Path> =>
  isValidKeyOfType(path) || Array.isArray(path) ? get(item, path) : item;
