import { FC, useEffect } from 'react';

import { useAuth } from './authProvider';

export const LogoutCallback: FC = () => {
  const { signOutCallback } = useAuth();

  useEffect(() => {
    signOutCallback();
  }, [signOutCallback]);

  return null;
};
