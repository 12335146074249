import { createSvgIcon } from '@mui/material';

export const RestoreAlert = createSvgIcon(
  <g transform="translate(-1338.188 -5214.188)">
    <circle cx="11" cy="11" r="11" transform="translate(1338.188 5214.188)" />
    <path
      d="M10.477,3a7.078,7.078,0,0,0-7.108,7.108H1l3.08,3.08.079.079,3.159-3.159H4.949a5.528,5.528,0,1,1,5.528,5.528,5.3,5.3,0,0,1-3.87-1.659L5.5,15.084a6.734,6.734,0,0,0,4.976,2.132A7.108,7.108,0,1,0,10.477,3m-.79,9.477h1.58v1.58H9.688v-1.58m0-6.318h1.58V10.9H9.688V6.159"
      transform="translate(1338.773 5215.08)"
      fill="#fff"
    />
  </g>,
  'RestoreAlert',
);
