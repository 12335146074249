import { AxiosRequestConfig } from 'axios';

import { StudentInKlassBase, StudentShared } from '../../shared';

export interface StudentDataAssignmentsItem {
  counts: {
    completed: number;
    notStarted: number;
    total: number;
  };
  student: StudentShared;
  studentInClass: StudentInKlassBase;
  studentInClassId: string;
}

export interface StudentDataAssignmentsIndexResponse {
  students: StudentDataAssignmentsItem[];
}

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/classes/${string}/student_data/assignments`;
}

export const list = (classId: string): ListConfig => ({
  method: 'GET',
  url: `/classes/${classId}/student_data/assignments`,
});
