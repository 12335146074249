import { useFlags } from 'launchdarkly-react-client-sdk';
import { camelCase } from 'lodash';

import { isFlagOff } from './utils';

export function useFeatureFlag<T = boolean>(
  flag?: string,
): T extends boolean ? T : T | null;
export function useFeatureFlag<T = boolean>(
  flag: string | undefined,
  defaultValue: T,
): T;

export function useFeatureFlag<T = boolean>(
  flag?: string,
  defaultValue: T | null = null,
): T extends boolean ? T : T | null {
  const flags = useFlags();
  const parsedFlag = camelCase(flag);
  const flagValue =
    parsedFlag && Object.prototype.hasOwnProperty.call(flags || {}, parsedFlag)
      ? flags[parsedFlag]
      : defaultValue;

  return isFlagOff(flagValue) ? null : flagValue;
}
