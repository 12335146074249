import { AxiosRequestConfig } from 'axios';

interface SendToken {
  email: string;
}

interface SendTokenConfig extends AxiosRequestConfig {
  method: 'POST';
  params: SendToken;
  url: '/verify_email/send_token';
}

export const sendToken = (params: SendToken): SendTokenConfig => ({
  method: 'POST',
  params,
  url: '/verify_email/send_token',
});
