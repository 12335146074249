import { WorkItemType } from '@gmm/sdk/shared';

export const work = {
  addBySkillIdModal: {
    addSkill: 'add-skill-affirmative',
    cancel: 'add-skill-negative',
    root: 'add-by-skill-id-dialog',
    textfield: 'add-by-skill-id-textfield',
  },
  assignmentInUseModal: {
    alert: 'assignment-in-use-alert',
    cancel: 'assignment-in-use-cancel',
    className: 'assignment-in-use-class-name',
    update: 'assignment-in-use-update',
    errorMessage: 'assignment-in-use-helper-text',
    previouslyAssigned: 'assignment-in-use-previously-assigned',
    root: 'assignment-in-use-root',
    tableId: 'assignmentInUseModal',
    title: 'assignment-in-use-modal-title',
  },
  assignmentMenu: {
    addBySkillID: 'assignment-add-by-skill-id',
    archive: 'more-archive',
    button: 'more-assignment',
    gameCredit: 'more-change-points-per-game-credit',
    printButton: 'assignment-print-button',
  },
  assignments: {
    alert: 'assignment-alert',
    cancel: 'assignment-cancel',
    close: 'close-detail-button',
    doNow: 'assignment-do-now',
    duplicate: 'assignment-duplicate',
    form: 'assignment-form',
    name: 'assignment-name',
    points: 'assignment-points',
    save: 'assignment-save',
  },
  assignToModal: {
    form: {
      assign: 'assign-to-form-select',
      assignToIndex: (index: number) => `assignTo-${index}`,
      cancel: 'assign-to-form-cancel',
      className: 'assign-to-class-name',
      defaultAssignment: 'assign-to-default-assignment',
      previouslyAssigned: 'assign-to-previously-assigned',
      root: 'assign-to-form-root',
      submit: 'assign-to-form-submit',
    },
    root: 'assign-to-modal-root',
    tableId: 'assignmentToModal',
  },
  breadcrumbs: {
    crumb: (name: string) => `breadcrumb-name-${name}`,
  },
  createFolder: {
    cancel: 'cancel-create-folder',
    close: 'close-create-folder',
    main: 'create-folder',
    name: 'create-folder-name',
    submit: 'submit-create-folder',
  },
  collections: {
    accordion: {
      icon: 'collections-accordion-icon',
      isOpen: (isOpen: boolean) => `collections-accordion-is-open-${isOpen}`,
    },
    breadcrumbs: {
      crumb: (name: string) => `collections-breadcrumb-${name}`,
      root: 'collections-breadcrumbs-root',
    },
    header: 'collections-navigator-header',
    item: (name: string) => `collections-item-${name}`,
    list: 'collections-navigator-list',
    root: 'collections-navigator-root',
  },
  copyFolder: {
    cancel: 'copy-folder-cancel',
    root: 'copy-folder-root',
    submit: 'copy-folder-submit',
  },
  editFolder: {
    cancel: 'cancel-edit-folder',
    close: 'close-edit-folder',
    form: {
      name: 'edit-folder',
      input: 'edit-folder-name',
    },
    submit: 'submit-edit-folder',
  },
  examTemplates: {
    duplicate: 'exam-duplicate',
    form: {
      cancel: 'exams-form-cancel',
      instances: (workType: WorkItemType, index: number) =>
        `${workType}-skill-index-${index}-instances`,
      name: 'exams-form-name',
      problemsCount: 'exams-form-problems-count',
      save: 'exams-form-save',
      static: (is: boolean) => `exams-form-static-${is}`,
      staticRoot: 'exams-form-static-root',
    },
    publish: {
      button: 'exams-publish',
      cancel: 'exams-publish-cancel',
      shuffle: (name: string) => `exams-publish-shuffle-${name}`,
      submit: 'exams-publish-submit',
      toClass: (name: string) => `exams-publish-to-class-${name}`,
      tableId: 'publishTable',
    },
  },
  examTemplateMenu: {
    addBySkillID: 'exam-add-by-skill-id',
    archive: 'more-vert-exam-archive',
    button: 'more-vert-exam-button',
    printButton: 'exam-print-button',
  },
  fab: {
    main: 'work-fab',
    newFolder: 'new-folder-button',
    newAssignment: 'new-assignment-button',
    newExam: 'new-exam-button',
  },
  myWork: {
    container: 'work-container',
    header: '',
    navigator: {
      item: (type: WorkItemType, name: string) => `${type}-${name}`,
      itemIcon: (id: string, isExpanded: boolean) =>
        `item-${id}-expanded-${isExpanded}`,
      remove: (name: string) => `item-${name}-remove`,
      rename: (name: string) => `item-${name}-rename`,
      root: 'my-work-tree',
    },
  },
  noClassesEmptyState: {
    classesLink: 'no-classes-empty-state-classes-link',
  },
  oldAssignmentInUseModal: {
    cancel: 'assignment-in-use-cancel',
    root: 'assignment-in-use-root',
    submit: 'assignment-in-use-submit',
  },
  printAssignmentModal: {
    cancel: 'print-assignment-modal-cancel',
    confirm: 'print-assignment-modal-confirm',
    form: {
      root: 'print-assignment-modal-form-root',
      shuffle: 'print-assignment-modal-form-shuffle',
      versions: 'print-assignment-modal-form-versions',
    },
    root: 'print-assignment-modal-root',
    title: 'print-assignment-modal-title',
  },
  printExamTemplateModal: {
    cancel: 'print-exam-modal-cancel',
    confirm: 'print-exam-modal-confirm',
    form: {
      includeAnswerKey: 'print-exam-modal-form-include-answer-key',
      root: 'print-exam-modal-form-root',
    },
    root: 'print-exam-modal-root',
    title: 'print-exam-modal-title',
  },
  skillDiscovery: {
    breadcrumbs: {
      collapseAll: 'skill-discovery-collapse-all',
      crumb: (index: number) => `skill-discovery-breadcrumb-${index}`,
    },
    copyButton: (name: string) => `skill-discovery-copy-button-${name}`,
    copyTag: {
      cancel: 'skill-discovery-copy-tag-cancel',
      root: 'skill-discovery-copy-tag-root',
      submit: 'skill-discovery-copy-tag-submit',
    },
    container: 'skill-discovery-container',
    grade: (gradelevel: string) => `grade-${gradelevel}-collection`,
    item: (name: string) => `skill-discovery-item-${name}`,
    itemExpanded: (name: string, isExpanded: boolean) =>
      `skill-discovery-item-${name}-expanded-${isExpanded}`,
    problemPreviewWrapper: 'skill-discovery-problem-preview-wrapper',
    search: {
      container: (open: boolean): string =>
        `skill-discovery-search-container:${open ? 'open' : 'closed'}`,
      field: 'skill-discovery-search-field',
      icon: 'skill-discovery-search-icon',
    },
    topLevelCollections: {
      tab: (name: string) => `skill-discovery-top-level-collection-tab-${name}`,
    },
  },
  skillList: {
    root: (workType: WorkItemType, index: number) =>
      `${workType}-skill-index-${index}-root`,
    queueDelete: (index: number) => `exercise-skill-index-${index}-remove`,
    refreshSkill: (skillCode: string) => `refresh-skill-icon-${skillCode}`,
    skill: (index: number) => `exercise-skill-index-${index}-root`,
    trashCan: (workType: WorkItemType, index: number) =>
      `${workType}-skill-index-${index}-remove`,
  },
} as const;
