import { AxiosRequestConfig } from 'axios';

interface Grade {
  examId: string;
  isFollowUp: boolean;
}

interface GradeConfig extends AxiosRequestConfig {
  method: 'POST';
  params: Pick<Grade, 'isFollowUp'>;
  url: `/exams/${string}/grade`;
}

/**
 * POST /api/v2/exams/{id}/grade?isFollowUp={boolean}
 */
export const grade = ({ examId, ...params }: Grade): GradeConfig => {
  return {
    method: 'POST',
    url: `/exams/${examId}/grade`,
    params,
  } as const;
};
