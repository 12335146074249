import useSWR, { BareFetcher, SWRResponse } from 'swr';

import { KlassesIndexResponse as Response } from '@gmm/sdk/classes/list';
import { KlassItem } from '@gmm/sdk/shared';
import { useCurrentTeacher } from '~/lib/hooks/useCurrentTeacher';
import { useSnackbar } from '~/lib/hooks/useSnackbar';
import { useClassesIndexEndpoint } from '~/lib/hooks/voodoo';
import { ReadOnlyOptions } from '~/lib/sdk';
import { sortBy } from '~/lib/sortHelper';
import { swrKeyFor, augmentSWR, voodooSdkFetcher, sdkFetcher } from '~/lib/swr';

const sortByName = sortBy<KlassItem>('name');

type ClassListResponse = SWRResponse<Response, unknown>;
interface UseGetClassList extends ClassListResponse {
  /** Active classes only, sorted by name */
  activeClasses: ClassListResponse['data'];
}

export const useGetClassList = ({
  readOnly,
}: ReadOnlyOptions = {}): UseGetClassList => {
  const { enqueueGenericError } = useSnackbar();
  const { data: teacher } = useCurrentTeacher();
  const isVoodooEndpoint = useClassesIndexEndpoint();

  const swrKey = swrKeyFor(() =>
    teacher && isVoodooEndpoint ? ['classes.list', teacher.id] : 'classes.list',
  );
  const fetcher = isVoodooEndpoint ? voodooSdkFetcher : sdkFetcher;

  return useSWR(swrKey, {
    fetcher: fetcher as BareFetcher<Response>,
    onError: () => enqueueGenericError(),
    revalidateIfStale: !readOnly,
    revalidateOnReconnect: !readOnly,
    use: [
      augmentSWR<Response>(
        'activeClasses',
        ({ data }) => data && sortByName(data.filter(({ active }) => active)),
      ),
    ],
  }) as UseGetClassList;
};
