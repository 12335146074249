import { AxiosRequestConfig } from 'axios';

import { BASE_API } from '../utils';

interface ReportConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/classes/${string}/students/${string}/report`;
  baseURL: string;
}

interface StudentReportPoints {
  assignment: number;
  mixedReview: number;
}

interface StudentReportPercentages {
  accuracy: number;
  activeSkillsAccuracy: number;
}

export interface StudentReportSkill {
  id: string;
  active: boolean;
  attempts: number;
  color: StudentReportSkillColor;
  condensed: boolean;
  currentlyWrong: boolean;
  currentPenalties: number;
  dateAdded: string;
  description: string;
  lastCorrect: string | null;
  percentages: StudentReportSkillPercentages;
  studentActive: boolean;
}

export enum StudentReportSkillColor {
  untried = 'untried',
  red = 'red',
  yellow = 'yellow',
  green = 'green',
  silver = 'silver',
  gold = 'gold',
}

interface StudentReportSkillPercentages {
  accuracy: number;
  lastFive: number;
}

export interface StudentInKlassesReportResponse {
  firstName: string;
  lastName: string;
  isInIndividualMixedReview: boolean;
  username: string;
  studentInClassId: string;
  attempts: number;
  errorsFixed: number;
  rotationTime: number;
  unfixedErrors: number;
  currentPenalties: number;
  points: StudentReportPoints;
  percentages: StudentReportPercentages;
  skills: StudentReportSkill[];
  pointsEarned: number;
}

/**
 * GET /v1/classes/{id}/report/{studentInClassId}
 */
export const report = (
  studentInClassId: string,
  classId?: string,
): ReportConfig => {
  return {
    method: 'GET',
    url: `/classes/${classId}/students/${studentInClassId}/report`,
    baseURL: BASE_API,
  } as const;
};
