import { AxiosRequestConfig } from 'axios';

interface Create {
  sfId: string;
}

export interface SuccessfulClaimsCreateConfig extends AxiosRequestConfig {
  data: Create;
  method: 'POST';
  url: '/claim_school/successful_claims';
}

/**
 * POST /api/v2/claim_school/successful_claims
 */
export const create = (data: Create): SuccessfulClaimsCreateConfig => {
  return {
    method: 'POST',
    url: '/claim_school/successful_claims',
    data,
  };
};
