import { AxiosRequestConfig } from 'axios';

export type Provider = 'local' | 'google' | 'classLink' | 'clever';

// This type is also defined in auth/utils.ts
export type LowercaseIdps = 'local' | 'clever' | 'google' | 'classlink';

export const idpToProviderMap = (idp: LowercaseIdps): Provider => {
  switch (idp) {
    case 'clever':
      return 'clever';
    case 'classlink':
      return 'classLink';
    case 'google':
      return 'google';
    default:
      return 'local';
  }
};

export interface RosterItem {
  id: string;
  createdDate: string;
  provider: Provider;
  name: string;
  rostered: boolean;
  subject: string | null;
}

export type RosterIndexResponse = RosterItem[];

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  params: { provider: Provider; classId?: string };
  url: '/roster';
}

/**
 * GET /api/v2/roster
 */
export const list = (idp: LowercaseIdps, classId?: string): ListConfig => {
  return {
    method: 'GET',
    params: { provider: idpToProviderMap(idp), classId },
    url: '/roster',
  };
};
