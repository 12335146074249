import { MenuProps } from '@mui/material';
import { MouseEventHandler, useState } from 'react';

// This is the same as MenuProps['onClose'] (which resolves to ModalProps['onClose'])
// With the exception that I am not making the arguments required
type CloseHandler = {
  bivarianceHack(
    event?: {},
    // these are technically all just string, but this makes for an easy reference later
    reason?: 'backdropClick' | 'escapeKeyDown' | string,
  ): void;
}['bivarianceHack'];

type ReturnedMenuProps = 'anchorEl' | 'onClose' | 'open';
type PartialMenuProps = Partial<Omit<MenuProps, ReturnedMenuProps>>;

interface Options<E> {
  dontStopPropagation?: boolean;
  menuProps?: PartialMenuProps;
  onClick?: MouseEventHandler<E>;
  onClose?: CloseHandler;
}

interface MenuObject<E> {
  handleMenuClick: MouseEventHandler<E>;
  handleMenuClose: CloseHandler;
  isMenuOpen: boolean;
  menuAnchorEl: E | null;
  menuProps: PartialMenuProps & Required<Pick<MenuProps, ReturnedMenuProps>>;
}

export const useMenu = <E extends Element>({
  dontStopPropagation = false,
  menuProps,
  onClick,
  onClose,
}: Options<E> = {}): MenuObject<E> => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<E | null>(null);
  const handleMenuClick: MouseEventHandler<E> = event => {
    if (!dontStopPropagation) event.stopPropagation();

    onClick?.(event);
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose: CloseHandler = (event, reason) => {
    if (!dontStopPropagation) (event as Event)?.stopPropagation?.();

    onClose?.(event, reason);
    setMenuAnchorEl(null);
  };
  const isMenuOpen = Boolean(menuAnchorEl);

  return {
    handleMenuClick,
    handleMenuClose,
    isMenuOpen,
    menuAnchorEl,
    menuProps: {
      ...menuProps,
      anchorEl: menuAnchorEl,
      onClose: handleMenuClose,
      open: isMenuOpen,
    },
  };
};
