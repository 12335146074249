import { AxiosRequestConfig } from 'axios';

import { ProblemtypeDetail, Subject } from '../shared';

export type StarterBundleSkill = ProblemtypeDetail;

export const allSubjects = Object.values(Subject);

export interface SubjectsShowResponse {
  mixedReviewStarterBundle: ProblemtypeDetail[];
}

export type SubjectsDetail = SubjectsShowResponse;

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/subjects/${string}`;
}

/**
 * GET /api/v2/subjects/{key}
 * See subjects.rb -> KEYS for a list of valid keys
 */
export const show = (key: string): ShowConfig => {
  return {
    method: 'GET',
    url: `/subjects/${key}`,
  } as const;
};
