import { createSvgIcon, styled } from '@mui/material';

import { ExamHexagon } from './examHexagon';

const Polygon = styled('polygon')(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const CorrectProblem = createSvgIcon(
  <>
    <ExamHexagon color="secondary" component="g" fontSize="inherit" />
    <Polygon points="8.47 15.12 5.19 11.84 6.03 11.01 8.47 13.46 14.67 7.26 15.5 8.09 8.47 15.12" />
  </>,
  'CorrectProblem',
);
