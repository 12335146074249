import { Labels } from './common';
import { eventFactory } from './utils';

const modifyStudentInClass = 'modify student in class';
const removeFromClass = 'remove from class';
const resetPassword = 'reset password';

const studentProfileEvent = eventFactory('student profile');

export const cancelModifyStudent = (): void =>
  studentProfileEvent({
    action: modifyStudentInClass,
    label: Labels.Cancel,
  });

export const cancelPasswordReset = (): void =>
  studentProfileEvent({ action: resetPassword, label: Labels.Cancel });

export const cancelRemoveStudent = (): void =>
  studentProfileEvent({ action: removeFromClass, label: Labels.Cancel });

export const clickRemoveStudent = (): void =>
  studentProfileEvent({ action: removeFromClass, label: Labels.Click });

export const confirmModifyStudent = (): void =>
  studentProfileEvent({
    action: modifyStudentInClass,
    label: Labels.Submit,
  });

export const confirmRemoveStudent = (): void =>
  studentProfileEvent({ action: removeFromClass, label: Labels.Submit });

export const changes = (inputField: string): void =>
  studentProfileEvent({
    action: modifyStudentInClass,
    label: inputField,
  });

export const disableGames = (): void =>
  studentProfileEvent({ action: 'disable games' });

export const disableLimitMultipleChoice = (): void =>
  studentProfileEvent({ action: 'disable limit multiple choice' });

export const disableStudentPenalties = (): void =>
  studentProfileEvent({ action: 'disable student penalties' });

export const disableStudentExamCorrections = (): void =>
  studentProfileEvent({ action: 'disable student exam corrections' });

export const enableGames = (): void =>
  studentProfileEvent({ action: 'enable games' });

export const enableLimitMultipleChoice = (): void =>
  studentProfileEvent({ action: 'enable limit multiple choice' });

export const enableStudentPenalties = (): void =>
  studentProfileEvent({ action: 'enable student penalties' });

export const enableStudentExamCorrections = (): void =>
  studentProfileEvent({ action: 'enable student exam corrections' });

export const passwordModal = (withAlternate: boolean): void =>
  studentProfileEvent({
    action: 'see password',
    label: withAlternate ? 'with alternate' : 'no alternate',
  });

export const submitPasswordReset = (usedGeneratedPassword: boolean): void => {
  studentProfileEvent({
    action: resetPassword,
    label: usedGeneratedPassword ? 'accepted suggestion' : 'typed new',
  });
};
