import { FC, ReactNode } from 'react';

import { Close, KeyboardArrowLeft, KeyboardArrowRight } from '@gmm/icons';
import { AppBar, Grid, IconButton, Toolbar, Typography } from '@gmm/ui';

import { useFullscreenContext } from '../context';

interface Props {
  description?: string;
  showPreviousSibling: () => void;
  showNextSibling: () => void;
  title?: ReactNode;
}

export const FullscreenHeading: FC<Props> = ({
  description,
  showPreviousSibling,
  showNextSibling,
  title,
}) => {
  const { siblingSkills, currentIndex, onClose } = useFullscreenContext();
  const isFirstSibling = currentIndex === 0;
  const isLastSibling = currentIndex === siblingSkills.length - 1;

  return (
    <>
      <AppBar position="static">
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography component="div">{title}</Typography>
          <IconButton onClick={onClose} color="inherit">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      {!!siblingSkills.length && (
        <Grid
          container
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
          }}
        >
          <Grid item>
            <IconButton disabled={isFirstSibling} onClick={showPreviousSibling}>
              <KeyboardArrowLeft />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography>{description}</Typography>
          </Grid>
          <Grid item>
            <IconButton disabled={isLastSibling} onClick={showNextSibling}>
              <KeyboardArrowRight />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};
