import { createSvgIcon, styled } from '@mui/material';

import { ExamHexagon } from './examHexagon';

const Path = styled('path')(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const SeenProblem = createSvgIcon(
  <>
    <ExamHexagon component="g" fontSize="inherit" />
    <Path d="M10,7.36a5.93,5.93,0,0,0-5.5,3.75,5.91,5.91,0,0,0,11,0A5.92,5.92,0,0,0,10,7.36Zm0,6.25a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,10,13.61Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,10,9.61Z" />
  </>,
  'SeenProblem',
);
