import * as Sentry from '@sentry/browser';
import { Event, Integration } from '@sentry/types';

interface Options {
  ignorePatterns?: Array<string | RegExp>;
  includePatterns?: Array<string | RegExp>;
}

export class LocalStorageData implements Integration {
  public static id = 'LocalStorageData';

  public name = LocalStorageData.id;

  private ignorePatterns?: Array<string | RegExp>;
  private includePatterns?: Array<string | RegExp>;

  constructor(options: Options = {}) {
    this.ignorePatterns = options.ignorePatterns;
    this.includePatterns = options.includePatterns;
  }

  public setupOnce(): void {
    Sentry.addGlobalEventProcessor((event: Event): Event => {
      const self = Sentry.getCurrentHub().getIntegration(LocalStorageData);

      if (!self) return event;

      return {
        ...event,
        contexts: {
          ...event.contexts,
          localStorage: this.getLocalStorage(),
        },
      };
    });
  }

  public getLocalStorage(): Record<string, unknown> {
    const storageObject: Record<string, unknown> = {};
    const length = window.localStorage.length;

    for (let i = 0; i < length; ++i) {
      const key = window.localStorage.key(i);

      if (!key || !this.isValidKey(key)) continue;

      const value = window.localStorage.getItem(key);

      try {
        storageObject[key] = value ? JSON.parse(value) : null;
      } catch {
        // we'll set the value as it was if we can't parse into JSON
        storageObject[key] = value;
      }
    }

    return storageObject;
  }

  private isValidKey(key: string): boolean {
    if (this.ignorePatterns) {
      const shouldIgnore = this.ignorePatterns.some(pattern =>
        key.match(pattern),
      );

      if (shouldIgnore) return false;
    }

    if (this.includePatterns) {
      return this.includePatterns.some(pattern => key.match(pattern));
    }

    return true;
  }
}
