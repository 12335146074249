import { AxiosRequestConfig } from 'axios';

interface Destroy {
  skillType: string;
}

interface DestroyConfig extends AxiosRequestConfig {
  body: Destroy;
  data: Destroy;
  method: 'DELETE';
  url: `/classes/${string}/mixed_review_skills`;
}

/**
 * DELETE /api/v2/classes/{id}/mixed_review_skills
 *
 * { skillType: string }
 */
export const destroy = (classId: string, data: Destroy): DestroyConfig => {
  return {
    method: 'DELETE',
    url: `/classes/${classId}/mixed_review_skills`,
    body: data,
    data,
  } as const;
};
