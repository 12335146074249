import { AxiosRequestConfig } from 'axios';

import { TeacherMe } from '../shared';

export interface Create {
  email: string;
  firstName: string;
  lastName: string;
  organizationId?: string;
  password: string;
  timezone: string;
  utmCampaign: string;
}

interface CreateConfig extends AxiosRequestConfig {
  body: Create;
  data: Create;
  method: 'POST';
  // Need prefix since axios config isn't called until sign in
  url: '/api/v2/teachers';
}

export type TeachersCreateResponse = TeacherMe;

/**
 * POST /api/v2/teachers
 *
 * {   email: string, firstName: string, lastName: string, password: string, timezone: string, organizationId: number }
 */
export const create = (data: Create): CreateConfig => {
  return {
    method: 'POST',
    url: '/api/v2/teachers',
    body: data,
    data,
  } as const;
};
