import { Box, BoxProps } from '@mui/material';
import { FC } from 'react';

import { DataGridLevel2Context } from '../data-grid';

const contextValue = { variant: 'body' } as const;

export const DataGridBody: FC<BoxProps<'section'>> = props => (
  <DataGridLevel2Context.Provider value={contextValue}>
    <Box component="section" {...props} />
  </DataGridLevel2Context.Provider>
);
