import { AxiosRequestConfig } from 'axios';

interface MarkAsViewedConfig extends AxiosRequestConfig {
  method: 'PATCH';
  url: `/achievements/${string}`;
}

/**
 * PATCH /api/v2/achievements/{key}
 */
export const markAsViewed = (key: string): MarkAsViewedConfig => {
  return {
    method: 'PATCH',
    url: `/achievements/${key}`,
  } as const;
};
