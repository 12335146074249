import { Paper as MuiPaper, styled } from '@mui/material';
import { HTMLMotionProps, motion, MotionConfig } from 'framer-motion';
import { position } from 'polished';
import { FC } from 'react';

const transition = {
  type: 'spring',
  damping: 22.5,
  mass: 0.5,
  stiffness: 225,
};

export const MiniDrawerVariants = { open: 'open', closed: 'closed' } as const;
type MiniDrawerVariants =
  typeof MiniDrawerVariants[keyof typeof MiniDrawerVariants];

export const getCurrentMiniDrawerVariant = (
  open: boolean,
): MiniDrawerVariants =>
  open ? MiniDrawerVariants.open : MiniDrawerVariants.closed;

interface SharedProps {
  inset?: boolean;
}

const sharedOptions = {
  shouldForwardProp: (prop: 'inset') => prop !== 'inset',
};

const Root = styled(
  motion.aside,
  sharedOptions,
)<SharedProps>(({ inset, theme }) => ({
  flex: '0 0 auto',
  position: 'relative',
  zIndex: theme.zIndex.drawer - (inset ? 1 : 0),
}));

const Paper = styled(
  MuiPaper,
  sharedOptions,
)<SharedProps>(({ inset, theme }) => ({
  ...position('absolute', 0),
  backgroundColor: inset
    ? theme.palette.grey[200]
    : theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  outline: 0,
  overflowX: 'hidden',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  zIndex: theme.zIndex.drawer,
}));

const MAX_WIDTH = 240;
const MIN_WIDTH = 48;

const variants = {
  closed: { width: MIN_WIDTH },
  open: { width: MAX_WIDTH },
};

interface Props extends SharedProps, HTMLMotionProps<'aside'> {
  open: boolean;
}

export const MiniDrawer: FC<Props> = props => {
  const { children, inset, open, ...drawerProps } = props;
  const currentVariant = getCurrentMiniDrawerVariant(open);

  return (
    <MotionConfig transition={transition}>
      <Root
        {...drawerProps}
        animate={currentVariant}
        initial={currentVariant}
        inset={inset}
        variants={variants}
      >
        <Paper elevation={2} inset={inset}>
          {children}
        </Paper>
      </Root>
    </MotionConfig>
  );
};
