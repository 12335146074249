import { AxiosRequestConfig } from 'axios';

import { TeacherShared } from '../shared';

export type SchoolTeachersListItem = TeacherShared;

export interface SchoolTeachersIndexResponse {
  teachers: TeacherShared[];
}

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/classes/${string}/school_teachers`;
}

export const list = (classId: string): ListConfig => ({
  method: 'GET',
  url: `/classes/${classId}/school_teachers`,
});
