import { AxiosRequestConfig } from 'axios';

import { ExerciseDetail } from '../shared';

export interface ExercisesCreateRequest {
  isExam?: boolean;
  name: string;
  parentFolderId: string;
  pointsPerGameCredit?: number;
  pointsToFinish?: number;
  skillTypes?: string[];
  static?: boolean;
}

interface CreateConfig extends AxiosRequestConfig {
  body: ExercisesCreateRequest;
  data: ExercisesCreateRequest;
  method: 'POST';
  url: '/exercises';
}

// TODO: Use this response
export type ExercisesCreateResponse = ExerciseDetail;

/**
 * POST /api/v2/exercises
 *
 * { isExam?: boolean, name: string, parentFolderId: string, skillTypes: string[], static: boolean, }
 */
export const create = (data: ExercisesCreateRequest): CreateConfig => {
  return {
    method: 'POST',
    url: '/exercises',
    body: data,
    data,
  };
};
