import { AxiosRequestConfig } from 'axios';

import { ExamSettingItem } from './list';

interface UpdateConfig extends AxiosRequestConfig {
  body: Partial<ExamSettingItem> & {
    additionalPracticePoints?: number | undefined;
  };
  data: Partial<ExamSettingItem> & {
    additionalPracticePoints?: number | undefined;
  };
  method: 'PATCH';
  url: `/exams/${string}`;
}

/**
 * PATCH /api/v2/exams/{id}
 */
export const update = (
  id: string,
  data: Partial<ExamSettingItem> & {
    additionalPracticePoints?: number | undefined;
  },
): UpdateConfig => {
  return {
    method: 'PATCH',
    url: `/exams/${id}`,
    body: data,
    data,
  } as const;
};
