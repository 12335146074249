import { captureException } from '@sentry/browser';
import { Component, ErrorInfo, FC, ReactNode } from 'react';

import NotFound from '~/lib/notFound';

import { useAppUpdateSnackbar } from './hooks/useAppUpdateSnackbar';

type State =
  | { hasError: false }
  | { error: Error; hasError: true; info: ErrorInfo };
const initialState: State = { hasError: false };

interface Props {
  readonly children?: ReactNode;
}

type ErrorWrapperProps = State & Props;

const reMissing = /Loading.*?failed/;
const isChunkLoadError = (error: Error | Error[]): boolean => {
  if (Array.isArray(error)) return error.some(isChunkLoadError);

  return (
    reMissing.test(error.message) ||
    error.name === 'ChunkLoadError' ||
    (error as any).code === 'CSS_CHUNK_LOAD_FAILED'
  );
};

const ErrorWrapper: FC<ErrorWrapperProps> = props => {
  const enqueueUpdateSnackbar = useAppUpdateSnackbar();

  if (props.hasError) {
    if (isChunkLoadError(props.error)) {
      enqueueUpdateSnackbar();
    } else {
      captureException(props.error, {
        contexts: { react: { componentStack: props.info.componentStack } },
      });
    }

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.info(props.info);
      // eslint-disable-next-line no-console
      console.error(props.error);
    }

    return <NotFound />;
  }

  return <>{props.children}</>;
};

export default class ErrorBoundary extends Component<Props, State> {
  public readonly state = initialState;

  // This is necessary since I added this to our Route
  // It allows someone to click away to another page and actually see that page
  public componentDidUpdate({ children }: Props): void {
    if (children !== this.props.children) {
      this.setState(initialState);
    }
  }

  public componentDidCatch(error: Error, info: ErrorInfo): void {
    this.setState({ error, hasError: true, info });
  }

  public render(): ReactNode {
    return <ErrorWrapper {...this.state}>{this.props.children}</ErrorWrapper>;
  }
}
