import { noop } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useLatestRef } from '@gmm/ui';

interface EventHandlers {
  onActivate?: (...args: unknown[]) => void;
  onDeactivate?: (...args: unknown[]) => void;
}

export type ToggleState = [
  activated: boolean,
  activate: (...args: unknown[]) => void,
  deactivate: (...args: unknown[]) => void,
  toggleWithEvents: (activated: boolean, ...args: unknown[]) => void,
  toggle: Dispatch<SetStateAction<boolean>>,
];

export const useToggle = (
  { onActivate = noop, onDeactivate = noop }: EventHandlers = {},
  initialValue = false,
): ToggleState => {
  const [isActivated, toggle] = useState(initialValue);
  const onActivateRef = useLatestRef(onActivate);
  const onDeactivateRef = useLatestRef(onDeactivate);

  const activate = useCallback(
    (...args: unknown[]): void => {
      toggle(wasActive => {
        if (!wasActive) onActivateRef.current(...args);

        return true;
      });
    },
    [onActivateRef],
  );

  const deactivate = useCallback(
    (...args: unknown[]): void => {
      toggle(wasActive => {
        if (wasActive) onDeactivateRef.current(...args);

        return false;
      });
    },
    [onDeactivateRef],
  );

  const toggleWithEvents = useCallback(
    (activated: boolean, ...args: unknown[]): void => {
      if (activated) {
        return activate(...args);
      }

      return deactivate(...args);
    },
    [activate, deactivate],
  );

  return useMemo(
    () => [isActivated, activate, deactivate, toggleWithEvents, toggle],
    [isActivated, activate, deactivate, toggleWithEvents, toggle],
  );
};
