import { createSvgIcon, styled } from '@mui/material';

import { ExamHexagon } from './examHexagon';

const Path = styled('path')(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const PartialCredit = createSvgIcon(
  <>
    <ExamHexagon color="warning" component="g" fontSize="inherit" />
    <Path d="M7.5,6.38v9H9.3v-3.6h1.8A1.8,1.8,0,0,0,12.9,10V8.18a1.81,1.81,0,0,0-1.8-1.8H7.5m1.8,1.8h1.8V10H9.3Z" />
  </>,
  'PartialCredit',
);
