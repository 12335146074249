import { useEffect, useState } from 'react';

interface Config<T> {
  chunkSize: number;
  data: readonly T[];
  isChunking: boolean;
}

export const useChunking = <T>({
  chunkSize,
  data,
  isChunking,
}: Config<T>): T[] => {
  const maxLength = data.length;
  const canChunk = isChunking && chunkSize < maxLength;
  const [slice, setSlice] = useState(canChunk ? 0 : maxLength);

  useEffect(() => {
    if (!canChunk) return setSlice(maxLength);

    setSlice(0);

    let raf: number;
    let started = false;
    const updateChunks = (): void => {
      if (!started) {
        started = true;
        raf = requestAnimationFrame(updateChunks);

        return;
      }

      let updated = false;

      setSlice(prevSlice => {
        if (prevSlice >= maxLength) return prevSlice;

        updated = true;

        return prevSlice + chunkSize;
      });

      if (updated) {
        raf = requestAnimationFrame(updateChunks);
      }
    };

    raf = requestAnimationFrame(updateChunks);

    return () => cancelAnimationFrame(raf);
  }, [canChunk, chunkSize, maxLength]);

  return data.slice(0, slice);
};
