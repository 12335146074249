import { AxiosRequestConfig } from 'axios';

import {
  KlassShared,
  OrganizationBase,
  StudentInKlassBase,
  TeacherShared,
} from '../shared';

export interface CampusStudentInKlass extends StudentInKlassBase {
  teacherId: string;
}

export interface ReportsCampusResponse {
  organizations: OrganizationBase[];
  teachers: TeacherShared[];
  classes: KlassShared[];
  studentInClasses: CampusStudentInKlass[];
}

interface CampusConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/reports/campus`;
}

/**
 * GET /api/v2/reports/campus
 */
export const campus = (): CampusConfig => {
  return {
    method: 'GET',
    url: `/reports/campus`,
  } as const;
};
