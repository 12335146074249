import { DialogTitle, DialogTitleProps } from '@mui/material';
import { FC } from 'react';

import { Truncate } from '../../../truncate';

export const ModalDialogTitle: FC<DialogTitleProps> = ({
  children,
  ...dialogTitleProps
}) => {
  if (!children) return null;

  return (
    <DialogTitle {...dialogTitleProps}>
      <Truncate>{children}</Truncate>
    </DialogTitle>
  );
};
