import { AxiosRequestConfig } from 'axios';

import { ExercisesDestroyResponseItem } from '../shared';

interface Destroy {
  id: string;
}

interface DestroyConfig extends AxiosRequestConfig {
  method: 'DELETE';
  url: `/exercise_folders/${string}`;
}

export interface ExerciseFoldersDestroyResponse {
  classAssignments: ExercisesDestroyResponseItem[];
}

/**
 * DELETE /api/v2/exercise_folders/{id}
 */
export const destroy = ({ id }: Destroy): DestroyConfig => {
  return {
    method: 'DELETE',
    url: `/exercise_folders/${id}`,
  } as const;
};
