import { AxiosRequestConfig } from 'axios';

import { KlassItem } from '../shared';

export type ClassList = KlassItem[];

export type KlassesIndexResponse = KlassItem[];

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: '/classes';
}

/**
 * GET /api/v2/classes
 */
export const list = (): ListConfig => {
  return {
    method: 'GET',
    url: '/classes',
  };
};
