import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';
import { forwardRef, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLLIElement> {
  checkboxProps: CheckboxProps;
  label: FormControlLabelProps['label'];
}
export const CheckboxMenuItem = forwardRef<HTMLLIElement, Props>(
  function CheckboxMenuItem(
    { checkboxProps, className, label, ...props },
    ref,
  ) {
    return (
      <Box
        {...props}
        component="li"
        ref={ref}
        sx={{ position: 'relative', ml: '-11px' }}
      >
        <FormControlLabel
          className={className}
          control={<Checkbox {...checkboxProps} />}
          label={label}
        />
        {props.children}
      </Box>
    );
  },
);
