import { InputBaseComponentProps, useForkRef } from '@mui/material';
import { uniqueId } from 'lodash';
import {
  ChangeEventHandler,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import { restrictValue } from '../utils';

export type TextInputProps = InputBaseComponentProps;

export const TextInput = forwardRef<HTMLInputElement, InputBaseComponentProps>(
  function TextInput({ id: idProp, onChange, ...inputProps }, inputRefProp) {
    const [id] = useState(() => idProp ?? uniqueId('number-input'));
    const inputRef = useRef<HTMLInputElement | null>(null);
    const ref = useForkRef(inputRefProp, inputRef);
    const previousValueRef = useRef<string>();

    useEffect(() => {
      previousValueRef.current = inputRef.current?.value;
    }, []);

    const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
      const { selectionEnd, value } = restrictValue(
        event.target,
        previousValueRef.current,
      );

      previousValueRef.current = value;
      event.target.value = value;
      event.target.selectionEnd = selectionEnd;
      onChange?.(event);
    };

    return <input {...inputProps} id={id} onChange={handleChange} ref={ref} />;
  },
);
