import {
  IconButton,
  IconButtonProps,
  InputBaseComponentProps,
  styled,
  useForkRef,
} from '@mui/material';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import {
  ChangeEventHandler,
  forwardRef,
  MouseEventHandler,
  useRef,
  useState,
} from 'react';

import { Visibility, VisibilityOff } from '@gmm/icons';

const Root = styled('div')({
  '&&': {
    alignItems: 'center',
    display: 'inline-flex',
    flex: 1,
  },
});

const Input = styled('input')({
  '&&': {
    background: 'none',
    border: 0,
    font: 'inherit',
    letterSpacing: 'inherit',
    padding: 0,
    '&:focus': { outline: 0 },
  },
});

export interface PasswordInputProps extends InputBaseComponentProps {
  allowToggle?: boolean;
  visibilityButtonProps?: IconButtonProps<'label'>;
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  function PasswordInput(
    {
      allowToggle,
      className,
      visibilityButtonProps,
      id: idProp,
      onChange,
      ...inputProps
    },
    inputRefProp,
  ) {
    const [isShowingPassword, setIsShowingPassword] = useState(false);
    const [id] = useState(() => idProp ?? uniqueId('search-input'));
    const inputRef = useRef<HTMLInputElement | null>(null);
    const ref = useForkRef(inputRefProp, inputRef);

    const handleClick: MouseEventHandler<HTMLLabelElement> = event => {
      setIsShowingPassword(!isShowingPassword);
      visibilityButtonProps?.onClick?.(event);
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
      onChange?.(event);
    };

    const type = allowToggle && isShowingPassword ? 'text' : 'password';

    return (
      <Root className={className}>
        <Input
          {...inputProps}
          autoComplete="off"
          className={className}
          id={id}
          onChange={handleChange}
          ref={ref}
          type={type}
        />
        {allowToggle && (
          <IconButton
            aria-label="x"
            {...visibilityButtonProps}
            className={clsx(visibilityButtonProps?.className)}
            component="label"
            onClick={handleClick}
            role=""
          >
            {isShowingPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        )}
      </Root>
    );
  },
);
