import { EventHint } from '@sentry/react';
import axios from 'axios';

import { isAxiosError } from '../axios';

/**
 * These events happen when a chunk is missing because it will serve up index.html
 *
 * (the url will eventually not work, since we only keep 90 days of history)
 * For an example event, see https://sentry.io/share/issue/2150bdfa0e4c4ca29bb7f5ee674d7601/
 */
export const isChunkLoadError = (hint: EventHint): boolean =>
  hint.originalException instanceof Error &&
  hint.originalException.name === 'SyntaxError' &&
  !!hint.originalException.message.match("'<'");

export const isExpectedAxiosError = (hint: EventHint): boolean => {
  if (!isAxiosError(hint.originalException)) return false;

  if (axios.isCancel(hint.originalException)) return true;

  const { config, response } = hint.originalException;

  return (
    !!response &&
    (response.status === 401 || !!config.expectErrors?.[response.status])
  );
};

export const isProblemJSImageLoadErrror = (hint: EventHint): boolean =>
  hint.originalException instanceof DOMException &&
  hint.originalException.name === 'InvalidStateError';
