import { DataType } from '~/lib/pointsHistory';

import { eventFactory } from './utils';

const pointsHistoryEvent = eventFactory('points history');

export const changeDataType = (dataType: DataType): void =>
  pointsHistoryEvent({ action: 'select metric', label: dataType });

export const sortTable = (label: string): void =>
  pointsHistoryEvent({
    action: 'sort table',
    label,
  });
