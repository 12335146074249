import { AxiosRequestConfig } from 'axios';

import { KlassItem, TeacherShared } from '../shared';

export interface KlassAssignmentsIndexByExercisesItem {
  assignedAt: string | null;
  class: KlassItem;
  classId: string;
  className: string;
  defaultAssignmentName: string | null;
  hasSpiralReviewSkills: boolean;
  releaseDate: string | null;
  teacher: TeacherShared;
}

interface ListForAssignmentConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/exercises/${string}/class_assignments`;
}

export type KlassAssignmentsIndexByExercisesResponse =
  KlassAssignmentsIndexByExercisesItem[];

/**
 * GET /api/v2/exercises/${id}/class_assignments
 */
export const listForAssignment = (
  assignmentId: string,
): ListForAssignmentConfig => {
  return {
    method: 'GET',
    url: `/exercises/${assignmentId}/class_assignments`,
  } as const;
};
