import { Labels } from './common';
import { eventFactory } from './utils';

const userMenuEvent = eventFactory('user menu');

export const changePassword = (): void =>
  userMenuEvent({ action: 'change password' });

export const click = (): void => userMenuEvent({ action: Labels.Click });

export const logout = (): void => userMenuEvent({ action: 'logout' });

export const trackEvent = (eventAction: string): void =>
  userMenuEvent({ action: eventAction });
