import { AxiosRequestConfig } from 'axios';

import { ProblemtypeDetail } from '../shared';

export type ProblemtypesShowResponse = ProblemtypeDetail;

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/problemtypes/${string}`;
}

/**
 * GET /api/v2/problemtypes/{id}
 */
export const show = (id: string): ShowConfig => {
  return {
    method: 'GET',
    url: `/problemtypes/${id}`,
  } as const;
};
