import useSWR from 'swr';

import { useSnackbar } from '~/lib/hooks/useSnackbar';
import { ReadOnlyOptions } from '~/lib/sdk';
import { augmentSWR, swrKeyFor } from '~/lib/swr';

import {
  dedupingInterval,
  MobiusProblemAndAnswer,
  PreviewProps,
} from './utils';

interface Config extends ReadOnlyOptions {
  studentInClassId?: string | null;
}

export const useCurrentProblem = ({
  readOnly,
  studentInClassId,
}: Config): PreviewProps => {
  const { enqueueGenericError } = useSnackbar();
  const swrKey = swrKeyFor(() =>
    studentInClassId ? ['currentProblems.list', studentInClassId] : null,
  );

  // TODO: Create and use CurrentProblemIndexResponse
  return useSWR<MobiusProblemAndAnswer>(swrKey, {
    dedupingInterval,
    onError: () => enqueueGenericError(),
    shouldRetryOnError: false,
    revalidateIfStale: !readOnly,
    revalidateOnReconnect: !readOnly,
    use: [augmentSWR('problemData', ({ data }) => data)],
  }) as PreviewProps;
};
