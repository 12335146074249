import { AxiosRequestConfig } from 'axios';

interface Create {
  city: string;
  name: string;
  state: string;
  website: string;
  zip: string;
}

export interface FailedClaimsCreateConfig extends AxiosRequestConfig {
  data: Create;
  method: 'POST';
  url: '/claim_school/failed_claims';
}

/**
 * POST /api/v2/claim_school/failed_claims
 */
export const create = (data: Create): FailedClaimsCreateConfig => {
  return {
    method: 'POST',
    url: '/claim_school/failed_claims',
    data,
  };
};
