import { AxiosRequestConfig } from 'axios';

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/student_in_classes/${string}/current_problem`;
}

/**
 * GET /api/v2/student_in_classes/{id}/current_problem
 */
export const list = (studentInClassId: string): ListConfig => {
  return {
    method: 'GET',
    url: `/student_in_classes/${studentInClassId}/current_problem`,
  } as const;
};
