import {
  inputLabelClasses,
  styled,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { uniqueId } from 'lodash';
import { forwardRef, Ref, useState } from 'react';
import { isElement } from 'react-is';

import { NumberInput, NumberInputProps } from '../number-input';
import { PasswordInput, PasswordInputProps } from '../password-input';
import { SearchInput, SearchInputProps } from '../search-input';
import { TextInput } from '../text-input';

interface BaseTextFieldProps
  extends Pick<
    MuiTextFieldProps,
    Exclude<
      keyof MuiTextFieldProps,
      'error' | 'ref' | 'rowsMax' | 'rowsMin' | 'type'
    >
  > {
  error?: string | boolean;
  // I don't really see us passing `component` in, but if we do, this will need to be updated
  formControlRef?: Ref<HTMLDivElement>;
  ref?: Ref<HTMLInputElement>;
  type?: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url';
}

interface NonNumberProps extends BaseTextFieldProps {
  type?: Exclude<BaseTextFieldProps['type'], 'number'>;
}

interface NumberTextFieldProps extends BaseTextFieldProps {
  inputProps?: NumberInputProps;
  type: 'number';
}

interface PasswordFieldProps extends BaseTextFieldProps {
  inputProps?: PasswordInputProps;
  type: 'password';
}

interface SearchTextFieldProps extends BaseTextFieldProps {
  inputProps?: SearchInputProps;
  type: 'search';
}

export type TextFieldProps =
  | NonNumberProps
  | NumberTextFieldProps
  | PasswordFieldProps
  | SearchTextFieldProps;

const Root = styled(MuiTextField, {
  shouldForwardProp: prop => prop !== 'inputType',
})<MuiTextFieldProps & { inputType: string }>(
  ({ fullWidth, inputType, theme }) =>
    inputType === 'search'
      ? {
          margin: theme.spacing(0.5),
          width: fullWidth ? '100%' : '90%',
          [`& .${inputLabelClasses.standard}`]: {
            [`&.${inputLabelClasses.formControl}`]: {
              transform: `translate(${theme.spacing(4)}, ${theme.spacing(
                3,
              )}) scale(1)`,
            },
            [`&.${inputLabelClasses.sizeSmall}`]: {
              transform: `translate(${theme.spacing(4)}, 17px) scale(1)`,
            },
            [`&.${inputLabelClasses.shrink}`]: {
              transform: 'translate(0, 1.5px) scale(0.75)',
            },
          },
          [`& .${inputLabelClasses.filled}`]: {
            [`&.${inputLabelClasses.formControl}`]: {
              transform: `translate(${theme.spacing(6)}, 21px) scale(1)`,
            },
            [`&.${inputLabelClasses.shrink}`]: {
              transform: 'translate(12px, 1.5px) scale(0.75)',
            },
          },
          [`& .${inputLabelClasses.outlined}`]: {
            [`&.${inputLabelClasses.formControl}`]: {
              transform: `translate(${theme.spacing(6)}, 9px) scale(1)`,
            },
            [`&.${inputLabelClasses.shrink}`]: {
              transform: 'translate(14px, -6px) scale(0.75)',
            },
          },
        }
      : {},
);

const getInputComponent = (
  type: string,
):
  | typeof NumberInput
  | typeof PasswordInput
  | typeof SearchInput
  | typeof TextInput => {
  if (type === 'number') return NumberInput;
  if (type === 'password') return PasswordInput;
  if (type === 'search') return SearchInput;

  return TextInput;
};

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(
    {
      error,
      formControlRef,
      helperText: helperTextProp,
      InputProps: InputPropsProp,
      inputProps,
      id: idProp,
      inputRef,
      multiline,
      maxRows,
      minRows,
      rows,
      type = 'text',
      ...textFieldProps
    },
    ref,
  ) {
    const [id] = useState(() => idProp ?? uniqueId(`text-field-${type}`));
    const inputComponent =
      InputPropsProp?.inputComponent ?? multiline
        ? InputPropsProp?.inputComponent
        : getInputComponent(type);

    const InputProps = {
      ...InputPropsProp,
      inputComponent,
      inputProps,
    };

    const helperText =
      isElement(error) || typeof error === 'string' ? error : helperTextProp;

    return (
      <Root
        {...textFieldProps}
        error={!!error}
        helperText={helperText}
        id={id}
        InputProps={InputProps}
        inputRef={ref || inputRef}
        inputType={type}
        multiline={multiline}
        ref={formControlRef}
        maxRows={maxRows}
        minRows={minRows}
        rows={rows}
        type={type === 'search' || type === 'number' ? 'text' : type}
      />
    );
  },
);
