import { AxiosRequestConfig } from 'axios';

import { TagItem } from '../shared';

interface List {
  state: string | null;
}

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  params: List;
  url: '/collections';
}

// TODO: Use this response
export type CollectionsIndexResponse = TagItem[];

/**
 * GET /api/v2/collections
 */
export const list = (state: string | null): ListConfig => {
  return {
    method: 'GET',
    params: { state },
    url: '/collections',
  };
};
