import { createContext } from 'react';

export interface DataGridLevel2Context {
  variant: 'head' | 'body' | 'footer';
}

export const DataGridLevel2Context = createContext<DataGridLevel2Context>(
  null as any,
);

/* istanbul ignore next: we don't care if this is covered */
if (process.env.NODE_ENV !== 'production') {
  DataGridLevel2Context.displayName = 'DataGridLevel2Context';
}
