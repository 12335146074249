import { AxiosRequestConfig } from 'axios';

export interface NewStudent {
  classId: string;
  firstName: string;
  lastName: string;
  schoolAssignedId: string | null | undefined;
}

export interface StudentsCreateRequest {
  dryRun: boolean;
  newStudents: NewStudent[];
}

export interface StudentsCreateStudent extends NewStudent {
  id: number;
  active: true;
  creationDate: number;
  mostRecentClassId: number;
  organizationId: number;
  password: string;
  schoolAssignedId: string;
  userName: string;
}

export type StudentsCreateResponse = StudentsCreateStudent[];

export type StudentsCreateConflictReason = 'NAME_MATCH' | 'SCHOOL_ID_MATCH';

export interface StudentCreateConflictStudent extends NewStudent {
  id: number;
  userName: string;
  mostRecentClass: string;
  reason: StudentsCreateConflictReason;
}

export type StudentsCreateConflictResponse = StudentCreateConflictStudent[];

interface CreateConfig extends AxiosRequestConfig {
  body: StudentsCreateRequest;
  data: StudentsCreateRequest;
  method: 'POST';
  url: '/students';
}

/**
 * POST /api/v2/students
 *
 * { assignmentId: string, newStudents: NewStudent[] }
 */
export const create = (data: StudentsCreateRequest): CreateConfig => {
  return {
    method: 'POST',
    url: '/students',
    body: data,
    data,
  };
};
