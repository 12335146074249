import { AxiosRequestConfig } from 'axios';

interface Update {
  password: string;
}

interface UpdateConfig extends AxiosRequestConfig {
  body: { Password: string };
  data: { Password: string };
  method: 'PATCH';
  url: `/student_passwords/${string}`;
}

/**
 * PATCH /api/v2/student_passwords/{id}
 *
 * { password: string }
 */
export const update = (
  id: string,
  // TODO: Consider removing useAxios from the component and aligning this with other `update` calls
  // password is required. This is just a convenience for useAxios
  { password }: Update = { password: 'nil' },
): UpdateConfig => {
  const data = { Password: password };

  return {
    method: 'PATCH',
    url: `/student_passwords/${id}`,
    body: data,
    data,
  } as const;
};
