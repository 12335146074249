import { AxiosRequestConfig } from 'axios';

interface SimpleAction {
  type: 'video' | 'article';
  url: string;
}

export type Trigger = 'extend-trial' | 'find-school' | 'verify-email';

interface ActionHookAction {
  translation: string;
  trigger: Trigger;
  type: 'action-hook';
}

type Action = SimpleAction | ActionHookAction;

export interface Task {
  key: string;
  actions: Action[];
  completedAt: string | null;
  description: string;
  imageUrl: string | null;
  lastViewedAt: string | null;
  title: string;
}

export interface AchievementItem {
  key: string;
  description: string;
  imageUrl: string | null;
  tasks: Task[];
  title: string;
}

interface List {
  tags: string[];
}

export type AchievementsIndexResponse = AchievementItem[];

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  params: List;
  url: `/achievements`;
}

export const list = (...tags: string[]): ListConfig => ({
  method: 'GET',
  params: { tags },
  url: '/achievements',
});
