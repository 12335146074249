import { AxiosRequestConfig } from 'axios';

import { ModifiedSkillsByClass } from './types';

// Note: `active` refers to whether the Assignment belongs to the Class,
// while `available` refers to whether the Assignment is available to the Students
interface Update {
  active?: boolean; // For unarchiving
  available?: boolean;
  modifiedSkillsByClass?: ModifiedSkillsByClass; // For unarchiving
  releaseDate?: string | null;
}

interface UpdateConfig extends AxiosRequestConfig {
  body: Update;
  data: Update;
  method: 'PATCH';
  url: `/class_assignments/${string}`;
}

/**
 * PATCH /api/v2/class_assignments/{id}
 *
 * {
 *   addSkills: boolean
 *   available: boolean
 *   classId: string
 *   releaseDate: string
 * }
 */
export const update = (id: string, data: Update): UpdateConfig => {
  return {
    method: 'PATCH',
    url: `/class_assignments/${id}`,
    body: data,
    data,
  } as const;
};
