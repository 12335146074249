import { trackIntercomEvent } from '~/lib/config/intercom';

import { Labels } from './common';
import { eventFactory } from './utils';

const addWork = 'add work';
const archiveExamStr = 'archive exam';
const archiveFolderStr = 'archive Folder';
const createFolderStr = 'create Folder';
const renameFolderStr = 'rename Folder';

const workNavigatorEvent = eventFactory('work navigator');

export const archiveAssignment = (): void => {
  workNavigatorEvent({ action: 'archive assignment' });
};

export const archiveExam = (): void => {
  workNavigatorEvent({ action: archiveExamStr });
};

export const archiveFolder = (): void => {
  workNavigatorEvent({ action: archiveFolderStr, label: 'archive' });
};

export const cancelArchiveAssignment = (): void =>
  workNavigatorEvent({ action: 'archive assignmet', label: Labels.Cancel });

export const cancelArchiveExam = (): void =>
  workNavigatorEvent({ action: archiveExamStr, label: Labels.Cancel });

export const cancelArchiveFolder = (): void =>
  workNavigatorEvent({ action: archiveFolderStr, label: Labels.Cancel });

export const cancelCreateFolder = (): void =>
  workNavigatorEvent({ action: createFolderStr, label: Labels.Cancel });

export const cancelRenameFolder = (): void =>
  workNavigatorEvent({ action: renameFolderStr, label: Labels.Cancel });

export const clickAddWork = (): void =>
  workNavigatorEvent({ action: addWork, label: Labels.Click });

export const confirmCreateFolder = (): void => {
  workNavigatorEvent({ action: createFolderStr, label: Labels.Submit });
  trackIntercomEvent('created-folder');
};

export const confirmRenameFolder = (): void => {
  workNavigatorEvent({ action: renameFolderStr, label: Labels.Submit });
};

export const copyWork = (whose: string, what: string): void => {
  workNavigatorEvent({ action: `copy ${whose} ${what}` });
};

export const createAssignment = (): void =>
  workNavigatorEvent({ action: addWork, label: 'create assignment' });

export const createExam = (): void =>
  workNavigatorEvent({ action: addWork, label: 'create exam' });

export const createFolder = (): void =>
  workNavigatorEvent({ action: addWork, label: createFolderStr });

export const moveAssignment = (): void =>
  workNavigatorEvent({ action: 'move assignment' });

export const moveEvent = (eventType: string): void => {
  workNavigatorEvent({ action: `move ${eventType}` });
};

export const undoArchiveAssignment = (): void =>
  workNavigatorEvent({ action: 'undo archive assignment' });

export const undoArchiveExam = (): void =>
  workNavigatorEvent({ action: 'undo archive exam' });

export const undoArchiveFolder = (): void =>
  workNavigatorEvent({ action: 'undo archive folder' });
