import { AxiosRequestConfig } from 'axios';

import { TeacherShared } from '../shared';

export type CoteachersCreateResponse = TeacherShared[];

interface Create {
  teacherIds: string[];
}

interface CreateConfig extends AxiosRequestConfig {
  body: Create;
  data: Create;
  method: 'POST';
  url: `/classes/${string}/coteachers`;
}

/**
 * Add coteachers to a class
 *
 * POST /api/v2/classes/{id}/coteachers
 *
 * { teacherIds: string[] }
 */
export const create = (classId: string, data: Create): CreateConfig => {
  return {
    method: 'POST',
    url: `/classes/${classId}/coteachers`,
    body: data,
    data,
  } as const;
};
