import { StatusEvent } from '@gmm/sdk/ws';
import { useTeacherNotifications } from '~/classes/hooks';

import { useCurrentStatus } from './useCurrentStatus';

export const useHandEvent = (): void => {
  const { updateStudentStatus, updateStudentProblem } = useCurrentStatus({
    readOnly: true,
  });

  const onReceived = (message: StatusEvent): void => {
    const { status } = message;

    if (status?.type === 'HandLowered' || status?.type === 'HandRaised') {
      updateStudentStatus(status);
    }

    if (status?.type === 'OverrideProblem') {
      updateStudentProblem(status);
    }
  };

  useTeacherNotifications({ received: onReceived });
};
