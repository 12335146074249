import { AxiosRequestConfig } from 'axios';

import { KlassDetail, TeacherShared } from '../shared';

export type Coteacher = TeacherShared;

export type ClassDetail = KlassDetail;

export type KlassesShowResponse = KlassDetail;

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/classes/${string}`;
}

/**
 * GET /api/v2/classes/{id}
 */
export const show = (id: string): ShowConfig => {
  return {
    method: 'GET',
    url: `/classes/${id}`,
  } as const;
};
