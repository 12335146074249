import { AxiosRequestConfig } from 'axios';

import { Cadence, StudentShared, Subject } from '../shared';

export interface ReportsStudentInKlassResponse {
  results: ReportsStudentTier;
  student: StudentShared;
}

export interface ReportsStudentTier {
  key: string;
  name: string;
  cadence?: Cadence;
  children: ReportsStudentTier[];
  counts: ReportsStudentTierCounts;
  skills: ReportsStudentSkill[];
}

type LastFive = 0 | 20 | 40 | 60 | 80 | 100;

export interface ReportsStudentTierCounts {
  accuracy: number;
  attempts: number;
  lastFive: LastFive;
  rating?: number;
}

export interface ReportsStudentSkill {
  id: string;
  name: string;
  accuracy: number;
  attempts: number;
  color: 'untried' | 'red' | 'yellow' | 'green' | 'silver' | 'gold';
  lastCorrect: string | null;
  lastFive: LastFive;
  rating: number;
}

interface StudentInKlassConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/reports/student_in_klass/${string}`;
  params: { subject: Subject };
}

/**
 * GET /api/v2/reports/student_in_klass/{id}?subject={subject}
 */
export const studentInKlass = (
  id: string,
  subject: Subject,
): StudentInKlassConfig => {
  return {
    method: 'GET',
    params: { subject },
    url: `/reports/student_in_klass/${id}`,
  } as const;
};
