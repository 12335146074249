// `readonly` is only here for backwards comptability and should not be
// applied for new SDK calls.

import { AxiosRequestConfig } from 'axios';

export interface UpdateWorkFolder {
  sourceId: string;
  positionsById: string[];
}

export interface MyWorkUpdateRequest {
  from?: UpdateWorkFolder;
  to: UpdateWorkFolder;
  targetId: string;
}

interface UpdateConfig extends AxiosRequestConfig {
  body: MyWorkUpdateRequest;
  data: MyWorkUpdateRequest;
  method: 'PUT';
  url: '/my_work';
}

/**
 * PUT /api/v2/my_work
 *
 * { from?: { sourceId: string, positionsById: string[], }, to: { sourceId: string, positionsById: string[], }, targetId: string }
 */
export const update = (data: MyWorkUpdateRequest): UpdateConfig => {
  return {
    method: 'PUT',
    url: '/my_work',
    body: data,
    data,
  };
};
