import { AxiosRequestConfig } from 'axios';

import { StudentInKlassBase, StudentShared } from '../shared';
import { StudentExamStatus } from '../studentExams';

interface Show {
  format?: 'pdf';
  includeAnswerKey?: boolean;
  isFollowUp?: boolean;
  type?: 'Exact' | 'Practice';
}

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  params: Omit<Show, 'format'>;
  qs: Omit<Show, 'format'>;
  url: `/exams/${string}.pdf` | `/exams/${string}`;
}

interface KlassExamDetail {
  autoAcceptExpiresAt: string;
  classExamId: string;
  name: string;
  students: SelectedExamStudent[];
}

export type ExamsShowResponse = KlassExamDetail;

interface StudentExamItemResult {
  isFollowUp: boolean;
  score: number;
  status: StudentExamStatus;
  studentExamId: string; // If `NotGenerated`, this is an empty string
  total: number; // If `NotGenerated`, this is 0
}
export type StudentExam = StudentExamItemResult;

export type SelectedExamStudent = KlassExamDetailStudentExamItem;

interface KlassExamDetailStudentExamItem {
  followUp: StudentExam;
  initial: StudentExam;
  student: StudentShared;
  studentInClass: StudentInKlassBase;
  studentInClassId: string;
}

/**
 * GET /api/v2/exams/{id}?isFollowUp={isFollowUp}
 */
export const show = <T extends Show>(
  id: string,
  { format, ...params }: T,
): ShowConfig => {
  const url =
    format === 'pdf'
      ? (`/exams/${id}.pdf` as const)
      : (`/exams/${id}` as const);

  return {
    method: 'GET',
    url,
    params,
    qs: params,
  };
};
