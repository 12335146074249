import { useActionCable } from '~/lib/webSocket/useActionCable';

interface Message {
  type: 'newStudentInClass' | 'removedStudentInClass';
}

export const useRealtimeClass = (
  classId: string,
  onReceived: (message: Message) => Promise<void>,
  onConnected: () => Promise<void>,
): void => {
  useActionCable(classId ? { channel: 'KlassChannel', classId } : null, {
    received: onReceived,
    connected: onConnected,
  });
};
