import { AxiosRequestConfig } from 'axios';

interface Create {
  skillTypes: string[];
}

interface CreateConfig extends AxiosRequestConfig {
  body: Create;
  data: Create;
  method: 'POST';
  url: `/classes/${string}/mixed_review_skills`;
}

/**
 * POST /api/v2/classes/{id}/mixed_review_skills
 *
 * { skillTypes: string[] }
 */
export const create = (id: string, data: Create): CreateConfig => {
  return {
    method: 'POST',
    url: `/classes/${id}/mixed_review_skills`,
    body: data,
    data,
  } as const;
};
