import { AxiosRequestConfig } from 'axios';

import { ExercisesDestroyResponseItem } from '../shared';

interface Update {
  classAssignments?: ExercisesDestroyResponseItem[]; // for unarchiving
  name?: string;
  parentFolderId?: string;
}

interface UpdateConfig extends AxiosRequestConfig {
  body: Update;
  data: Update;
  method: 'PUT';
  url: `/exercise_folders/${string}`;
}

export interface ExerciseFoldersUpdateResponse {
  name?: string;
}

/**
 * PUT /api/v2/exercise_folders/{id}
 *
 * { name?: string; parentFolderId: string }
 */
export const update = (id: string, data: Update): UpdateConfig => {
  return {
    method: 'PUT',
    url: `/exercise_folders/${id}`,
    body: data,
    data,
  } as const;
};
