import { FC, lazy, Suspense } from 'react';

import { Sibling } from '~/lib/fullscreenPreview';
import { useExampleProblem } from '~/lib/problemPreview/hooks';

const ProblemPreview = lazy(
  () => import(/* webpackChunkName: "problemPreview" */ '~/lib/problemPreview'),
);

interface ExamplePreviewProps {
  description?: string;
  isPreviewModal?: boolean;
  name?: string;
  siblingSkills?: Sibling[];
  skillType: string;
  title?: string;
}

export const ExamplePreview: FC<ExamplePreviewProps> = ({
  description,
  isPreviewModal = false,
  title,
  name,
  siblingSkills,
  skillType,
}) => {
  const previewProps = useExampleProblem({ skillType });

  return (
    <Suspense fallback={<noscript />}>
      <ProblemPreview
        {...previewProps}
        description={description}
        elevation={0}
        isPreviewModal={isPreviewModal}
        name={name}
        showRefresh
        siblingSkills={siblingSkills}
        title={title}
      />
    </Suspense>
  );
};
