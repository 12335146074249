import { includes } from 'lodash';
import { DateTime } from 'luxon';

import { ProfileContext } from '../authProvider';
import { SalesStage } from '../utils';

export interface StatusBar {
  canDismiss: boolean;
  shouldShow: boolean;
}

interface StatusBarVisibilityHelper {
  daysLeft: number;
  isNoQuote: boolean;
  isQuoted: boolean;
  isTrial: boolean;
}

const hidden = {
  canDismiss: false,
  shouldShow: false,
};

const salesStages: SalesStage[] = ['quoted', 'invoiced', 'no_quote'];

export function statusBarVisibilityHelper({
  daysLeft,
  isNoQuote,
  isQuoted,
  isTrial,
}: StatusBarVisibilityHelper): StatusBar {
  if (isTrial) {
    return {
      canDismiss: daysLeft > 10,
      shouldShow: isNoQuote ? daysLeft < 31 : daysLeft < 15,
    };
  }

  const canDismiss = daysLeft > 7;

  if (isNoQuote) return { canDismiss, shouldShow: daysLeft < 209 };
  if (isQuoted) return { canDismiss, shouldShow: daysLeft < 122 };

  return { canDismiss, shouldShow: daysLeft < 15 };
}

export function getStatusBarVisibility(
  { accountExpiration, isQuoted, isNoQuote, salesStage }: ProfileContext,
  daysLeft: number,
  isTrial: boolean,
): StatusBar {
  if (!includes(salesStages, salesStage)) return hidden;

  const expirationDate = DateTime.fromISO(accountExpiration);

  if (!expirationDate.isValid) return hidden;

  const now = DateTime.now();
  const isExpired = expirationDate < now;

  if (isExpired) return hidden;

  return statusBarVisibilityHelper({ daysLeft, isNoQuote, isQuoted, isTrial });
}
