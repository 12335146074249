import { AxiosRequestConfig } from 'axios';

interface GradeExam {
  correct: boolean;
  id: string;
  partialCredit: number;
  pos: number;
  status: string;
}

export interface UpdateStudentExam {
  turnIn?: boolean;
  grades?: GradeExam[];
}

interface UpdateConfig extends AxiosRequestConfig {
  body: UpdateStudentExam;
  data: UpdateStudentExam;
  method: 'PATCH';
  url: `/student_exams/${string}`;
}

/**
 * PATCH /api/v2/student_exams/{id}
 */
export const update = (id: string, data: UpdateStudentExam): UpdateConfig => {
  return {
    method: 'PATCH',
    url: `/student_exams/${id}`,
    body: data,
    data,
  } as const;
};
