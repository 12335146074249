import { MouseEventHandler, useRef } from 'react';
import ReactGA, { EventArgs } from 'react-ga4';
import { I18nKey, Namespace, useTranslation } from 'react-i18next';

import { Clear } from '@gmm/icons';
import {
  OptionsObject,
  SnackbarAction,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar as useNotistackSnackbar,
  VariantType,
  Button,
} from '@gmm/ui';
import { ids as allIds } from '~/lib/constants';

const ids = allIds.snackbar;

type BaseOptions = Omit<OptionsObject, 'action'>;

interface Options extends BaseOptions {
  action?: {
    label: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
  } | null;
}

export type EnqueueSnackbar = (
  message: SnackbarMessage,
  options?: Options,
) => SnackbarKey;
type EnqueueActionSnackbar = (
  message: SnackbarMessage,
  onClick: MouseEventHandler<HTMLButtonElement>,
  options?: Omit<Options, 'action'>,
) => SnackbarKey;
type MakeVariant = (variant: VariantType) => EnqueueSnackbar;

export interface UseSnackbar {
  enqueueError: EnqueueSnackbar;
  enqueueGenericError: (options?: Options) => SnackbarKey;
  enqueueInfo: EnqueueSnackbar;
  enqueueSnackbar: EnqueueSnackbar;
  enqueueRetrySnackbar: EnqueueActionSnackbar;
  enqueueSuccess: EnqueueSnackbar;
  enqueueUndoSnackbar: EnqueueActionSnackbar;
  enqueueWarning: EnqueueSnackbar;
}

const makeEvent = (label?: string): EventArgs => ({
  category: 'snackbar',
  action: 'clicked',
  label,
});
const closeEvent = makeEvent('close');

export const AUTO_HIDE_AFTER = 4000;
const MAX_RETRIES = 2;

/**
 * This exists purely to do a quick check and remove and duplicates from the UI
 *
 * In the future, we may be able to use the `preventDuplicate` prop on the
 * provider and make something happen when a duplicate is shown
 *
 * @see https://github.com/iamhosseindhv/notistack/issues/234
 */
const makeId = (message: SnackbarMessage, options: Options = {}): SnackbarKey =>
  options.key || btoa(encodeURIComponent(JSON.stringify({ message, options })));

interface Options {
  autoTranslate?: boolean;
}

export const useSnackbar = ({ autoTranslate }: Options = {}): UseSnackbar => {
  const [t] = useTranslation<Namespace>();
  const { closeSnackbar, enqueueSnackbar: notistackEnqueueSnackbar } =
    useNotistackSnackbar();
  const retries = useRef<{ id?: SnackbarKey; count: number }>({ count: 0 });
  const lastMessage = useRef<{
    id: SnackbarKey;
    key: SnackbarKey;
    message: SnackbarMessage;
    options: Options;
  } | null>(null);

  const { current: handleClose } = useRef((key: SnackbarKey) => () => {
    ReactGA.event(closeEvent);
    closeSnackbar(key);
  });

  const { current: enqueueSnackbar } = useRef<EnqueueSnackbar>(
    (message, options = {}) => {
      const id = makeId(message, options);
      const action: SnackbarAction = (key: SnackbarKey) => {
        const label = options.action?.label ?? '';
        const handleAction: MouseEventHandler<HTMLButtonElement> = event => {
          ReactGA.event(makeEvent(label));
          options.action?.onClick(event);
          closeSnackbar(key);
        };

        return (
          <>
            {options.action && (
              <Button data-testid={ids.actionButton} onClick={handleAction}>
                {label}
              </Button>
            )}
            <Button
              color="inherit"
              data-testid={ids.closeButton}
              onClick={handleClose(key)}
            >
              <Clear />
            </Button>
          </>
        );
      };

      if (lastMessage.current?.id === id && !options.preventDuplicate) {
        closeSnackbar(lastMessage.current?.key);
      }

      lastMessage.current = {
        id,
        key: notistackEnqueueSnackbar(message, {
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          ...options,
          action,
          onExited: (event, key) => {
            options.onExited?.(event, key);

            if (lastMessage.current?.key === key) {
              lastMessage.current = null;
            }
          },
        }),
        message,
        options,
      };

      return lastMessage.current.key;
    },
  );

  const makeVariant: MakeVariant = _ => (message, options) =>
    enqueueSnackbar(
      autoTranslate && typeof message === 'string'
        ? t(message as I18nKey)
        : message,
      {
        ...options,
        // After I discuss with Matt further, we may enable this
        // variant
      },
    );

  const { current: enqueueError } = useRef(makeVariant('error'));
  const { current: enqueueInfo } = useRef(makeVariant('info'));
  const { current: enqueueSuccess } = useRef(makeVariant('success'));
  const { current: enqueueWarning } = useRef(makeVariant('warning'));

  const { current: enqueueUndoSnackbar } = useRef<EnqueueActionSnackbar>(
    (message, onClick, options = {}) =>
      enqueueSuccess(message, {
        ...options,
        action: { label: t('app:undo'), onClick },
      }),
  );

  const { current: enqueueRetrySnackbar } = useRef<EnqueueActionSnackbar>(
    (message, onClick, options = {}) => {
      const id = makeId(message, options);

      if (retries.current.id !== id) {
        retries.current = { id, count: 0 };
      }

      if (++retries.current.count > MAX_RETRIES) {
        return enqueueError(message, options);
      }

      return enqueueError(message, {
        ...options,
        action: { label: t('app:retry'), onClick },
      });
    },
  );

  const { current: enqueueGenericError } = useRef<
    (options?: Options) => SnackbarKey
  >((options: Options = {}) =>
    enqueueSnackbar(t('app:error.snackbar'), options),
  );

  const { current: snackbarActions } = useRef({
    enqueueError,
    enqueueGenericError,
    enqueueInfo,
    enqueueRetrySnackbar,
    enqueueSnackbar,
    enqueueSuccess,
    enqueueUndoSnackbar,
    enqueueWarning,
  });

  return snackbarActions;
};
