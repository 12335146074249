import axios from 'axios';
import { useCallback, useMemo } from 'react';

import * as apiSdk from '@gmm/sdk';
import { ClassList } from '@gmm/sdk/classes';
import { KlassesCreateResponse } from '@gmm/sdk/classes/create';
import { isAxiosError } from '~/lib/config/axios';
import { useSnackbar } from '~/lib/hooks/useSnackbar';
import { ReadOnlyOptions } from '~/lib/sdk';
import { getEmptyArray } from '~/lib/type-utils';

import { useGetClassList } from './useGetClassList';

interface UseClassList {
  classList: ClassList;
  classNameExists: (name: string) => boolean;
  createClass: (name: string, subject: string) => Promise<string | void>;
  error: unknown;
  isLoadingList: boolean;
}

export const useClassList = ({
  readOnly,
}: ReadOnlyOptions = {}): UseClassList => {
  const { enqueueGenericError } = useSnackbar({ autoTranslate: true });
  const { activeClasses, error, isValidating, mutate } = useGetClassList({
    readOnly,
  });

  const createClass = useCallback(
    async (className: string, subject: string) => {
      try {
        const name = className.trim();
        const config = apiSdk.classes.create({ name, subjects: [subject] });
        const { data } = await axios.request<KlassesCreateResponse>(config);

        await mutate(list => [...(list ?? []), data], false);

        return data.id;
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 400) {
          throw error;
        }

        enqueueGenericError();

        return;
      }
    },
    [enqueueGenericError, mutate],
  );

  return useMemo(() => {
    const classNameExists = (name: string): boolean => {
      const normalized = name.toLowerCase().trim();

      return !!activeClasses?.some(
        ({ name }) => name.toLowerCase().trim() === normalized,
      );
    };

    return {
      classList: activeClasses ?? getEmptyArray(),
      classNameExists,
      createClass,
      error,
      isLoadingList: isValidating,
    };
  }, [activeClasses, createClass, error, isValidating]);
};
