import axios, { AxiosResponse } from 'axios';
import { noop } from 'lodash';
import {
  Log,
  UserProfile,
  User as BaseUser,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client-ts';

import * as apiSdk from '@gmm/sdk';

export type ProfileStudentSharing = 'None' | 'District' | 'School';

export type Idps = 'local' | 'Clever' | 'Google' | 'classLink';

export type LowercaseIdps = Lowercase<Idps>;

export type AccountType = 'trial' | 'pilot' | 'paid';

export type SalesStageResponse = string | undefined;

export type RecognizedSalesStageResponseTypes =
  | 'No quote'
  | 'Invoiced'
  | 'Quoted';

export type ProviderTitle =
  | 'Local'
  | 'Google Classroom'
  | 'ClassLink'
  | 'Clever';

export type SalesStage = 'no_quote' | 'invoiced' | 'quoted';

// TODO: Move to `sdk/` then create+apply builder
export interface User extends BaseUser {
  profile: UserProfile & {
    acct_exp?: string;
    acct_type?: AccountType;
    idp?: Idps;
    permissions?: string;
    sales_stage?: SalesStageResponse;
    student_sharing?: ProfileStudentSharing;
    username?: string;
  };
}

const { origin } = window.location;
const config: UserManagerSettings = {
  authority: process.env.HALL_MONITOR_URL,
  client_id: 'teacherapp',
  clockSkewInSeconds: 15 * 60, // 15 minutes in seconds
  post_logout_redirect_uri: `${origin}/oidc/logout`,
  redirect_uri: `${origin}/oidc/login`,
  response_type: 'code',
  revokeTokensOnSignout: true,
  scope:
    'openid profile teacherapi email teacher:rw student:rw organization:ro',
  silent_redirect_uri: `${origin}/oidc/renew`,
  userStore: new WebStorageStateStore({
    store: window.sessionStorage,
  }),
};

if (process.env.NODE_ENV === 'development') {
  Log.setLogger(console);
}

export const userManager = new UserManager(config);

const deletePresence = (): Promise<void | AxiosResponse<any>> => {
  const config = apiSdk.presence.destroy();

  return axios.request(config).catch(noop);
};

function goHome(): void {
  // clear out history and reload
  window.history.replaceState({}, '', '/');
  window.history.go();
}

export const signOut = async (): Promise<void> => {
  await deletePresence();
  await userManager.signoutRedirect();
  userManager.clearStaleState();
};

export const signOutCallback = async (): Promise<void> => {
  try {
    await userManager.signoutRedirectCallback();
    userManager.clearStaleState();
  } catch {
  } finally {
    goHome();
  }
};

export const silentRenewCallback = async (): Promise<void> => {
  await userManager.signinSilentCallback();
};

export const signInSilent = async (): Promise<void> => {
  await userManager.signinSilent();
};

export const idpToProviderTitleMap = (idp: LowercaseIdps): ProviderTitle => {
  switch (idp) {
    case 'clever':
      return 'Clever';
    case 'classlink':
      return 'ClassLink';
    case 'google':
      return 'Google Classroom';
    default:
      return 'Local';
  }
};
