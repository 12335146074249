import { FC, useEffect } from 'react';

import { useSnackbar } from '~/lib/hooks/useSnackbar';

import { useAuth } from './authProvider';

export const Renew: FC = () => {
  const { silentRenewCallback } = useAuth();
  const { enqueueGenericError } = useSnackbar();

  useEffect(() => {
    const doSilentRenewCallback = async (): Promise<void> => {
      try {
        await silentRenewCallback();
      } catch {
        enqueueGenericError();
      }
    };

    doSilentRenewCallback();
  }, [enqueueGenericError, silentRenewCallback]);

  return null;
};
