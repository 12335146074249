import { Dispatch, SetStateAction, useMemo } from 'react';

import { useStorage } from '../use-storage';

export enum DisplayNameFormat {
  FirstLast = 'first last',
  LastFirst = 'last first',
}

type DisplayNameState = [
  format: DisplayNameFormat,
  setFormat: Dispatch<SetStateAction<DisplayNameFormat>>,
] & {
  isDisplayNameFirstLast: boolean;
};

export const useDisplayNameState = (): DisplayNameState => {
  const [displayNameFormat, setDisplayNameFormat] = useStorage(
    'nameFormat',
    DisplayNameFormat.FirstLast,
  );

  return useMemo(() => {
    const returnValue = [
      displayNameFormat,
      setDisplayNameFormat,
    ] as DisplayNameState;

    returnValue.isDisplayNameFirstLast =
      displayNameFormat === DisplayNameFormat.FirstLast;

    return returnValue;
  }, [displayNameFormat, setDisplayNameFormat]);
};
