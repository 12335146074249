import ReactGA from 'react-ga4';

// These are defined under GA -> Admin -> Property: Custom Definitions
interface UserDimensions {
  dimension1: string | null; // userID
  dimension2: string | null; // full story url
  dimension3: string | null; // idp
  userId: string | null;
}

// initialize will throw if the measurement id is falsey
ReactGA.initialize(process.env.GA_MEASUREMENT_ID || ' ');

if (process.env.NODE_ENV === 'development') {
  ReactGA.set({ sendHitTask: null });
}

export function setGaUserDimensions(dimensions: Partial<UserDimensions>): void {
  ReactGA.set(dimensions);
}
