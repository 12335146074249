import {
  Box,
  TextFieldProps as MuiTextFieldProps,
  useForkRef,
} from '@mui/material';
import { FC, Ref, useEffect, useRef } from 'react';

import { TextField, TextFieldProps } from '../text-field';

const getTextFieldProps = (
  props: MuiTextFieldProps,
  ref: Ref<HTMLDivElement>,
): TextFieldProps => ({
  ...props,
  formControlRef: ref,
  // restricts ability to type in the input
  inputProps: { ...props.inputProps, readOnly: true },
  ref: null,
  sx: { width: 115 },
  tabIndex: 0,
  type: 'text',
});

interface Props {
  end: MuiTextFieldProps;
  setIsEndFocused: (isEndFocused: boolean) => void;
  start: MuiTextFieldProps;
  toText: string;
}

export const DateRangeInput: FC<Props> = ({
  end,
  setIsEndFocused,
  start,
  toText,
}) => {
  const localStartRef = useRef<HTMLDivElement>(null);
  const localEndRef = useRef<HTMLDivElement>(null);
  const startRef = useForkRef(localStartRef, start.ref);
  const endRef = useForkRef(localEndRef, end.ref);

  // For whatever reason, they call `.focus()` on the divs, but don't offer a
  // way using `onFocus` to capture those. So, we are manually doing it to the `ref`
  useEffect(() => {
    const startHandler = (): void => setIsEndFocused(false);
    const endHandler = (): void => setIsEndFocused(true);
    const startTextField = localStartRef.current;
    const endTextField = localEndRef.current;

    startTextField?.addEventListener('focus', startHandler);
    endTextField?.addEventListener('focus', endHandler);

    return () => {
      startTextField?.removeEventListener('focus', startHandler);
      endTextField?.removeEventListener('focus', endHandler);
    };
  }, [endRef, setIsEndFocused, startRef]);

  return (
    <>
      <TextField {...getTextFieldProps(start, startRef)} />
      <Box sx={{ mx: 2 }}>{toText}</Box>
      <TextField {...getTextFieldProps(end, endRef)} />
    </>
  );
};
