import { AxiosRequestConfig } from 'axios';

import { StudentInKlassBase, StudentShared } from '../../../sdk/src/shared';
import { BASE_API } from '../utils';

export interface KlassAssignmentDetailStudent {
  complete: boolean;
  errorsFixed: number | null;
  pointsEarnedTotal: number;
  pointsEarnedNewSkills: number;
  pointsEarnedSpiralReview: number;
  pointsToFinish: number;
  student: StudentShared;
  studentInClass: StudentInKlassBase;
  studentInClassId: string;
}

export interface KlassAssignmentDetail {
  assignedAt: string;
  name: string;
  pointsRequiredTotal: number;
  pointsRequiredNewSkills: number;
  pointsRequiredSpiralReview: number;
  releaseDate: string | null;
  students: KlassAssignmentDetailStudent[];
  studentsCompleted: string;
}

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/class-assignments/${string}`;
}

/**
 * GET /v1/class-assignments/{id}
 */
export const show = (id: string): ShowConfig => {
  return {
    baseURL: BASE_API,
    method: 'GET',
    url: `/class-assignments/${id}`,
  } as const;
};
