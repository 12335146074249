import { useFeatureFlag } from '~/lib/launchDarkly';

export const useStudentDataEndpoint = (): boolean => {
  const voodooEndpoints = useFeatureFlag(
    'Feature.StudentData.UseNewEndpoints',
    false,
  );

  return voodooEndpoints;
};
