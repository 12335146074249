import { noop } from 'lodash';
import { Key, memo, ReactNode } from 'react';
import { Updater, useImmer } from 'use-immer';

import { createNamedContext } from '~/lib/createNamedContext';

export interface LayerProps {
  onClose: () => void;
  open: boolean;
}

export const LayerContext = createNamedContext<Updater<LayerDefinition[]>>(
  'LayerContext',
  noop,
);

export type Level = 1 | 2 | 3;

export type RenderLayer = (props: LayerProps & { key: Key }) => ReactNode;

interface LayerDefinition {
  render: RenderLayer;
  key: Key;
  level: Level;
  props: LayerProps;
}

export const LayerProvider = memo(function MemoizedLayerProvider({ children }) {
  const [layers, setLayers] = useImmer<LayerDefinition[]>([]);

  return (
    <LayerContext.Provider value={setLayers}>
      {children}
      {layers.map(layer => layer.render({ ...layer.props, key: layer.key }))}
    </LayerContext.Provider>
  );
});
