import { memoize, partialRight } from 'lodash';
import { orderBy } from 'natural-orderby';

import { pathsAreEqual } from './paths';

export type SortOrder = 'asc' | 'desc';

type SortBy<T> = (collection: T[]) => T[];
type SortIdentifier<T> = (value: T) => unknown;

export const asc: SortOrder = 'asc';
export const desc: SortOrder = 'desc';

export function toggleSortOrder<T>(
  sortOrder: SortOrder,
  newSortByColumn: T,
  currentSortByColumn: T | null | undefined,
): { readonly sortBy: T; readonly sortOrder: SortOrder } {
  if (!pathsAreEqual(newSortByColumn, currentSortByColumn)) {
    return {
      sortBy: newSortByColumn,
      sortOrder: asc,
    };
  }

  return {
    sortBy: newSortByColumn,
    sortOrder: sortOrder === asc ? desc : asc,
  };
}

// When using `sortBy` from a MobX store `@computed` method (which is preferred),
// MobX already uses memoization, so it isn't used by default here
export function sortBy<T, C = keyof T>(
  sortByColumn: SortIdentifier<T> | C | Array<SortIdentifier<T> | C>,
  sortOrder: SortOrder = asc,
  { shouldMemoize = false } = {},
): SortBy<T> {
  let sortOrders: SortOrder | SortOrder[] = sortOrder;

  if (Array.isArray(sortByColumn)) {
    sortOrders = sortByColumn.map(() => sortOrder);
  }

  const sortFn = partialRight(orderBy, sortByColumn, sortOrders);

  if (shouldMemoize) {
    return memoize(sortFn);
  }

  return sortFn;
}
