import { useFlags } from 'launchdarkly-react-client-sdk';
import { camelCase } from 'lodash';

import { isFlagOff } from './utils';

export type UseFeatureFlag<T> = () => T | null;

export function makeFeatureFlag<T = boolean>(
  flag: string,
  defaultValue: T,
): UseFeatureFlag<T> {
  const camelCaseFlag = camelCase(flag);

  return () => {
    const flags = useFlags();

    const flagValue = Object.prototype.hasOwnProperty.call(
      flags || {},
      camelCaseFlag,
    )
      ? flags[camelCaseFlag]
      : defaultValue;

    return isFlagOff(flagValue) ? null : flagValue;
  };
}
