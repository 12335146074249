import { AxiosRequestConfig } from 'axios';

export interface UpdatePassword {
  currentPassword: string;
  newPassword: string;
}

interface UpdateConfig extends AxiosRequestConfig {
  data: UpdatePassword;
  method: 'PATCH';
  url: `password`;
}

export const update = (
  data: UpdatePassword,
  language: string,
): UpdateConfig => {
  const headers = { 'Content-Language': language };

  return {
    data,
    headers,
    method: 'PATCH',
    url: `password`,
  } as const;
};
