import { useIdentity } from '~/lib/auth';

import { makeFeatureFlag } from '../makeFeatureFlag';

const useOnboardingChecklistFeatureFlag = makeFeatureFlag<boolean>(
  'Feature.Onboarding.Checklist',
  false,
);

export const useShowOnboardingAchievements = (): boolean | null => {
  const isOn = useOnboardingChecklistFeatureFlag();
  const { ldUser } = useIdentity();

  return isOn && !!ldUser;
};
