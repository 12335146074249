export const restrictValue = (
  target: HTMLInputElement,
  previousValue = '',
): {
  selectionEnd: number | null;
  value: string;
} => {
  const { pattern, selectionStart, value } = target;

  if (!pattern) return { selectionEnd: selectionStart, value };

  const isValid = target.checkValidity();

  if (isValid) return { selectionEnd: selectionStart, value };

  const rePattern = new RegExp(pattern, 'g');
  const matches = value.match(rePattern);

  if (matches === null) {
    if (value.length < previousValue.length) {
      return { selectionEnd: selectionStart, value };
    }

    return { selectionEnd: selectionStart, value: previousValue };
  }

  const diff = matches.length - 1;

  target.value = matches.join('');

  /* istanbul ignore else */
  if (selectionStart !== null) {
    target.selectionEnd = selectionStart - diff;
  }

  return {
    selectionEnd: selectionStart && selectionStart - diff,
    value: matches.join(''),
  };
};
