import { lowerCase } from 'lodash';

import { CurrentStatusIndexItem as Student } from '@gmm/sdk/currentStatuses';
import { PropertyPath } from '~/lib/paths';

import { eventFactory, getColumnName } from './utils';

const currentStatusEvent = eventFactory('current status');

export const clearHelp = (studentHelpOrder: number): void =>
  currentStatusEvent({
    action: 'clear help',
    value: studentHelpOrder,
  });

export const sortTable = (columnPath: PropertyPath<Student>): void =>
  currentStatusEvent({
    action: 'sort table',
    label: lowerCase(getColumnName(columnPath)),
  });
