import { SkillDiscoveryItemType } from '@gmm/sdk/skillDiscovery';

type ValidType = SkillDiscoveryItemType;

export interface WorkItem {
  condensed?: boolean | null;
  name: string;
  type: ValidType;
}

export const isTypedItem = (item?: unknown): item is { type: string } =>
  typeof item === 'object' && item !== null && 'type' in item;

const makeTypeGuard = <Type extends string>(types: Type | Type[]) => {
  return <T extends { type: Type }>(item?: unknown): item is T =>
    isTypedItem(item) &&
    (Array.isArray(types)
      ? types.some(type => item.type === type)
      : item.type === types);
};

export const isFolderType = makeTypeGuard('Folder');
export const isAssignmentType = makeTypeGuard('Assignment');
export const isExamTemplateType = makeTypeGuard('ExamTemplate');
export const isExerciseType = makeTypeGuard(['Assignment', 'ExamTemplate']);
export const isSkillType = makeTypeGuard('Skill');
