import { AxiosRequestConfig } from 'axios';

interface DestroyConfig extends AxiosRequestConfig {
  method: 'DELETE';
  url: `/classes/${string}/coteachers/${string}`;
}

/**
 * DELETE /api/v2/classes/{classId}/coteachers/{teacherId}
 */
export const destroy = (classId: string, teacherId: string): DestroyConfig => {
  return {
    method: 'DELETE',
    url: `/classes/${classId}/coteachers/${teacherId}`,
  } as const;
};
