/* istanbul ignore file */
import { User } from '~/lib/auth';

import {
  setIntercomUser as bootIntercomUser,
  instantiateIntercom,
  shutdownIntercom,
} from './intercom';

instantiateIntercom();

export function setIntercomUser(user?: User | null): void {
  if (user) {
    bootIntercomUser(user);
  } else {
    shutdownIntercom();
  }
}
