export enum PartialCredit {
  None,
  Partial,
  Full,
}

export enum ProblemStatus {
  Unseen,
  ViewedNotAnswered,
  NotValid,
  Ready,
  Wrong,
  Right,
}

export interface BaseExamProblemItem {
  correct: boolean;
  id: string;
  partialCredit: PartialCredit;
  pos: number;
  status: keyof typeof ProblemStatus;
}

export interface ExamProblemItem extends BaseExamProblemItem {
  description: string;
  type: string;
  // Not returned from API. Added in the UI
  isGraded?: boolean;
}
