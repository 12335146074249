import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';

import { useFeatureFlag } from '~/lib/launchDarkly';

import { useCurrentTeacher } from '../../hooks/useCurrentTeacher';
import { useProfile } from '../authProvider';
import { AccountType } from '../utils';

import { getStatusBarVisibility } from './utils';

interface UseAccount {
  accountType: AccountType;
  canBeExtended: boolean;
  canDismissStatusBar: boolean;
  closeAccountStatusBar: () => void;
  displayDate: string;
  daysLeft: number;
  isExpired: boolean;
  isInvoiced: boolean;
  isNonStandardAccount: boolean;
  isTrial: boolean;
  showAccountSetup: boolean;
  showAccountStatusBar: boolean;
}

const defaultUseAccount: UseAccount = {
  accountType: 'paid',
  canBeExtended: false,
  canDismissStatusBar: false,
  closeAccountStatusBar: () => {},
  displayDate: 'Jan 1',
  daysLeft: 1000,
  isExpired: false,
  isInvoiced: false,
  isNonStandardAccount: false,
  isTrial: false,
  showAccountSetup: false,
  showAccountStatusBar: false,
};

export const useAccount = (): UseAccount => {
  const { data: teacher } = useCurrentTeacher();
  const profile = useProfile();
  const { accountExpiration, accountType, isInvoiced } = profile;

  const showSignUpV2Feature = useFeatureFlag('Feature.SignUp.v2');

  const expirationDate = DateTime.fromISO(accountExpiration);
  const daysLeft = Math.floor(expirationDate.diffNow().as('days'));

  const isTrial = accountType === 'trial';
  const { shouldShow, canDismiss: canDismissStatusBar } = useMemo(
    () => getStatusBarVisibility(profile, daysLeft, isTrial),
    [profile, daysLeft, isTrial],
  );

  const [showAccountStatusBar, setShowAccountStatusBar] = useState(
    shouldShow && showSignUpV2Feature,
  );

  const closeAccountStatusBar = (): void => setShowAccountStatusBar(false);

  if (!teacher) {
    return defaultUseAccount;
  }

  const { isHomeschooler, isInternational } = teacher.account;

  const now = DateTime.utc();
  const accountExpAsDateTime = DateTime.fromISO(accountExpiration);
  const isExpired = now > accountExpAsDateTime;

  const isNonStandardAccount = isInternational || isHomeschooler;
  const canBeExtended = isTrial && !isNonStandardAccount;

  const showAccountSetup = isExpired && canBeExtended;

  const displayDate = expirationDate.toLocaleString({
    month: 'long',
    day: 'numeric',
  });

  return {
    accountType,
    canBeExtended,
    canDismissStatusBar,
    closeAccountStatusBar,
    displayDate,
    daysLeft,
    isExpired,
    isInvoiced,
    isNonStandardAccount,
    isTrial,
    showAccountSetup,
    showAccountStatusBar:
      showAccountStatusBar && shouldShow && showSignUpV2Feature,
  };
};
