import { ResizeObserver } from '@juggle/resize-observer';
import { ResizeObserverCallback } from '@juggle/resize-observer/lib/ResizeObserverCallback';
import {
  ForwardedRef,
  RefCallback,
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useLatestRef } from '../use-latest-ref';
import { useMountedRef } from '../use-mounted-ref';

const cancel =
  window.cancelIdleCallback ?? (id => window.cancelAnimationFrame(id));
const request =
  window.requestIdleCallback ??
  ((cb: () => void): number => window.requestAnimationFrame(cb));

interface Dimensions {
  height: number;
  width: number;
}

const defaultDimensions: Dimensions = { height: 0, width: 0 };

let observer: ResizeObserver | null = null;
const callbacks = new WeakMap<
  Element,
  RefObject<ResizeObserverCallback | undefined>
>();

const getObserver = (): ResizeObserver => {
  if (!observer) {
    observer = new ResizeObserver((entries, observer) => {
      /* istanbul ignore if: this should never really happen */
      if (!Array.isArray(entries)) return;

      entries.forEach(entry => {
        const callback = callbacks.get(entry.target);

        callback?.current?.([entry], observer);
      });
    });
  }

  return observer;
};

const updateRef = <T extends Element>(
  ref: ForwardedRef<T> | undefined,
  node: T | null,
): void => {
  if (typeof ref == 'function') {
    ref(node);
  } else if (ref) {
    ref.current = node;
  }
};

export type UseResizeObserver<T extends Element> = {
  ref: RefCallback<T>;
} & Dimensions;

export const useResizeObserver = <T extends Element>(
  ref?: ForwardedRef<T>,
  onResize?: ResizeObserverCallback,
): UseResizeObserver<T> => {
  const mountedRef = useMountedRef();
  const [size, setSize] = useState(defaultDimensions);
  const previousSize = useRef(size);
  const onResizeRef = useLatestRef(onResize);
  const localRef = useRef<T | null>();
  const timerRef = useRef(0);
  // Wrap the callback in a function that checks for size differences first
  const onResizeWithCheck = useRef<ResizeObserverCallback>(
    ([entry], observer) => {
      cancel(timerRef.current);
      timerRef.current = request(
        () => {
          if (!mountedRef.current) return;

          const { contentRect } = entry;

          const newHeight = Math.round(contentRect.height);
          const newWidth = Math.round(contentRect.width);

          /* istanbul ignore if: until we find a decent way to test changing sizes */
          if (
            newHeight === previousSize.current.height &&
            newWidth === previousSize.current.width
          ) {
            return;
          }

          const newSize = { height: newHeight, width: newWidth };

          previousSize.current = newSize;
          setSize(newSize);
          onResizeRef.current?.([entry], observer);
        },
        { timeout: 100 },
      );
    },
  );

  const refCallback = useCallback(
    node => {
      const observer = getObserver();
      const { current: prevNode } = localRef;

      localRef.current = node;
      updateRef(ref, node);

      if (prevNode) {
        if (prevNode === node) return;

        observer.unobserve(prevNode);
        callbacks.delete(prevNode);

        /* istanbul ignore else */
        if (!node) return;
      }

      /* istanbul ignore if */
      if (!node) return;

      callbacks.set(node, onResizeWithCheck);
      observer.observe(node);
    },
    [ref],
  );

  const refElement = typeof ref !== 'function' ? ref?.current : null;

  // This makes it so it works with `useVirtual`
  useLayoutEffect(() => {
    // If there is no element tied to this ref, do nothing and nothing to cleanup
    if (!refElement) return;

    refCallback(refElement);

    // To make sure we stop listening
    return () => refCallback(null);
  }, [refElement, refCallback]);

  return useMemo(
    () => ({
      ref: refCallback,
      ...size,
    }),
    [refCallback, size],
  );
};
