import { AxiosRequestConfig } from 'axios';

import { QuestionType, StudentShared } from '../shared';

export interface ProblemReplacementSettings {
  inherited: boolean;
  replacementsPerDay: number;
}

export interface QuestionTypeSettings {
  inherited: boolean;
  questionTypes: QuestionType[];
}

export interface StudentOverrideSettings {
  questionTypeSettings: QuestionTypeSettings;
  problemReplacementSettings: ProblemReplacementSettings;
  readAloudSettings: ReadAloudSettings;
}

export interface ReadAloudSettings {
  inherited: boolean;
  readAloudEnabled: boolean;
}

interface Settings extends StudentOverrideSettings {
  blockGames?: boolean;
  blockPenalty?: boolean;
  limitMultipleChoiceTo?: number;
}

export interface StudentInKlassDetail {
  profile: StudentShared;
  settings: Settings;
}

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/student_in_classes/${string}`;
}

export type StudentInKlassesShowResponse = StudentInKlassDetail;

/**
 * GET /api/v2/student_in_classes/{id}
 */
export const show = (studentInClassId: string): ShowConfig => {
  return {
    method: 'GET',
    url: `/student_in_classes/${studentInClassId}`,
  } as const;
};
