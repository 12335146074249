import { TreeItems } from '../types';

/**
 * This mutates `items`. If you don't want to mutate, wrap it in a `produce`
 */
export function removeItem<T>(items: TreeItems<T>, id: string): void {
  for (let i = 0, l = items.length; i < l; i++) {
    const item = items[i];

    if (item.id !== id) {
      if (item.children.length > 0) removeItem(item.children, id);

      continue;
    }

    items.splice(i, 1);

    return;
  }
}
