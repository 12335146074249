import { AxiosRequestConfig } from 'axios';

export interface StudentPerformance {
  accuracy: number | null;
  proficiency: string;
  studentName: string;
  firstName?: string;
  lastName?: string;
  isInIndividualMixedReview?: boolean;
}
export interface MixedReviewSkill {
  accuracy: number;
  condensed: boolean;
  counts: {
    gold: number;
    green: number;
    red: number;
    silver: number;
    unattempted: number;
    yellow: number;
  };
  dateAdded: string;
  description: string;
  enabled: boolean;
  points: {
    mixedReview: number;
    assignment: number;
  } | null;
  proficiency: number;
  rotationDays: number[];
  studentsPerformance?: StudentPerformance[];
  type: string;
}

export interface MixedReviewSkillWithNotEnoughData extends MixedReviewSkill {
  counts: {
    gold: number;
    green: number;
    notEnoughData: number;
    red: number;
    silver: number;
    unattempted: number;
    yellow: number;
  };
}

export type MixedReviewSkillsWithNotEnoughData =
  MixedReviewSkillWithNotEnoughData[];
export type MixedReviewSkills = MixedReviewSkill[];

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/classes/${string}/mixed_review_skills`;
}

/**
 * GET /api/v2/classes/{id}/mixed_review_skills
 */
export const list = (classId: string): ListConfig => {
  return {
    method: 'GET',
    url: `/classes/${classId}/mixed_review_skills`,
  } as const;
};
