import { Alert, AlertProps } from '@mui/material';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { FC, ReactNode } from 'react';

const alertVariants: Variants = {
  alertIn: { height: 'auto', transition: { delay: 0.3 } },
  alertOut: { height: 0 },
};

export interface ModalDialogAlertProps extends Omit<AlertProps, 'children'> {
  text?: ReactNode;
}

export const ModalDialogAlert: FC<ModalDialogAlertProps> = ({
  text,
  ...props
}) => (
  <AnimatePresence>
    {text && (
      <motion.div
        animate="alertIn"
        exit="alertOut"
        initial="alertOut"
        variants={alertVariants}
      >
        <Alert {...props}>{text}</Alert>
      </motion.div>
    )}
  </AnimatePresence>
);
