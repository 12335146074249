import { FC, lazy, Suspense } from 'react';

import { MobiusProblemAndAnswer } from '~/lib/problemPreview/hooks/utils';

const ProblemPreview = lazy(
  () => import(/* webpackChunkName: "problemPreview" */ '~/lib/problemPreview'),
);

const noop = async (): Promise<undefined> => undefined;

interface Props {
  problemData: MobiusProblemAndAnswer;
}

export const StudentDetailFullscreenPreview: FC<Props> = props => {
  return (
    <Suspense fallback={<noscript />}>
      <ProblemPreview
        {...props}
        elevation={0}
        error={undefined}
        isPreviewModal
        isValidating={false}
        mutate={noop}
        name="studentDetail"
        type="student desk"
      />
    </Suspense>
  );
};
