import { eventFactory } from './utils';

const studentDataEvent = eventFactory('student data');

export const toggleColumn = (value: number, label?: string): void =>
  studentDataEvent({ action: 'toggle column', label, value });

export const viewedStudentCalendar = (): void => {
  studentDataEvent({ action: 'viewed student calendar' });
};
