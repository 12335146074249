import { AxiosRequestConfig } from 'axios';

interface ListForAssignmentFolderConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/exercise_folders/${string}/class_assignments`;
}

export interface KlassAssignmentsIndexByExerciseFolderResponse {
  count: number;
}

/**
 * GET /api/v2/exercise_folders/${id}/class_assignments
 */
export const listForAssignmentFolder = (
  assignmentFolderId: string,
): ListForAssignmentFolderConfig => {
  return {
    method: 'GET',
    url: `/exercise_folders/${assignmentFolderId}/class_assignments`,
  } as const;
};
