import { AxiosRequestConfig } from 'axios';

import { StudentInKlassBase, StudentShared } from '../../shared';

export interface StudentDataStudentInKlassAssignmentsItem {
  assignedAt: string;
  counts: {
    earned: number;
    errorsFixed: number;
    required: number;
  };
  name: string;
}

export interface StudentDataStudentInKlassAssignmentsResponse {
  classAssignments: StudentDataStudentInKlassAssignmentsItem[];
  student: StudentShared;
  studentInClass: StudentInKlassBase;
}

interface AssignmentsConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/student_data/student_in_classes/${string}/assignments`;
}

export const assignments = (studentInClassId: string): AssignmentsConfig => ({
  method: 'GET',
  url: `/student_data/student_in_classes/${studentInClassId}/assignments`,
});
