export enum Status {
  Success = 'Success',
  Partial = 'Partial',
  Failure = 'Failure',
}
export enum Type {
  Import = 'Import',
  Sync = 'Sync',
}

export interface AddedOrRemovedMetrics {
  id: string; // username
  name: string; // givenName familyName
}

export interface FailedMetrics extends AddedOrRemovedMetrics {
  message: string;
}

export interface LazyRosteredClassMessage {
  id: string; // classId
  name: string; // className
  provider: string;
  status: Status; // Enum
  type: Type; // Enum
  added?: AddedOrRemovedMetrics[];
  removed?: AddedOrRemovedMetrics[];
  failed?: FailedMetrics[];
}
