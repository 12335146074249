import { Location } from 'history';
import { TOptions } from 'i18next';
import { nanoid } from 'nanoid';
import { FC, useEffect, useRef, useState } from 'react';
import { I18nKey, Namespace, useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';

import { ConfirmationModal } from '../confirmationModal';
import { useFullscreenContext } from '../fullscreenPreview';

declare global {
  interface Window {
    [key: string]: any;
  }
}

type GlobalTrigger = (
  confirmCallback: ConfirmCallback,
) => ConfirmCallback | void;
type ConfirmCallback = (ok: boolean) => void;

const getGlobalTrigger = (key: string): GlobalTrigger =>
  window[Symbol.for(key) as unknown as string] as unknown as GlobalTrigger;

export const getUserConfirmation = (
  dialogKey: string,
  callback: ConfirmCallback,
): void | ConfirmCallback => {
  const dialogTrigger = getGlobalTrigger(dialogKey);

  if (dialogTrigger) {
    return dialogTrigger(callback);
  }

  callback(true);
};

interface NewProps {
  readonly affirmative: I18nKey;
  readonly message: I18nKey;
  readonly negative: I18nKey;
  readonly onAbort?: () => void;
  readonly onContinue?: (nextLocation: Location) => void;
  readonly onTransition?: (nextLocation: Location) => boolean;
  readonly replace?: boolean;
  readonly title: I18nKey;
  readonly translationOptions?: TOptions<Record<string, any>>;
  readonly when: boolean;
}

const BASE_SYMBOL_ID = '__PreventTransitionPrompt_';

export const PreventTransitionPrompt: FC<NewProps> = ({
  affirmative = 'app:unsavedChanges.continue',
  message = 'app:unsavedChanges.message',
  negative = 'app:unsavedChanges.goBack',
  onAbort,
  onContinue,
  onTransition,
  replace = false,
  title = 'app:unsavedChanges.title',
  translationOptions,
  when,
}) => {
  const [t] = useTranslation<Namespace>();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const afterOpenChange = useRef<null | (() => void)>(null);
  const triggerRef = useRef(Symbol.for(`${BASE_SYMBOL_ID}${nanoid()}`));
  const updateGlobalTrigger = (updateFn: GlobalTrigger): void => {
    window[triggerRef.current as unknown as keyof Window] = updateFn;
  };
  const { onClose: closeFullscreen } = useFullscreenContext();
  const removeTrigger = (): void => {
    delete window[triggerRef.current as unknown as keyof Window];
  };
  const showDialog = (allowTransitionCallback: ConfirmCallback): void => {
    setOpen(true);
    afterOpenChange.current = () => allowTransitionCallback(false);
  };

  const promptMessage = useRef((nextLocation: Location): string | boolean => {
    if (onTransition?.(nextLocation)) return true;

    setNextLocation(nextLocation);

    return Symbol.keyFor(triggerRef.current) as string;
  });

  const handleClose = (): void => {
    setOpen(false);
    afterOpenChange.current = () => updateGlobalTrigger(showDialog);
  };

  const handleAbort = (): void => {
    handleClose();
    onAbort?.();
  };

  const handleContinue = (): void => {
    updateGlobalTrigger((allowTransitionCallback: ConfirmCallback) =>
      allowTransitionCallback(true),
    );

    handleClose();

    if (nextLocation) {
      replace ? history.replace(nextLocation) : history.push(nextLocation);
      onContinue?.(nextLocation);
    }
  };

  useEffect(() => {
    afterOpenChange.current?.();
    afterOpenChange.current = null;

    if (open) closeFullscreen();
  }, [closeFullscreen, open]);

  useEffect(() => {
    updateGlobalTrigger(showDialog);

    return () => removeTrigger();
  }, []);

  return (
    <>
      <Prompt when={when} message={promptMessage.current} />
      <ConfirmationModal
        confirmText={t(affirmative, translationOptions)}
        content={t(message, translationOptions)}
        denyText={t(negative, translationOptions)}
        onClose={handleAbort}
        onConfirm={handleContinue}
        open={open}
        title={t(title, translationOptions)}
      />
    </>
  );
};
