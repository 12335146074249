import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { TeacherMe } from '@gmm/sdk/shared';
import { TeachersShowResponse as Response } from '@gmm/sdk/teachers';
import { swrKeyFor } from '~/lib/swr';

type UseCurrentTeacher = SWRResponse<TeacherMe, unknown>;

export const useCurrentTeacher = (): UseCurrentTeacher => {
  const axiosTokenSetRef = useRef(
    !!axios.defaults.headers.common.Authorization,
  );
  const [canRequest, setCanRequest] = useState(axiosTokenSetRef.current);

  useEffect(() => {
    let timer = -1;
    const checkHeaders = (): void => {
      // If auth token has not been set _or_ we already know it has been, do nothing
      if (axiosTokenSetRef.current) return;

      if (!axios.defaults.headers.common.Authorization) {
        timer = window.requestAnimationFrame(checkHeaders);

        return;
      }

      axiosTokenSetRef.current = true;
      setCanRequest(true);
    };

    checkHeaders();

    return () => window.cancelAnimationFrame(timer);
  }, []);

  return useSWRImmutable<Response>(
    swrKeyFor(() => (canRequest ? 'teachers.me' : null)),
  );
};
