import { AxiosRequestConfig } from 'axios';

interface CreateConfig extends AxiosRequestConfig {
  method: 'POST';
  url: `/student_in_classes/${string}/game_credits`;
}

/**
 * POST /api/v2/student_in_classes/{id}/game_credits
 */
export const create = (studentInClassId: string): CreateConfig => {
  return {
    method: 'POST',
    url: `/student_in_classes/${studentInClassId}/game_credits`,
  } as const;
};
