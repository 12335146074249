import { updateUserAttributes } from '~/lib/config/intercom';

import { eventFactory } from './utils';

const skillsNavigatorEvent = eventFactory('skills navigator');

export const addAllSkills = (openWorkItemType: 'Assignment' | 'Exam'): void => {
  const lowerWorkItemType = openWorkItemType.toLowerCase();

  skillsNavigatorEvent({ action: `add all skills to ${lowerWorkItemType}` });
};

export const addSkillToAssignment = (): void =>
  skillsNavigatorEvent({ action: 'add skill to assignment' });

export const collapseAll = (): void => {
  skillsNavigatorEvent({ action: 'collapse all skill folders' });
};

export const copyColleagueAssignment = (): void =>
  skillsNavigatorEvent({ action: "copy colleague's assignment" });

export const duplicate = (what: string, whose: 'my' | 'colleagues'): void => {
  skillsNavigatorEvent({ action: `copy ${whose} ${what}` });
};

export const noSearchResult = (label: string): void =>
  skillsNavigatorEvent({ action: 'no search result', label });

export const search = (label: string): void => {
  skillsNavigatorEvent({ action: 'search', label });
};

export const selectCollection = (collection: string): void => {
  skillsNavigatorEvent({ action: 'select collection', label: collection });
};

export const selectGradeLevel = (nameIdentifier: string): void => {
  skillsNavigatorEvent({
    action: 'select grade level',
    label: nameIdentifier,
  });
  updateUserAttributes({ 'current-grade-level': nameIdentifier });
};
