import { AxiosRequestConfig } from 'axios';

import { SkillDiscoveryItem } from '../shared';

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/collections/${string}/new_skills`;
}

// TODO: Use this response
export type NewSkillsIndexResponse = SkillDiscoveryItem[];

/**
 * GET /api/v2/collections/{gradeName}/new_skills
 */
export const list = (selectedGrade: string | null): ListConfig => {
  return {
    method: 'GET',
    url: `/collections/${selectedGrade}/new_skills`,
  } as const;
};
