import { RefObject, useEffect, useRef } from 'react';

import { useLatestRef } from '../use-latest-ref';

type Setter = <T extends HTMLElement>(el: T, height: number) => void;

const defaultSetter: Setter = (el, height) => {
  el.style.height = `${height}px`;
};

const events = ['resize', 'scroll', 'orientationchange'] as const;
const eventOptions = { capture: false, passive: true };

export const use100vh = <T extends HTMLElement>(
  setter: Setter | undefined = defaultSetter,
): RefObject<T> => {
  const ref = useRef<T>(null);
  const setterRef = useLatestRef(setter);

  useEffect(() => {
    const sizeHandler = (): void => {
      if (ref.current && window.innerHeight !== ref.current.clientHeight) {
        setterRef.current(ref.current, window.innerHeight);
      }
    };

    events.forEach(event => {
      window.addEventListener(event, sizeHandler, eventOptions);
    });
    sizeHandler();

    return () => {
      events.forEach(event => window.removeEventListener(event, sizeHandler));
    };
  }, [setterRef]);

  return ref;
};
