import { useFeatureFlag } from '~/lib/launchDarkly';

export const useClassesIndexEndpoint = (): boolean => {
  const voodooEndpoints = useFeatureFlag<Record<string, boolean>>(
    'Feature.Scalability.Endpoints',
    {},
  );

  return voodooEndpoints['classes-index'];
};
