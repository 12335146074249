import { Location } from 'history';
import { camelCase } from 'lodash';
import ReactGA from 'react-ga4';

const regexPathWithId = /(classes|exams|student(s|-data)|assignments)\/\d+/g;

const toSingular = (plural: string): string =>
  camelCase(plural.replace(/e?s$/, ''));

const getPageFromPathname = (pathname: string): string =>
  pathname.replace(
    regexPathWithId,
    (_, path) => `${path}/<${toSingular(path)}ID>`,
  );

export const trackPageView = (location: Location): void => {
  const page = `${getPageFromPathname(location.pathname)}${location.search}`;

  /**
   * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications#tracking_virtual_pageviews
   */
  ReactGA.set({ page });
  ReactGA.ga('send', 'pageview');
};
