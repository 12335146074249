import { Box, BoxProps } from '@mui/material';
import { CSSProperties, forwardRef } from 'react';

export const INTERCOM_LOGO_BUFFER = 95;

interface Props extends BoxProps {
  containerOverflow?: CSSProperties['overflow'];
  removeAllPadding?: boolean;
  removeIntercomPadding?: boolean;
}

export const DataGrid = forwardRef<HTMLDivElement, Props>(function DataGrid(
  {
    containerOverflow = 'auto',
    removeAllPadding,
    removeIntercomPadding,
    ...props
  },
  ref,
) {
  const pb = removeAllPadding
    ? 0
    : removeIntercomPadding
    ? 1.5
    : `${INTERCOM_LOGO_BUFFER}px`;

  return (
    <Box
      ref={ref}
      sx={{
        overflow: containerOverflow,
        position: 'relative',
        '::after': {
          content: '""',
          display: 'block',
          pb,
        },
      }}
    >
      <Box {...props} />
    </Box>
  );
});
