import { AxiosRequestConfig } from 'axios';

interface Create {
  releaseDate: string;
}

interface PreviewConfig extends AxiosRequestConfig {
  body: Create;
  data: Create;
  method: 'POST';
  url: `/exercises/${string}/preview`;
}

export interface ExercisesPreviewResponse {
  url: string;
}

/**
 * POST /api/v2/exercises/${id}/preview
 */
export const preview = (
  assignmentId: string,
  releaseDate: string,
): PreviewConfig => {
  return {
    method: 'POST',
    url: `/exercises/${assignmentId}/preview`,
    body: { releaseDate },
    data: { releaseDate },
  } as const;
};
