import { createSvgIcon, styled } from '@mui/material';

import { ExamHexagon } from './examHexagon';

const Polygon = styled('polygon')(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const IncorrectProblem = createSvgIcon(
  <>
    <ExamHexagon color="error" component="g" fontSize="inherit" />
    <Polygon points="14.46 14.67 13.56 15.57 10 12.01 6.44 15.57 5.54 14.67 9.1 11.11 5.54 7.55 6.44 6.65 10 10.21 13.56 6.65 14.46 7.55 10.9 11.11 14.46 14.67" />
  </>,
  'IncorrectProblem',
);
