// TODO: See if this endpoint is unused
import { AxiosRequestConfig } from 'axios';

interface Update {
  settings: {
    selectedCollection?: {
      id: string;
      type: string;
    };
  };
}

interface UpdateConfig extends AxiosRequestConfig {
  body: Update;
  data: Update;
  method: 'PATCH';
  url: `/ui_settings`;
}

/**
 * PATCH /api/v2/ui_settings
 *
 * { settings: { selectedCollection?: { id: string, type: string, } } }
 */
export const update = (data: Update): UpdateConfig => {
  return {
    method: 'PATCH',
    url: `/ui_settings`,
    body: data,
    data,
  };
};
