import { AxiosRequestConfig } from 'axios';

import { StudentInKlassBase } from '../shared';

interface Create {
  classId: string;
  studentId: string;
}

interface CreateConfig extends AxiosRequestConfig {
  body: { studentId: string };
  data: { studentId: string };
  method: 'POST';
  url: `/classes/${string}/student_in_classes`;
}

export type StudentInKlassesCreateResponse = StudentInKlassBase;

/**
 * POST /api/v2/classes/{id}/student_in_classes
 *
 * { studentId: string }
 */
export const create = ({ classId, studentId }: Create): CreateConfig => {
  const data = { studentId };

  return {
    method: 'POST',
    url: `/classes/${classId}/student_in_classes`,
    body: data,
    data,
  } as const;
};
