export const dispatchChangeEvent = <T>(
  input: HTMLInputElement | HTMLTextAreaElement,
  value: string | string[] | undefined,
  detail?: T,
): void => {
  const prototype = Object.getPrototypeOf(input);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    'value',
  )?.set;

  prototypeValueSetter?.call(input, value);
  input.dispatchEvent(new CustomEvent('input', { bubbles: true, detail }));
};
