import { AxiosRequestConfig } from 'axios';

import { KlassDetail } from '../shared';

interface Create {
  name: string;
  subjects?: string[];
}

interface CreateConfig extends AxiosRequestConfig {
  body: Create;
  data: Create;
  method: 'POST';
  url: '/classes';
}

export type KlassesCreateResponse = KlassDetail;

/**
 * POST /api/v2/classes
 *
 * { name: string, subjects: string[] }
 */
export const create = (data: Create): CreateConfig => {
  return {
    method: 'POST',
    url: '/classes',
    body: data,
    data,
  };
};
