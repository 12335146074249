import { AxiosRequestConfig } from 'axios';

import { ProblemAndAnswer } from '../shared';

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/exam_problems/${string}`;
}

export interface ExamProblemsShowResponse extends ProblemAndAnswer {
  condensed: boolean;
  overrideId?: number;
}

/**
 * GET /api/v2/exam_problems/{id}
 */
export const show = (
  id: string,
  studentInTestId: string | undefined,
): ShowConfig => {
  return {
    method: 'GET',
    params: { studentInTestId },
    url: `/exam_problems/${id}`,
  } as const;
};
