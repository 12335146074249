import { AxiosResponseTransformer } from 'axios';

import { WorkItemType } from '../../../sdk/src/shared';
import { transformResponseToJSON, UnknownError } from '../../../sdk/src/utils';
import { BASE_API } from '../utils';

export type SkillDiscoveryItemType = WorkItemType | 'Tag' | 'Skill';

interface BaseSkillDiscoveryLazyItem {
  id: string;
  condensed?: boolean;
  name: string;
  type: SkillDiscoveryItemType;
}

export interface SkillDiscoveryLazyAssignmentItem
  extends BaseSkillDiscoveryLazyItem {
  releasedOn?: never;
  type: 'Assignment';
}

export interface SkillDiscoveryLazyExamTemplateItem
  extends BaseSkillDiscoveryLazyItem {
  releasedOn?: never;
  type: 'ExamTemplate';
}

export interface SkillDiscoveryLazyFolderItem
  extends BaseSkillDiscoveryLazyItem {
  releasedOn?: never;
  type: 'Folder';
}
export interface SkillDiscoveryLazySkillItem
  extends BaseSkillDiscoveryLazyItem {
  releasedOn: string;
  type: 'Skill';
}

export interface SkillDiscoveryLazyTagItem extends BaseSkillDiscoveryLazyItem {
  releasedOn?: never;
  type: 'Tag';
}

export type SkillDiscoveryLazyItem =
  | SkillDiscoveryLazyAssignmentItem
  | SkillDiscoveryLazyExamTemplateItem
  | SkillDiscoveryLazyFolderItem
  | SkillDiscoveryLazySkillItem
  | SkillDiscoveryLazyTagItem;

type SkillDiscoveryShowResponse = SkillDiscoveryLazyItem[];

interface PostTransformItem {
  depth: number;
  parentId: string | null;
  path: string[];
}

type AddTransformData<T> = T extends unknown ? T & PostTransformItem : never;
type ExtractType<T extends SkillDiscoveryItemType> = Extract<
  SkillDiscoveryItem,
  { type: T }
>;

export type SkillDiscoveryItem = AddTransformData<SkillDiscoveryLazyItem>;
export type SkillDiscoveryAssignmentItem = ExtractType<'Assignment'>;
export type SkillDiscoveryExamTemplateItem = ExtractType<'ExamTemplate'>;
export type SkillDiscoveryFolderItem = ExtractType<'Folder'>;
export type SkillDiscoverySkillItem = ExtractType<'Skill'>;
export type SkillDiscoveryTagItem = ExtractType<'Tag'>;

export type SkillDiscoveryResponse = SkillDiscoveryItem[];

type SkillDiscoveryShowRequestType =
  | 'tag'
  | 'district'
  | 'school'
  | 'folder'
  | 'exercise'
  | 'new';

export interface ShowConfig {
  baseURL: string;
  method: 'GET';
  params: { type: Uppercase<SkillDiscoveryShowRequestType> };
  transformResponse: AxiosResponseTransformer[];
  url: `skill-discovery/${string}`;
}

export const show = (
  id: string,
  type: Uppercase<SkillDiscoveryShowRequestType>,
  parent?: SkillDiscoveryItem,
): ShowConfig => {
  const extra: PostTransformItem = {
    depth: parent ? parent.depth + 1 : 0,
    parentId: parent?.id ?? null,
    path: parent ? [...parent.path, parent.id] : [],
  };

  return {
    baseURL: BASE_API,
    method: 'GET',
    params: {
      type: type.toUpperCase() as Uppercase<SkillDiscoveryShowRequestType>,
    },
    transformResponse: [
      (stringData: string): SkillDiscoveryResponse | UnknownError => {
        const data =
          transformResponseToJSON<SkillDiscoveryShowResponse>(stringData);

        if (!Array.isArray(data)) return data;

        return data.map<SkillDiscoveryItem>(item => ({ ...item, ...extra }));
      },
    ],
    url: `skill-discovery/${id}`,
  };
};
