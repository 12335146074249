import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { FC } from 'react';

import { mobiusTheme } from './theme';

const AUTO_HIDE_AFTER = 4000;

const cache = createCache({
  key: 'css',
  prepend: true,
  ...(process.env.NODE_ENV === 'development' && { stylisPlugins: [] }),
});

/**
 * @see https://github.com/mui-org/material-ui/issues/24894
 *
 * Turns off warnings that are specific to server-side rendering
 */
cache.compat = true;

export const ThemeProvider: FC = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterLuxon}>
    <CacheProvider value={cache}>
      <MuiThemeProvider theme={mobiusTheme}>
        <CssBaseline />
        <SnackbarProvider autoHideDuration={AUTO_HIDE_AFTER} maxSnack={3}>
          {children}
        </SnackbarProvider>
      </MuiThemeProvider>
    </CacheProvider>
  </LocalizationProvider>
);
