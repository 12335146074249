import { AxiosRequestConfig } from 'axios';

interface Update {
  goal: number | null;
}

interface UpdateConfig extends AxiosRequestConfig {
  body: Update;
  data: Update;
  method: 'PUT';
  url: `/classes/${string}/daily_goal`;
}

/**
 * PATCH /api/v2/classes/{id}/daily_goal
 *
 * { goal: number }
 */
export const update = (id: string, data: Update): UpdateConfig => {
  return {
    method: 'PUT',
    url: `/classes/${id}/daily_goal`,
    body: data,
    data,
  } as const;
};
