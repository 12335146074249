import { last } from 'lodash';
import ReactGA from 'react-ga4';

import { isValidKeyOfType, PropertyPath } from '~/lib/paths';

interface EventParams {
  action: string;
  label?: string;
  value?: number;
}

export const eventFactory =
  (category: string): any =>
  (params: EventParams): void =>
    ReactGA.event({
      category,
      ...params,
    });

export const getColumnName = <Item extends Record<string, any>>(
  columnPath: PropertyPath<Item>,
): string | undefined => {
  if (isValidKeyOfType(columnPath)) {
    return columnPath.toString();
  }

  return last(columnPath);
};
