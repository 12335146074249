import { includes } from 'lodash';
import { FC, useEffect } from 'react';

import { useCurrentTeacher } from '../hooks/useCurrentTeacher';

import { useAuth } from './authProvider';

export const scopedKeysToKeep = [
  'hasClosedChecklistDrawer',
  'hasClosedMobileSplashPage',
  'skillDiscovery',
];

// Clear out teacher-specific localStorage values
export const clearTeacherLocalStorage = (teacherId: string): void => {
  let i = window.localStorage.length;

  while (i--) {
    const key = window.localStorage.key(i);

    if (key?.startsWith(teacherId)) {
      // key is formatted like `{teacherId}.{scopedKey}`
      const scopedKey = key?.split('.')[1];

      if (!includes(scopedKeysToKeep, scopedKey)) {
        window.localStorage.removeItem(key);
      }
    }
  }
};

export const Logout: FC = () => {
  const { data: teacher } = useCurrentTeacher();
  const { signOut } = useAuth();

  useEffect(() => {
    const doSignOut = async (): Promise<void> => {
      await signOut();
    };

    if (teacher) clearTeacherLocalStorage(teacher.id);

    doSignOut();
  }, [signOut, teacher]);

  return null;
};
