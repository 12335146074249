export const banner = {
  examRequest: {
    accept: 'accept-banner-exam-request',
    autoAccept: 'auto-accept-banner-exam-request',
    deny: 'deny-banner-exam-request',
  },
  examUnlockRequest: {
    allow: 'allow-banner-exam-unlock-request',
    deny: 'deny-banner-exam-unlock-request',
  },
  root: 'banner-root',
};
