import { Labels } from './common';
import { eventFactory } from './utils';

const print = 'print';

const worksheetEvent = eventFactory('worksheet');

export const cancelPrint = (): void => {
  worksheetEvent({ action: print, label: Labels.Cancel });
};

export const confirmPrint = (): void => {
  worksheetEvent({ action: print, label: print });
};

export const setShuffle = (value: string): void => {
  worksheetEvent({ action: 'set shuffle', label: String(value) });
};

export const setVersions = (value: string): void => {
  worksheetEvent({ action: 'set versions', value: Number(value) });
};
