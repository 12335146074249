import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  MenuProps,
  Tooltip,
} from '@mui/material';
import { memo, ReactNode } from 'react';
import { isElement } from 'react-is';
import { ColumnInstance } from 'react-table';

import { ViewColumn } from '@gmm/icons';

import { CheckboxMenuItem } from '../checkbox-menu-item';
import { useMenu } from '../use-menu';

interface Props extends Omit<MenuProps, 'open' | 'title'> {
  className?: string;
  columns: Array<ColumnInstance<any>>;
  onToggleAll?: () => void;
  size?: IconButtonProps['size'];
  title?: ReactNode;
  tooltipTitle?: string;
}

export const DataGridColumnMenu = memo<Props>(function DataGridColumnMenu({
  className,
  columns,
  onClose,
  size,
  sx,
  title,
  tooltipTitle = '',
  ...menuProps
}) {
  const { handleMenuClick, menuProps: addlMenuProps } = useMenu({
    onClose,
  });

  if (columns.length === 0) return null;

  return (
    <>
      <Tooltip disableFocusListener title={tooltipTitle}>
        <IconButton
          className={className}
          onClick={handleMenuClick}
          size={size}
          sx={sx}
        >
          <ViewColumn />
        </IconButton>
      </Tooltip>
      <Menu {...menuProps} {...addlMenuProps}>
        {title}
        {columns.map(column => {
          const label = column.render('Header');

          if (
            !isElement(label) &&
            typeof label !== 'string' &&
            typeof label !== 'number'
          ) {
            return null;
          }

          return (
            <MenuItem
              checkboxProps={column.getToggleHiddenProps({
                name: 'visible-columns',
              })}
              component={CheckboxMenuItem}
              key={column.id}
              label={label}
            />
          );
        })}
      </Menu>
    </>
  );
});
