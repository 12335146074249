import { AxiosRequestConfig } from 'axios';

interface DestroyConfig extends AxiosRequestConfig {
  method: 'DELETE';
  url: '/presence';
}

/**
 * DELETE /api/v2/classes/{id}
 */
export const destroy = (): DestroyConfig => {
  return {
    method: 'DELETE',
    url: '/presence',
  };
};
