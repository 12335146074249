import { Box } from '@mui/material';
import { FC } from 'react';

interface Props {
  height: number;
}

export const VirtualBuffer: FC<Props> = ({ height }) => (
  <Box component="script" style={{ height }} sx={{ display: 'block' }} />
);
