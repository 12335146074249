/* istanbul ignore file */
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { DateTime } from 'luxon';

import { TeacherMe } from '@gmm/sdk/shared';
import { Idps } from '~/lib/auth';

import { LocalStorageData } from './integrations/localStorage';
import {
  isChunkLoadError,
  isExpectedAxiosError,
  isProblemJSImageLoadErrror,
} from './utils';

Sentry.init({
  attachStacktrace: true,
  beforeBreadcrumb: breadcrumb => {
    const isLaunchDarklyBreadcrumb = breadcrumb.message?.startsWith('LD:');

    if (isLaunchDarklyBreadcrumb) return null;

    return breadcrumb;
  },
  beforeSend: (event, hint) => {
    if (hint) {
      if (
        isExpectedAxiosError(hint) ||
        isProblemJSImageLoadErrror(hint) ||
        isChunkLoadError(hint)
      ) {
        return null;
      }
    }

    return event;
  },
  /**
   * Going to remove the TryCatch integration for now since it's causing
   * more issues than it's catching. I'm restoring it below without
   * requestAnimationFrame support
   *
   * See https://sentry.io/organizations/front-porch/issues/?project=5210198&query=is%3Aunresolved+trycatch&statsPeriod=14d
   * for a list of related issues.
   */
  defaultIntegrations: Sentry.defaultIntegrations.filter(
    ({ name }) => name !== 'TryCatch',
  ),
  denyUrls: [/intercomcdn\.com/i, /extensions\//i, /^chrome:\/\//i],
  dsn: process.env.WEB_SENTRY_DSN,
  enabled: process.env.SENTRY_ENABLED === 'true',
  environment: process.env.ENVIRONMENT,
  ignoreErrors: [
    'ChunkLoadError',
    /Object Not Found Matching Id/,
    'ResizeObserver loop limit exceeded',
  ],
  integrations: [
    new TracingIntegrations.BrowserTracing(),
    new LocalStorageData({
      ignorePatterns: [/^intercom/, /_fs/, /^ld:/, /^oidc/],
    }),
    // Tell Sentry to not instrument rAF for now
    new Sentry.Integrations.TryCatch({ requestAnimationFrame: false }),
  ],
  normalizeDepth: 5,
  release: `mobius@${process.env.version}`,
  tracesSampleRate: 0.2,
});

Sentry.setTag('timezone', DateTime.fromMillis(Date.now()).zoneName);

interface SentryUser {
  displayName: `${TeacherMe['firstName']} ${TeacherMe['lastName']}`;
  email: TeacherMe['email'];
  id: TeacherMe['id'];
  idp?: Idps;
  username: TeacherMe['username'];
}

export function setSentryUser(user?: SentryUser | null): void {
  Sentry.setUser(user ?? null);
}
