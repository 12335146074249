import { captureException } from '@sentry/browser';
import { useEffect } from 'react';
import useSWR from 'swr';

import { useSnackbar } from '~/lib/hooks/useSnackbar';
import { augmentSWR, swrKeyFor } from '~/lib/swr';

import {
  dedupingInterval,
  MobiusProblemAndAnswer,
  PreviewProps,
} from './utils';

interface Config {
  skillType: string;
}

export const useExampleProblem = ({ skillType }: Config): PreviewProps => {
  const { enqueueGenericError } = useSnackbar();

  useEffect(() => {
    if (!skillType) captureException(new Error(`skillType is ${skillType}`));
  }, [skillType]);

  const swrKey = swrKeyFor(() =>
    skillType ? ['skills.show', skillType, undefined] : null,
  );

  // TODO: Create and use SkillsShowResponse
  return useSWR<MobiusProblemAndAnswer>(swrKey, {
    dedupingInterval,
    onError: () => enqueueGenericError(),
    use: [augmentSWR('problemData', ({ data }) => data)],
  }) as PreviewProps;
};
