import { AxiosRequestConfig } from 'axios';

import { StudentInKlassBase } from '../shared';

interface List {
  isFollowUp: boolean;
}

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  params: List;
  url: `/exams/${string}/analysis`;
}
export interface AnalysisIndexItem {
  condensed: boolean;
  description: string;
  missedStudents: StudentInKlassBase[];
  numOccurrences: number;
  numMissedPoints: number;
  numMissedStudents: number;
  problemType: string;
}

export type AnalysisIndexResponse = AnalysisIndexItem[];

/**
 * GET /api/v2/exams/{id}/analysis?isFollowUp={isFollowUp}
 */
export const list = (id: string, params: List): ListConfig => {
  return {
    method: 'GET',
    params,
    url: `/exams/${id}/analysis`,
  } as const;
};
