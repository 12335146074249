import { PaletteOptions } from '@mui/material';
import {
  blue,
  blueGrey,
  common,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors';

export const palette: PaletteOptions = {
  background: {
    tooltip: blueGrey[700],
  },
  error: {
    light: red[100],
    main: red[500],
    dark: red[700],
  },
  presence: {
    add: teal.A700,
    remove: red.A700,
  },
  primary: {
    dark: blue[900],
    light: blue[500],
    main: blue[700],
  },
  secondary: {
    dark: teal[500],
    light: teal[100],
    main: teal[300],
  },
  tertiary: {
    contrastText: common.black,
    dark: purple[700],
    light: purple[100],
    main: purple[300],
  },
  warning: {
    dark: orange[700],
    light: orange[300],
    main: orange[500],
  },
};
