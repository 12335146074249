import { Labels } from './common';
import { eventFactory } from './utils';

const changePassword = 'change password';

const changePasswordEvent = eventFactory('change password');

export const cancelChangePassword = (): void =>
  changePasswordEvent({ action: changePassword, label: Labels.Cancel });

export const confirmChangePassword = (): void => {
  changePasswordEvent({ action: changePassword, label: Labels.Submit });
};
