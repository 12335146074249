import { Box, BoxProps } from '@mui/material';
import { ElementType, ReactElement, ReactNode } from 'react';

import {
  AlphaECircle,
  Folder,
  Hexagon,
  HexagonMultiple,
  HexagonSlice,
} from '@gmm/icons';

import { Truncate } from '../../truncate';
import { sxToArray } from '../../utils';
import { WorkItem } from '../utils';

const folderIcon = <Folder color="action" />;
const assignmentIcon = <HexagonMultiple color="primary" />;
const examIcon = <AlphaECircle color="primary" />;
const condensedSkillIcon = <HexagonSlice color="primary" />;
const skillIcon = <Hexagon color="primary" />;

const getIcon = (item: WorkItem): ReactElement => {
  switch (item.type) {
    case 'Folder':
    // falls through
    case 'Tag':
      return folderIcon;
    case 'Assignment':
      return assignmentIcon;
    case 'ExamTemplate':
      return examIcon;
    default:
      return item.condensed ? condensedSkillIcon : skillIcon;
  }
};

export type WorkItemDescriptionProps<T extends ElementType> = BoxProps<T> & {
  displayName?: ReactNode;
  item: WorkItem;
  truncate?: boolean;
};

export const WorkItemDescription = <T extends ElementType>({
  displayName,
  item,
  sx,
  truncate,
  ...boxProps
}: WorkItemDescriptionProps<T>): JSX.Element => {
  const icon = getIcon(item);

  return (
    <Box
      {...boxProps}
      sx={[
        ...sxToArray(sx),
        {
          alignItems: 'center',
          display: 'flex',
          '> svg': { display: 'block' },
        },
      ]}
    >
      {icon}
      <Box
        component={truncate ? Truncate : 'div'}
        sx={theme => ({
          flex: 1,
          flexBasis: `calc(100% - ${theme.spacing(3)})`,
          flexShrink: 0,
          ml: 1,
          width: `calc(100% - ${theme.spacing(3)})`,
        })}
      >
        {displayName ?? item.name}
      </Box>
    </Box>
  );
};
