import { AxiosRequestConfig } from 'axios';

interface UpdateConfig extends AxiosRequestConfig {
  method: 'PATCH';
  url: `/student_in_class_settings/${string}`;
}

/**
 * PATCH /api/v2/student_in_class_settings/{id}
 */
export const update = (id: string): UpdateConfig => {
  return {
    method: 'PATCH',
    url: `/student_in_class_settings/${id}`,
  } as const;
};
