import { AxiosRequestConfig } from 'axios';

import { BASE_API } from '../../utils';

interface Student {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
}

interface StudentInClass {
  classId: string;
  isInIndividualMixedReview: boolean;
  studentInClassId: string;
}

export interface StudentDataStudentInKlassAssignmentsItem {
  assignedAt: string;
  errorsFixed: number;
  pointsEarnedTotal: number;
  pointsEarnedNewSkills: number;
  pointsEarnedSpiralReview: number;
  pointsRequiredTotal: number;
  pointsRequiredNewSkills: number;
  pointsRequiredSpiralReview: number;
  complete: boolean;
  name: string;
}

export interface StudentDataStudentInKlassAssignmentsResponse {
  classAssignments: StudentDataStudentInKlassAssignmentsItem[];
  student: Student;
  studentInClass: StudentInClass;
}

interface AssignmentsConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/student-in-classes/${string}/assignments`;
}

export const assignments = (studentInClassId: string): AssignmentsConfig => ({
  baseURL: BASE_API,
  method: 'GET',
  url: `/student-in-classes/${studentInClassId}/assignments`,
});
