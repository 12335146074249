import { AxiosRequestConfig } from 'axios';

interface PublishedClassesConfig extends AxiosRequestConfig {
  method: 'GET';
  params: {
    classIds: string[];
  };
  url: `/exercises/${string}/published_classes`;
}

export interface ExercisesPublishedKlassesResponse {
  classIds: string[] | null;
}

/**
 * GET /api/v2/exercises/${id}/published_classes
 */
export const publishedClasses = (
  assignmentId: string,
  classIds: string[],
): PublishedClassesConfig => {
  return {
    method: 'GET',
    params: { classIds },
    url: `/exercises/${assignmentId}/published_classes`,
  } as const;
};
