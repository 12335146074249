import { AxiosRequestConfig } from 'axios';

import { StudentInKlassBase, StudentShared } from '../../shared';
import { StudentExamStatus } from '../../studentExams';

export interface StudentExamItemForStudentDataExams {
  counts: {
    followUpScores: {
      earned: number;
      required: number;
    };
    initialScores: {
      earned: number;
      required: number;
    };
  };
  createdAt: string;
  name: string;
  statuses: {
    initialStatus: StudentExamStatus;
    followUpStatus: StudentExamStatus;
  };
}

export interface StudentDataStudentInKlassExamsResponse {
  classExams: StudentExamItemForStudentDataExams[];
  student: StudentShared;
  studentInClass: StudentInKlassBase;
}

interface ExamsConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/student_data/student_in_classes/${string}/exams`;
}

export const exams = (studentInClassId: string): ExamsConfig => ({
  method: 'GET',
  url: `/student_data/student_in_classes/${studentInClassId}/exams`,
});
