import { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { StudentRemovedModal } from '~/classes/[classId]/components/studentRemovedModal';
import { useRealtimeClass } from '~/lib/realtime/useRealtimeClass';
import { ApiSdkSwrKey } from '~/lib/swr';
import { useAddLayer } from '~/providers/layerProvider';

import { useRealTimeCurrentStatus } from '../[classId]/currentStatus/hooks';

interface StudentInClass {
  studentInClassId: string;
}

interface Config<T extends StudentInClass> {
  backPath?: string;
  studentInClassId?: string;
  students: T[] | undefined;
  swrKey: ApiSdkSwrKey;
}

export const useRosterChangeActions = <T extends StudentInClass>({
  backPath,
  studentInClassId: studentInClassIdOverride,
  students,
  swrKey,
}: Config<T>): void => {
  const { mutate } = useSWRConfig();
  const { pathname } = useLocation();
  const { classId, studentInClassId: studentInClassIdParams } = useParams<{
    classId: string;
    studentInClassId: string;
  }>();
  const studentInClassId = studentInClassIdOverride ?? studentInClassIdParams;
  const { send } = useRealTimeCurrentStatus(classId);
  const sendRef = useRef<(data: { event: string }) => boolean>(send);
  const isRoster = pathname.includes('roster');

  const onConnected = async (): Promise<void> => {
    if (!isRoster) return;

    await mutate(['studentInClasses.list', classId]);
  };

  useEffect(() => {
    if (send) {
      sendRef.current = send;
    }
  }, [send]);

  useRealtimeClass(
    classId ?? '',
    async message => {
      if (
        message.type !== 'removedStudentInClass' &&
        message.type !== 'newStudentInClass'
      ) {
        return;
      }

      await mutate(swrKey);

      // Using setTimeout here because a race condition exists where the new
      // student won't be added into the current status SWR cache without it.
      setTimeout(() => {
        sendRef.current({
          event: 'newStudentInClass',
        });
      }, 3000);
    },
    onConnected,
  );

  const { open } = useAddLayer(props => (
    <StudentRemovedModal {...props} backPath={backPath} />
  ));

  useEffect(() => {
    if (!students || !backPath) return;

    const foundStudent = students.find(
      student => student.studentInClassId === studentInClassId,
    );

    if (!studentInClassId || foundStudent) return;

    open();
  }, [backPath, open, studentInClassId, students]);
};
