import { AxiosRequestConfig } from 'axios';

export interface EmailCampaignSubscribersCreateConfig
  extends AxiosRequestConfig {
  method: 'POST';
  url: '/claim_school/email_campaign_subscribers';
}

/**
 * POST /api/v2/claim_school/email_campaign_subscribers
 */
export const create = (): EmailCampaignSubscribersCreateConfig => {
  return {
    method: 'POST',
    url: '/claim_school/email_campaign_subscribers',
  };
};
