import { AxiosRequestConfig } from 'axios';

import { ModifiedSkillsByClass } from './types';

interface Destroy {
  removeSkills: boolean;
}

interface DestroyConfig extends AxiosRequestConfig {
  body: Destroy;
  data: Destroy;
  method: 'DELETE';
  url: `/class_assignments/${string}`;
}

export interface KlassAssignmentDestroyResponse {
  modifiedSkillsByClass: ModifiedSkillsByClass;
}

/**
 * DELETE /api/v2/exercises/{exerciseId}
 *
 * { removeSkills: boolean }
 */
export const destroy = (id: string, data: Destroy): DestroyConfig => {
  return {
    method: 'DELETE',
    url: `/class_assignments/${id}`,
    body: data,
    data,
  } as const;
};
