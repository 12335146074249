import { AxiosRequestConfig } from 'axios';

interface Update {
  enableStudentMixedReview: boolean;
}

interface UpdateConfig extends AxiosRequestConfig {
  body: Update;
  data: Update;
  method: 'PATCH';
  url: `/student_in_classes/${string}`;
}

/**
 * PATCH /api/v2/classes/{id}/student_in_classes
 *
 * { enableStudentMixedReview: boolean }
 */
export const update = (
  studentInClassId: string,
  data: Update,
): UpdateConfig => {
  return {
    method: 'PATCH',
    url: `/student_in_classes/${studentInClassId}`,
    body: data,
    data,
  } as const;
};
