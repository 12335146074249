import { eventFactory } from './utils';

const studentDeskEvent = eventFactory('student desk');

export const replaceCurrentProblem = (): void =>
  studentDeskEvent({ action: 'replace current problem' });

export const replaceHelpRequestedProblem = (): void =>
  studentDeskEvent({ action: 'replace help requested problem' });

export const sendGameCredit = (): void =>
  studentDeskEvent({ action: 'send game credit' });

export const sendMessage = (): void =>
  studentDeskEvent({ action: 'send message' });
