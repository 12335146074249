import { AxiosRequestConfig } from 'axios';

interface Update {
  enabled: boolean;
  skillTypes: string[];
}

interface UpdateConfig extends AxiosRequestConfig {
  body: Update;
  data: Update;
  method: 'PATCH';
  url: `/classes/${string}/mixed_review_skills`;
}

/**
 * PATCH /api/v2/classes/{id}/mixed_review_skills
 *
 * { enabled: boolean, skillTypes: string[] }
 */
export const update = (id: string, data: Update): UpdateConfig => {
  return {
    method: 'PATCH',
    url: `/classes/${id}/mixed_review_skills`,
    body: data,
    data,
  } as const;
};
