import { AxiosRequestConfig } from 'axios';

interface CalendarConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/student_in_classes/${string}/calendar`;
}

export interface StudentInKlassesCalendarResponse {
  studentCalendarUrl: string;
}

/**
 * GET /api/v2/student_in_classes/{id}/calendar
 */
export const calendar = (studentInClassId: string): CalendarConfig => {
  return {
    method: 'GET',
    url: `/student_in_classes/${studentInClassId}/calendar`,
  } as const;
};
