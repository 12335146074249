import { createTheme } from '@mui/material';

import { makeComponents } from './components';
import { themeOptions } from './theme-options';

const baseTheme = createTheme(themeOptions);

export const mobiusTheme = createTheme(baseTheme, {
  components: makeComponents(baseTheme),
});
export type MobiusTheme = typeof mobiusTheme;
