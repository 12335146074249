import { AxiosRequestConfig } from 'axios';

import { BASE_API } from '../utils';

export type UsageResponse = Organization[];

interface Organization {
  activeStudents: number;
  activeStudentsLast30Days: number;
  activeTeachers: number;
  activeTeachersLast30Days: number;
  id: number;
  name: string;
  performance: {
    totalCompletedProblems: number;
    totalCompletedProblemsLast30Days: number;
    avgCompletedProblems: number;
    totalPointsEarned: number;
  };
  teachers: Teacher[];
}

export type OrgRow = Organization;

export interface Teacher {
  activeStudents: number;
  activeStudentsLast30Days: number;
  classes: Clas[];
  firstName: string;
  id: number;
  lastName: string;
  performance: {
    avgActiveProficientSkills: number;
    avgSkillAccuracy: number;
    avgCompletedProblems: number;
    avgPointsFromSpiralReview: number;
    medianRotationTime: number;
    totalCompletedProblems: number;
    totalPointsEarned: number;
  };
}

export interface TeacherRow extends Teacher {
  orgName: string;
}

export interface Clas {
  activeSkills: number;
  activeStudents: number;
  activeStudentsLast30Days: number;
  id: number;
  name: string;
  performance: {
    avgActiveProficientSkills: number;
    avgSkillAccuracy: number;
    avgCompletedProblems: number;
    avgPointsFromSpiralReview: number;
    medianRotationTime: number;
    totalPointsEarned: number;
  };
  students: Student[];
}

export interface ClassRow extends Clas {
  orgName: string;
  teacherDisplayName: string;
  teacherLast: string;
}

export interface Student {
  firstName: string;
  id: number;
  lastName: string;
  performance: {
    activeProficientSkills: number;
    completedProblems: number;
    completedProblemsLast30Days: number;
    greenOrBetter: number;
    pointsEarned: number;
    pointsFromSpiralReview: number;
    red: number;
    rotationTime: number;
    skillAccuracy: number;
    untried: number;
    yellow: number;
  };
}

export interface StudentRow extends Student {
  classId: number;
  className: string;
  orgName: string;
  teacherDisplayName: string;
  teacherLast: string;
}

interface UsageConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `users/${string}/usage-report`;
}

export const usage = (id: string): UsageConfig => {
  return {
    baseURL: BASE_API,
    method: 'GET',
    url: `users/${id}/usage-report`,
  } as const;
};
