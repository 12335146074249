import { AxiosRequestConfig } from 'axios';

import { ExercisesDestroyResponseItem } from '../shared';

interface skillForUpdate {
  skillId: number;
  points: number;
}

interface Update {
  classAssignments?: ExercisesDestroyResponseItem[]; // for unarchiving
  folderId?: string; // for unarchiving
  isExam?: boolean;
  name?: string;
  pointsPerGameCredit?: number;
  pointsToFinish?: number;
  skills?: skillForUpdate[];
  static?: boolean;
}

interface UpdateConfig extends AxiosRequestConfig {
  body: Update;
  data: Update;
  method: 'PUT';
  url: `/exercises/${string}`;
}

export interface ExercisesUpdateResponse {
  name?: string;
}

/**
 * PUT /api/v2/exercises/{id}
 *
 * { parentFolderId: string }
 */
export const update = (id: string, data: Update): UpdateConfig => {
  return {
    method: 'PUT',
    url: `/exercises/${id}`,
    body: data,
    data,
  } as const;
};
