import { AxiosRequestConfig } from 'axios';

interface Update {
  teacher?: {
    state?: string;
  };
  uiSetting?: {
    settings?: {
      firstTimeUser?: boolean;
      isInternational?: boolean;
    };
  };
}

interface UpdateConfig extends AxiosRequestConfig {
  body: Update;
  data: Update;
  method: 'PATCH';
  url: `/me`;
}

/**
 * PATCH /api/v2/me
 */
export const update = (data: Update): UpdateConfig => {
  return {
    method: 'PATCH',
    url: `/me`,
    body: data,
    data,
  } as const;
};
