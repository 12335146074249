import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FC, lazy, ReactNode, Suspense, useEffect, useState } from 'react';
import { matchPath, Route, Switch, useHistory } from 'react-router-dom';

import { useSnackbar } from '~/lib/hooks/useSnackbar';

import { useCurrentTeacher } from '../hooks/useCurrentTeacher';
import { useFeatureFlag } from '../launchDarkly';

import { useAuth, useIdentity } from './authProvider';
import { Login } from './login';
import { Logout } from './logout';
import { LogoutCallback } from './logoutCallback';
import { Renew } from './renew';

const SignUp = lazy(() => import('./signUp'));
const Welcome = lazy(() => import('../welcome'));

const defaultAuthRoutes: ReactNode = [
  <Route exact key="login" path="/oidc/login">
    <Login />
  </Route>,
  <Route exact key="logooutCallback" path="/oidc/logout">
    <LogoutCallback />
  </Route>,
  <Route exact key="renew" path="/oidc/renew">
    <Renew />
  </Route>,
];

export const Auth: FC = ({ children }) => {
  const history = useHistory();
  const { isSignedIn } = useIdentity();
  const { data: teacher } = useCurrentTeacher();
  const { signIn } = useAuth();
  const { enqueueGenericError } = useSnackbar();
  const showSignUpV2 = useFeatureFlag('Feature.SignUp.v2');
  const ldClient = useLDClient();
  const [isSigningIn, setIsSigningIn] = useState(false);

  const showWelcomeExperience =
    teacher?.firstTimeUser &&
    showSignUpV2 &&
    ldClient?.getContext().anonymous !== true;

  useEffect(() => {
    const doSignIn = async (): Promise<void> => {
      try {
        const isOnSignUp = matchPath(history.location.pathname, '/sign-up');

        // This allows the embedded version to always show the sign up page,
        // even if the user is logged in elsewhere
        if (isOnSignUp && history.location.search.includes('embedded')) return;

        setIsSigningIn(true);
        await signIn({ doNotRedirect: !!isOnSignUp });
      } catch {
        enqueueGenericError();
      } finally {
        setIsSigningIn(false);
      }
    };

    const pathRequiresAuth = !matchPath(history.location.pathname, '/oidc/*');

    if (pathRequiresAuth) {
      doSignIn();
    }
  }, [enqueueGenericError, history, signIn]);

  if (isSignedIn() && teacher) {
    if (showWelcomeExperience) {
      return (
        <>
          <Switch>{defaultAuthRoutes}</Switch>
          <Suspense fallback={<script />}>
            <Switch>
              <Route>
                <Welcome />
              </Route>
            </Switch>
          </Suspense>
        </>
      );
    }

    // Include OIDC & App routes if logged in
    return (
      <>
        <Switch>{defaultAuthRoutes}</Switch>
        <Switch>
          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route>{children}</Route>
        </Switch>
      </>
    );
  }

  // Only include the routes required for OIDC callbacks
  // when not logged in
  return (
    <>
      <Switch>{defaultAuthRoutes}</Switch>
      <Suspense fallback={<script />}>
        <Switch>
          <Route path="/sign-up">{isSigningIn ? null : <SignUp />}</Route>
        </Switch>
      </Suspense>
    </>
  );
};
