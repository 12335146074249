/* istanbul ignore file */
import { KeyboardCode } from '@dnd-kit/core';

const createCodeGuard =
  <T extends KeyboardCode>(validCode: T | T[]) =>
  (code: string): code is T =>
    Array.isArray(validCode)
      ? validCode.some(c => c === code)
      : code === validCode;

export const isDownArrow = createCodeGuard(KeyboardCode.Down);
export const isRightArrow = createCodeGuard(KeyboardCode.Right);
export const isUpArrow = createCodeGuard(KeyboardCode.Up);
export const isLeftArrow = createCodeGuard(KeyboardCode.Left);
export const isDirection = createCodeGuard([
  KeyboardCode.Down,
  KeyboardCode.Right,
  KeyboardCode.Up,
  KeyboardCode.Left,
]);
export const isHorizontal = createCodeGuard([
  KeyboardCode.Left,
  KeyboardCode.Right,
]);
