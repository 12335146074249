import { AxiosRequestConfig } from 'axios';

import { TeacherMe } from '../shared';

interface MeConfig extends AxiosRequestConfig {
  method: 'GET';
  url: '/me';
}

export type TeachersShowResponse = TeacherMe;

/**
 * GET /api/v2/me
 */
export const me = (): MeConfig => {
  return {
    method: 'GET',
    url: '/me',
  };
};
