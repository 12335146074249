import { useMemo } from 'react';
import { actions, PluginHook } from 'react-table';

import { useStorage } from '../../use-storage';
import { DataGridSettings } from '../types';

export const makePersistenceHook = <D extends object>(
  tableId: string,
  initialSettings: DataGridSettings<D>,
): PluginHook<D> => {
  const usePersistence: PluginHook<D> = (hooks): void => {
    const [settings, setSettings] = useStorage<DataGridSettings<D>>(
      `${tableId}:settings`,
      initialSettings,
    );

    hooks.stateReducers.push((state, action) => {
      if (action.type === actions.init) {
        return {
          ...state,
          hiddenColumns: settings.hiddenColumns ?? [],
          sortBy: settings.sortBy ?? [],
        };
      }

      /* istanbul ignore if */
      if (action.type === actions.resetSortBy) {
        return {
          ...state,
          sortBy: settings.sortBy ?? [],
        };
      }

      /* istanbul ignore if */
      if (action.type === actions.resetHiddenColumns) {
        return {
          ...state,
          hiddenColumns: settings.hiddenColumns || [],
        };
      }

      return state;
    });

    hooks.useControlledState.push(state =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMemo(() => {
        setSettings(settings => ({
          ...settings,
          hiddenColumns: state.hiddenColumns,
          sortBy: state.sortBy,
        }));

        return state;
      }, [state]),
    );
  };

  usePersistence.pluginName = 'usePersistence';

  return usePersistence;
};
