export type UnknownError = Record<string, unknown>;

/**
 * This is a pared down version of axios.defaults.transformResponse
 *
 * I'm not using it directly, as 1) it's typed as
 * `AxiosResponseTransformer[] | undefined`, and 2) the defaults can technically
 * be overriden. I am up for discussing if we care about the rest of the
 * logic in the default or not in the future.
 *
 * @see https://github.com/axios/axios/blob/76f09afc03fbcf392d31ce88448246bcd4f91f8c/lib/defaults.js#L81
 */
export function transformResponseToJSON<T>(data: unknown): T | UnknownError {
  if (typeof data === 'string') {
    try {
      return JSON.parse(data);
    } catch {}
  }

  return data as T;
}
