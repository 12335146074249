import { Channel, CreateMixin } from '@rails/actioncable';

import { ClassList } from '@gmm/sdk/classes';
import { useFeatureFlag } from '~/lib/launchDarkly';
import { getEmptyArray } from '~/lib/type-utils';
import { useActionCable } from '~/lib/webSocket';

import { useGetClassList } from './useGetClassList';

export const useTeacherNotifications = <M extends CreateMixin>(
  handlers: M = {} as M,
): Channel<M> => {
  const { activeClasses = getEmptyArray<ClassList>() } = useGetClassList();
  const classIds = activeClasses.map(({ id }) => id);
  const coteachersFlag = useFeatureFlag('Feature.CoTeacher');
  const globalTeacher = { channel: 'TeacherGlobalChannel' };

  const channelConfig = coteachersFlag
    ? { channel: 'TeacherKlassesChannel', classIds }
    : globalTeacher;

  useActionCable(
    {
      channel: 'TeacherChannel',
    },
    handlers,
  );

  return useActionCable(channelConfig, handlers);
};
