import { useGetClassDetail } from '~/classes/hooks';

import { useCurrentTeacher } from '../hooks/useCurrentTeacher';
import { useFeatureFlag } from '../launchDarkly';

import { useProfile } from './authProvider';

interface UseAuthorization {
  classEnrollmentLinks: {
    canUse: boolean;
  };
  classes: {
    canArchive: boolean;
    canCreate: boolean;
    canImport: boolean;
    canRename: boolean;
    canSeeArchive: boolean;
    canSeeRename: boolean;
    canSetSubject: boolean;
  };
  coteachers: {
    canCreate: boolean;
    canDestroy: boolean;
    canLeave: boolean;
    canView: boolean;
  };
  studentInClasses: {
    canAdd: boolean;
    canArchive: boolean;
    canChangePassword: boolean;
    canCreate: boolean;
    canPrint: boolean;
    canSeeUsername: boolean;
  };
  students: {
    canUpdate: boolean;
  };
  teacher: {
    canChangePassword: boolean;
  };
}

const local: Omit<UseAuthorization, 'coteachers'> = {
  classEnrollmentLinks: {
    canUse: true,
  },
  classes: {
    canArchive: true,
    canCreate: true,
    canImport: false,
    canRename: true,
    canSeeArchive: true,
    canSeeRename: true,
    canSetSubject: true,
  },
  studentInClasses: {
    canAdd: true,
    canArchive: true,
    canChangePassword: true,
    canCreate: true,
    canPrint: true,
    canSeeUsername: true,
  },
  students: {
    canUpdate: true,
  },
  teacher: {
    canChangePassword: true,
  },
};

const ssoProvider: Omit<UseAuthorization, 'coteachers'> = {
  classEnrollmentLinks: {
    canUse: false,
  },
  classes: {
    canArchive: false,
    canCreate: false,
    canImport: false,
    canRename: false,
    canSeeArchive: false,
    canSeeRename: false,
    canSetSubject: false,
  },
  studentInClasses: {
    canAdd: false,
    canArchive: false,
    canChangePassword: false,
    canCreate: false,
    canPrint: false,
    canSeeUsername: false,
  },
  students: {
    canUpdate: false,
  },
  teacher: {
    canChangePassword: false,
  },
};

export const useAuthorization = (): UseAuthorization => {
  const { isClever, isClassLink, isLocal, isGoogleClassroom } = useProfile();
  const { data: teacher } = useCurrentTeacher();
  const { data: classDetail } = useGetClassDetail({ readOnly: true });
  const coteachersFlag = useFeatureFlag('Feature.CoTeacher');
  const coteachersFromRosteringFlag = useFeatureFlag(
    'Feature.CoTeacher.FromRostering',
  );
  const coteacherCreateStudentFlag = useFeatureFlag(
    'Feature.CoTeacher.CreateStudents',
  );
  const ssoLazyRosteringFlag = useFeatureFlag('Feature.SSO.LazyRoster', {
    Google: false,
    Clever: false,
    ClassLink: false,
  });

  const isFromGoogleClassroom =
    isGoogleClassroom && ssoLazyRosteringFlag.Google;
  const isFromClever = isClever && ssoLazyRosteringFlag.Clever;
  const isFromClassLink = isClassLink && ssoLazyRosteringFlag.ClassLink;
  const canUseLazyRosterOptions =
    isFromClever || isFromClassLink || isFromGoogleClassroom;

  const teacherId = teacher?.id;
  const classTeacherId = classDetail?.teacherId;

  const isFromRostering = isClever || isClassLink;

  const canSeeCreateCoteachers = coteachersFlag && !isFromRostering;

  const isOwner = (): boolean => {
    if (!teacherId || !classTeacherId) return false;

    return teacherId === classTeacherId;
  };

  // Set up as a function because GMM-4977 Remove self as coteacher will
  // require a coteacherId to be passed in and return true if removing self.
  const canDestroyCoteachers = (): boolean => {
    if (isFromRostering && !canUseLazyRosterOptions) return false;

    return isOwner();
  };

  const canViewCoteachers = (): boolean => {
    if (!coteachersFlag) return false;

    if (isFromRostering) return coteachersFromRosteringFlag;

    return true;
  };

  const isMember = (): boolean => {
    if (!classDetail) return false;
    if (!teacherId) return false;

    const found = classDetail.coteachers.find(
      coteacher => coteacher.id === teacherId,
    );

    return !!found;
  };

  const base = isLocal ? local : ssoProvider;

  const canCreateStudentInClasses = (): boolean => {
    if (!coteacherCreateStudentFlag && !isOwner()) return false;
    if (!isOwner() && !isMember()) return false;

    return base.studentInClasses.canCreate;
  };

  const value = {
    ...base,
    classes: {
      ...base.classes,
      canArchive:
        (base.classes.canArchive || canUseLazyRosterOptions) && isOwner(),
      canImport: canUseLazyRosterOptions,
      canRename: base.classes.canRename && isOwner(),
      canSeeArchive: base.classes.canSeeArchive || canUseLazyRosterOptions,
      canSetSubject: base.classes.canSetSubject || canUseLazyRosterOptions,
    },
    coteachers: {
      canCreate: canSeeCreateCoteachers && isOwner(),
      canDestroy: canDestroyCoteachers(),
      canLeave: isMember() && !isOwner(),
      canView: canViewCoteachers(),
    },
    studentInClasses: {
      ...base.studentInClasses,
      canCreate: canCreateStudentInClasses(),
    },
  };

  return value;
};
