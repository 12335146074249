import { AxiosRequestConfig } from 'axios';

interface Stop {
  examId: string;
  isFollowUp: boolean;
}

interface StopConfig extends AxiosRequestConfig {
  method: 'POST';
  params: Pick<Stop, 'isFollowUp'>;
  url: `/exams/${string}/stop`;
}

/**
 * POST /api/v2/exams/{id}/stop
 */
export const stop = ({ examId, ...params }: Stop): StopConfig => {
  return {
    method: 'POST',
    url: `/exams/${examId}/stop`,
    params,
  } as const;
};
