import { AxiosRequestConfig } from 'axios';

interface DestroyConfig extends AxiosRequestConfig {
  method: 'DELETE';
  url: `/student_in_classes/${string}`;
}

/**
 * DELETE /api/v2/student_in_classes/{id}
 */
export const destroy = (id: string): DestroyConfig => {
  return {
    method: 'DELETE',
    url: `/student_in_classes/${id}`,
  } as const;
};
