import { styled } from '@mui/material';
import { FC } from 'react';

import {
  ModalDialogAction,
  ModalDialogActionProps,
} from '../modal-dialog-action';

const SecondaryActions = styled('div')({
  marginRight: 'auto',
});

interface Props {
  actions?: ModalDialogActionProps[];
}

export const ModalDialogSecondaryActions: FC<Props> = ({ actions }) => {
  if (!actions?.length) return null;

  return (
    <SecondaryActions>
      {actions.map((actionProps, index) => (
        <ModalDialogAction key={index} {...actionProps} />
      ))}
    </SecondaryActions>
  );
};
