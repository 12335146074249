import { Location } from 'history';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { memo, useEffect } from 'react';

import { setGaUserDimensions, trackPageView } from '~/lib/analytics';
import { useIdentity } from '~/lib/auth';
import { useLocationEffect } from '~/lib/hooks/useLocationEffect';

import { useSetIdentity } from '../auth/authProvider';
import { useCurrentTeacher } from '../hooks/useCurrentTeacher';
import { toLDUser } from '../launchDarkly';

import { updateIntercomPageView } from './intercom';
import { setIntercomUser } from './intercom/setIntercomUser';
import { setSentryUser } from './sentry';

const isProduction = process.env.NODE_ENV === 'production';
const isGAToken = !!process.env.GA_MEASUREMENT_ID;
const trackLocation = (location: Location): void => {
  if (isProduction || isGAToken) {
    trackPageView(location);
    updateIntercomPageView();
  }
};

export const Config = memo(function Config(): null {
  const { user } = useIdentity();
  const { data: teacher } = useCurrentTeacher();
  const setIdentity = useSetIdentity();
  const ldClient = useLDClient();

  useEffect(() => {
    const ldUser = toLDUser(teacher);

    if (ldUser) {
      ldClient?.identify(ldUser, undefined, err => {
        if (err) return;

        setIdentity(state => ({ ...state, ldUser }));
      });
    }
  }, [ldClient, setIdentity, teacher]);

  useEffect(() => {
    setGaUserDimensions({
      dimension1: user?.profile.sub ?? null,
      dimension2: '',
      dimension3: user?.profile.idp ?? null,
      userId: user?.profile.sub ?? null,
    });
    setSentryUser(
      teacher
        ? {
            displayName: `${teacher.firstName} ${teacher.lastName}`,
            email: teacher.email,
            id: teacher.id,
            idp: user?.profile.idp,
            username: teacher.username,
          }
        : null,
    );
    setIntercomUser(user);
  }, [teacher, user]);
  useLocationEffect(trackLocation, []);

  return null;
});
