import { AxiosRequestConfig } from 'axios';

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: '/password_suggestion';
}

/**
 * GET /api/v2/password_suggestion
 */
export const list = (): ListConfig => {
  return {
    method: 'GET',
    url: '/password_suggestion',
  };
};
