import { SWRResponse } from 'swr';

import { ExamProblemsShowResponse } from '@gmm/sdk/examProblems/show';

export type MobiusProblemAndAnswer = ExamProblemsShowResponse;

type PreviewResponse = SWRResponse<MobiusProblemAndAnswer, unknown>;

export interface PreviewProps extends PreviewResponse {
  problemData: PreviewResponse['data'];
}

/** Just a really long interval since we only want reload on demand */
export const dedupingInterval = 100000000 as const;
