import { AxiosRequestConfig } from 'axios';

interface Create {
  email: string;
  first: string;
  last: string;
}

interface CreateConfig extends AxiosRequestConfig {
  body: Create;
  data: Create;
  method: 'POST';
  url: '/pilots';
}

/**
 * POST /api/v2/pilots
 *
 * { 'email: string, first: string, last: string' }
 */
export const create = (data: Create): CreateConfig => {
  return {
    method: 'POST',
    url: '/pilots',
    body: data,
    data,
  } as const;
};
