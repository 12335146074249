import { Context, createContext } from 'react';

export const createNamedContext = <T>(
  name: string,
  defaultValue: T,
): Context<T> => {
  const ctx = createContext<T>(defaultValue);

  if (process.env.NODE_ENV !== 'production') {
    ctx.displayName = name;
  }

  return ctx;
};
