import { startCase } from 'lodash';

import { trackIntercomEvent } from '~/lib/config/intercom';

import { Labels } from './common';
import { eventFactory } from './utils';

const create = 'create';
const doNow = 'do now';
const removeSkill = 'remove skill';

const assignmentEvent = eventFactory('assignment');

export const cancelCreate = (): void =>
  assignmentEvent({ action: create, label: Labels.Cancel });

export const cancelDoNow = (): void =>
  assignmentEvent({ action: doNow, label: Labels.Cancel });

export const cancelRemoveSkill = (): void =>
  assignmentEvent({ action: removeSkill, label: Labels.Cancel });

export const confirmCreate = (value: number): void => {
  assignmentEvent({ action: create, label: Labels.Submit, value });
  trackIntercomEvent('created-assignment');
};

export const confirmRemoveSkill = (): void =>
  assignmentEvent({ action: removeSkill, label: Labels.Submit });

export const dismissStepper = (): void =>
  assignmentEvent({ action: 'dismiss stepper' });

export const edit = (label: string, value?: number): void =>
  assignmentEvent({
    action: 'edit',
    label: startCase(label).toLowerCase(),
    value,
  });

export const preview = (): void => {
  assignmentEvent({ action: 'preview assignment as student' });
};

export const removeSkills = (value: number): void => {
  assignmentEvent({ action: removeSkill, value });
};

export const setPointsPerGame = (value: number): void =>
  assignmentEvent({ action: 'set points per game', value });

export const setPointsPerSkill = (value: number): void => {
  assignmentEvent({ action: 'set points per skill', value });
};

export const stepper = (step: number): void =>
  assignmentEvent({ action: 'stepper', label: `step ${step}` });

export const submitAssignment = (): void => {
  assignmentEvent({ action: doNow, label: Labels.Submit });
};

export const submitPractice = (): void => {
  assignmentEvent({ action: doNow, label: Labels.Submit });
};
