import { useRouteMatch } from 'react-router-dom';
import useSWR, { BareFetcher, Middleware, SWRResponse } from 'swr';

import { Exercise } from '@gmm/sdk-voodoo/exercises';
import { useSnackbar } from '~/lib/hooks/useSnackbar';
import { ReadOnlyOptions } from '~/lib/sdk';
import { sdkFetcher, swrKeyFor, voodooSdkFetcher } from '~/lib/swr';

import { useExercisesShowEndpoint } from '../voodoo';

interface Options extends ReadOnlyOptions {
  exerciseId?: string;
  use?: Middleware[];
}

export type UseExercise<T extends Exercise> = SWRResponse<T, unknown>;

export const useExercise = <T extends Exercise = Exercise>({
  exerciseId: exerciseIdProp,
  readOnly,
  use = [],
}: Options = {}): UseExercise<T> => {
  const match = useRouteMatch<{ exerciseId?: string }>(
    '/work/:folderId/:exerciseId(\\d+)',
  );

  const exerciseId = exerciseIdProp ?? match?.params.exerciseId;

  const { enqueueGenericError } = useSnackbar();
  const isVoodooEndpoint = useExercisesShowEndpoint();
  const fetcher = isVoodooEndpoint ? voodooSdkFetcher : sdkFetcher;
  const apiValueKey = swrKeyFor(() =>
    exerciseId ? ['exercises.show', exerciseId] : null,
  );

  return useSWR(apiValueKey, {
    fetcher: fetcher as BareFetcher<T>,
    onError: () => enqueueGenericError(),
    revalidateIfStale: !readOnly,
    revalidateOnReconnect: !readOnly,
    use,
  });
};
