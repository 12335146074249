import useSWR from 'swr';

import { ExamProblemsShowResponse as Response } from '@gmm/sdk/examProblems/show';
import { useSnackbar } from '~/lib/hooks/useSnackbar';
import { augmentSWR, swrKeyFor } from '~/lib/swr';

import { PreviewProps } from './utils';

interface Config {
  problemId?: string;
  studentInTestId?: string;
}

export const useExamProblem = ({
  problemId,
  studentInTestId,
}: Config): PreviewProps => {
  const { enqueueGenericError } = useSnackbar();
  const swrKey = swrKeyFor(() =>
    problemId ? ['examProblems.show', problemId, studentInTestId] : null,
  );

  return useSWR<Response>(swrKey, {
    onError: () => enqueueGenericError(),
    use: [augmentSWR('problemData', ({ data }) => data)],
  }) as PreviewProps;
};
