import { Channel } from '@rails/actioncable';

import { CurrentStatusMessage } from '@gmm/sdk/ws';
import { useActionCable } from '~/lib/webSocket/useActionCable';

import { useCurrentStatus } from './useCurrentStatus';

export const useRealTimeCurrentStatus = (
  classId: string,
): Channel<{ received: (message: CurrentStatusMessage) => void }> => {
  const { updateStudentStatus } = useCurrentStatus({ readOnly: true });

  const onReceived = (message: CurrentStatusMessage): void => {
    updateStudentStatus(message.status);
  };

  return useActionCable(
    classId ? { channel: 'KlassCurrentStatusChannel', classId } : null,
    { received: onReceived },
  );
};
