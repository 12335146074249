import { AxiosRequestConfig } from 'axios';

interface Create {
  message: string;
}

interface ToClassConfig extends AxiosRequestConfig {
  method: 'POST';
  url: `/classes/${string}/messages`;
  body: Create;
  data: Create;
}

/**
 * POST /api/v2/classes/{id}/messages
 *
 * { message: string }
 */
export const toClass = (classId: string, data: Create): ToClassConfig => {
  return {
    method: 'POST',
    url: `/classes/${classId}/messages`,
    body: data,
    data,
  } as const;
};
