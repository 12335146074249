import { forwardRef, FC, isValidElement, ReactNode } from 'react';
import { I18nKey, Namespace, Trans, useTranslation } from 'react-i18next';

import {
  Box,
  Card,
  CardProps,
  CardContent,
  CardMedia,
  Paper,
  Typography,
  sxToArray,
} from '@gmm/ui';
import { ids as allIds } from '~/lib/constants';

const ids = allIds.emptyState;

const betaHeadlineComponents = [
  <Box component="span" key="beta" sx={{ color: 'warning.main' }}>
    Beta
  </Box>,
];

interface EmptyStateImageProps {
  id?: string;
  image: string;
}

export const EmptyStateImage: FC<EmptyStateImageProps> = ({
  id = ids.image,
  image,
}) => (
  <CardMedia
    data-testid={id}
    id={id}
    image={image}
    sx={{
      backgroundSize: 'contain',
      height: '30vh',
      maxHeight: 325,
      mb: 1,
      minHeight: 200,
    }}
  />
);

interface Props extends CardProps {
  betaHeadline?: I18nKey;
  betaSubHeading?: I18nKey;
  className?: string;
  children?: never;
  decoration?: ReactNode;
  headline: I18nKey;
  subHeading?: I18nKey | JSX.Element;
  image: string;
  translationContext?: string;
}

const EmptyState = forwardRef<HTMLDivElement, Props>(function EmptyState(
  {
    betaHeadline,
    betaSubHeading,
    className,
    decoration,
    headline,
    subHeading,
    image,
    sx,
    translationContext,
    ...cardProps
  },
  ref,
) {
  const [t] = useTranslation<Namespace>();

  return (
    <Card
      {...cardProps}
      className={className}
      data-testid={ids.main}
      elevation={0}
      id={ids.main}
      ref={ref}
      sx={[{ width: 1 }, ...sxToArray(sx)]}
    >
      <CardContent sx={{ height: 1, overflow: 'hidden', position: 'relative' }}>
        {decoration}
        <EmptyStateImage image={image} />
        <Typography
          align="center"
          data-testid={ids.headline}
          id={ids.headline}
          variant="h5"
        >
          {t(headline, { context: translationContext })}
        </Typography>

        <Typography
          align="center"
          data-testid={ids.subheading}
          id={ids.subheading}
          variant="subtitle1"
        >
          {typeof subHeading === 'string' &&
            t(subHeading, { context: translationContext })}

          {isValidElement(subHeading) && subHeading}
        </Typography>

        {(betaHeadline || betaSubHeading) && (
          <Paper
            sx={{
              alignSelf: 'center',
              bgcolor: 'common.black',
              color: 'common.white',
              mt: 3,
              mx: 'auto',
              pt: 1.5,
              width: 0.7,
            }}
          >
            {betaHeadline && (
              <Typography
                align="center"
                data-testid={ids.betaHeadline}
                id={ids.betaHeadline}
                variant="h6"
              >
                <Trans
                  components={betaHeadlineComponents}
                  i18nKey={betaHeadline}
                  t={t}
                />
              </Typography>
            )}

            {betaSubHeading &&
              t(betaSubHeading)
                .split('\\n')
                .map(line => (
                  <Typography
                    align="center"
                    data-testid={ids.betaSubheading}
                    id={ids.betaSubheading}
                    key={line}
                    sx={{ my: 2, px: 3 }}
                    variant="subtitle1"
                  >
                    {line}
                  </Typography>
                ))}
          </Paper>
        )}
      </CardContent>
    </Card>
  );
});

export default EmptyState;
