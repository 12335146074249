export const dataTable = {
  add: 'add',
  cell: (rowIndex: number, columnName: string) =>
    `cell-${rowIndex}-${columnName}`,
  cellDate: (rowIndex: number, columnName: string, date: string) =>
    `cell-${rowIndex}-${columnName},${date}`,
  column: (columnName: string) => `sort-cell-${columnName}`,
  columnDate: (columnName: string, date: string) =>
    `sort-cell-${columnName},${date}`,
  expandDetail: 'expandDetail',
  nameFormatMenu: {
    firstLast: 'display-name-first-last',
    lastFirst: 'display-name-last-first',
    menu: 'display-name-menu',
    menuButton: 'display-name-menu-button',
  },
  selectCheckBoxes: 'selectCheckBoxes',
  table: (tableId: string) => `${tableId}-table`,
  tableColumnVisibility: {
    byIndex: (index: number) => `table-column-visibility-${index}`,
    icon: 'table-column-visibility',
    menu: 'table-column-visibility-menu',
  },
  toolbar: (tableId: string) => `${tableId}-toolbar`,
  tooltip: (column: string) => `column-${column}-tooltip`,
  trashIcons: 'trashIcons',
  undo: 'undo',
};
