import { AxiosRequestConfig } from 'axios';

import { MyWorkItem } from '../shared';

interface Duplicate {
  parentFolderId: string;
  workId: string;
  workType: string;
}

interface DuplicateConfig extends AxiosRequestConfig {
  body: Duplicate;
  data: Duplicate;
  method: 'POST';
  url: '/my_work';
}

// TODO: Use this response
export type MyWorkCreateResponse = MyWorkItem;

/**
 * POST /api/v2/my_work
 *
 * { parentFolderId: string, workId: string, workType: string, }
 */
export const duplicate = (data: Duplicate): DuplicateConfig => {
  return {
    method: 'POST',
    url: '/my_work',
    body: data,
    data,
  };
};
