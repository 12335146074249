import { AxiosRequestConfig } from 'axios';

import { idpToProviderMap, LowercaseIdps, Provider } from './list';

interface RosteringUpdateBody {
  provider: Provider;
}

interface ListConfig extends AxiosRequestConfig {
  method: 'PATCH';
  url: `/roster/${string}`;
  body: RosteringUpdateBody;
  data: RosteringUpdateBody;
}

/**
 * PATCH /api/v2/roster/{id}
 */
export const update = (id: string, idp: LowercaseIdps): ListConfig => {
  const data: RosteringUpdateBody = {
    provider: idpToProviderMap(idp),
  };

  return {
    method: 'PATCH',
    url: `/roster/${id}`,
    body: data,
    data,
  };
};
