import { AxiosRequestConfig } from 'axios';

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/classes/${string}/points_history`;
}

export interface PointsHistoryRow {
  firstName: string;
  lastName: string;
  points: { [date: string]: number };
  studentId: string;
  studentInClassId: string;
  studentName: string;
  total: number;
}

export interface V2PointsHistoryRow {
  firstName: string;
  isInIndividualMixedReview: boolean;
  lastName: string;
  points: {
    [date: string]: {
      assignment: number | JSX.Element;
      mixedReview: number | JSX.Element;
      errorsFixed: number | JSX.Element;
    };
  };
  studentId: string;
  studentInClassId: string;
  studentName: string;
}

type Row = PointsHistoryRow | V2PointsHistoryRow;

export interface PointsHistoryListResponse {
  dailyGoals: Row;
  students: Row[];
}

/**
 * GET /api/v2/classes/{id}/points_history
 */
export const list = (
  classId: string,
  start: string,
  end: string,
): ListConfig => {
  return {
    method: 'GET',
    params: { end, start },
    url: `/classes/${classId}/points_history`,
  } as const;
};
