import { AxiosRequestConfig } from 'axios';

import { idpToProviderMap, LowercaseIdps, Provider } from './list';

interface ListConfig extends AxiosRequestConfig {
  method: 'DELETE';
  params: { provider: Provider };
  url: `/roster/${string}`;
}

/**
 * DELETE /api/v2/roster/{id}
 */
export const destroy = (id: string, idp: LowercaseIdps): ListConfig => {
  return {
    method: 'DELETE',
    params: { provider: idpToProviderMap(idp) },
    url: `/roster/${id}`,
  };
};
