import { toFinite } from 'lodash';
import { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';

const reAnyNumber = /^-?\d*(\.\d*)?$/;
const rePositiveNumber = /^\d*(\.\d*)?$/;
const reAnyInteger = /^-?\d*$/;
const rePositiveInteger = /^\d*$/;

export const getRegex = (
  allowDecimals: boolean,
  allowNegative: boolean,
): RegExp => {
  if (allowNegative) {
    return allowDecimals ? reAnyNumber : reAnyInteger;
  }

  return allowDecimals ? rePositiveNumber : rePositiveInteger;
};

const getEffectiveMin = (
  allowNegative: boolean,
  min: number | void,
): number => {
  if (allowNegative) {
    if (!min && min !== 0) return -Infinity;

    return min;
  }

  if (!min) return 0;

  if (min < 0) {
    if (process.env.NODE_ENV !== 'production') {
      throw new RangeError(
        '`min` should not be less than zero if `allowNegative` is `false`',
      );
    }

    return 0;
  }

  return min;
};

const getEffectiveMax = (
  allowNegative: boolean,
  max: number | void,
  min: number,
): number => {
  if (!max && max !== 0) return Infinity;

  if (allowNegative) return max;

  if (max < 0) {
    if (process.env.NODE_ENV !== 'production') {
      throw new RangeError(
        '`max` should not be less than zero if `allowNegative` is `false`',
      );
    }

    return Math.max(0, min);
  }

  return max;
};

export const normalizeMaxMin = (
  allowNegative: boolean,
  max: number | void,
  min: number | void,
): { max: number; min: number } => {
  const effectiveMin = getEffectiveMin(allowNegative, min);
  const effectiveMax = getEffectiveMax(allowNegative, max, effectiveMin);

  if (process.env.NODE_ENV !== 'production' && effectiveMax < effectiveMin) {
    throw new RangeError('`max` should not be less than `min`');
  }

  return { max: effectiveMax, min: effectiveMin };
};

export const getEffectiveStep = (
  step: number,
  allowDecimals = false,
): number => {
  if (step === 0) {
    if (process.env.NODE_ENV !== 'production') {
      throw new RangeError('`step` should not be a zero');
    }

    return 1;
  }

  if (step < 0) {
    if (process.env.NODE_ENV !== 'production') {
      throw new RangeError('`step` should be a positive number');
    }
  }

  const flooredStep = Math.floor(step);

  if (!allowDecimals && flooredStep !== step) {
    if (process.env.NODE_ENV !== 'production') {
      throw new RangeError(
        'cannot `step` by decimals if `allowDecimals` is `false`',
      );
    }

    return Math.max(1, Math.abs(flooredStep));
  }

  return Math.abs(step);
};

/**
 * This is used to limit the number of decimal places to either what had been
 * or what the step has been set to. This is mostly for those situations
 * when javascript has weird rounding issues.
 *
 * eg.
 *
 * ```ts
 * getNumDecimalPlaces('1.567', 2) === 3;
 * getNumDecimalPlaces('1', 1.5) === 1;
 * getNumDecimalPlaces('1.000', 1) === 0;
 * ```
 */
export const getNumDecimalPlaces = (value: string, step: number): number => {
  const [, decimalNumbers] = toFinite(value).toString().split('.');
  const [, stepDecimalPoints] = step.toString().split('.');

  return Math.max(
    decimalNumbers ? decimalNumbers.length : 0,
    stepDecimalPoints ? stepDecimalPoints.length : 0,
  );
};

type PossibleEvent =
  | KeyboardEvent<HTMLInputElement>
  | ChangeEvent<HTMLInputElement>
  | MouseEvent<HTMLLabelElement>;

const formatNumber = (value: number, decimalPlaces: number): string => {
  // To the correct decimal places
  const nextValue = value.toFixed(decimalPlaces);

  // Trims something like 3.0 to 3
  return toFinite(nextValue).toString();
};

interface ContainedCounterConfig {
  max: number;
  min: number;
  step: number;
}

interface ContainedCounterReturnValue {
  decrement: (event: PossibleEvent) => void;
  increment: (event: PossibleEvent) => void;
}

export const getContainedCounter = (
  updateValue: (value: string) => void,
  { min, max, step }: ContainedCounterConfig,
): ContainedCounterReturnValue => {
  const increment = (event: PossibleEvent): void => {
    const target =
      event.currentTarget instanceof HTMLLabelElement
        ? (document.getElementById(
            event.currentTarget.htmlFor,
          ) as HTMLInputElement | null)
        : event.currentTarget;

    console.log(event);
    /* istanbul ignore if */
    if (!target) return;

    const decimalPlaces = getNumDecimalPlaces(target.value, step);
    const inputNumber = toFinite(target.value);
    // subtracting the step from the min makes it so the min is the next number
    // if it started lower than that
    const startValue = Math.max(min - step, inputNumber);
    const nextValue = startValue + step;

    if (nextValue <= max) {
      updateValue(formatNumber(nextValue, decimalPlaces));
    }
  };

  const decrement = (event: PossibleEvent): void => {
    const target =
      event.currentTarget instanceof HTMLLabelElement
        ? (document.getElementById(
            event.currentTarget.htmlFor,
          ) as HTMLInputElement | null)
        : event.currentTarget;

    /* istanbul ignore if */
    if (!target) return;

    const decimalPlaces = getNumDecimalPlaces(target.value, step);
    const inputNumber = toFinite(target.value);
    // adding the step from the max makes it so the max is the next number
    // if it started higher than that
    const startValue = Math.min(max + step, inputNumber);
    const nextValue = startValue - step;

    if (nextValue >= min) {
      updateValue(formatNumber(nextValue, decimalPlaces));
    }
  };

  return { increment, decrement };
};
