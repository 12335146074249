import { FlattenedItem, TreeItems } from '../types';

function flatten<T>(
  items: TreeItems<T>,
  parentId: string | null = null,
  depth = 0,
  path: string[] = [],
): Array<FlattenedItem<T>> {
  return items.reduce<Array<FlattenedItem<T>>>((acc, item, index) => {
    acc.push(
      { ...item, parentId, depth, index, path },
      ...flatten(item.children, item.id, depth + 1, [...path, item.id]),
    );

    return acc;
  }, []);
}

export const flattenTree = <T>(items: TreeItems<T>): Array<FlattenedItem<T>> =>
  flatten<T>(items);
