import { AxiosRequestConfig } from 'axios';

import { ExercisesDestroyResponseItem } from '../shared';

interface Destroy {
  removeSkills: boolean;
}

export interface ExercisesDestroyResponse {
  classAssignments: ExercisesDestroyResponseItem[];
}

interface DestroyConfig extends AxiosRequestConfig {
  body: Destroy;
  data: Destroy;
  method: 'DELETE';
  url: `/exercises/${string}`;
}

/**
 * DELETE /api/v2/exercises/{id}
 *
 * { removeSkills: boolean }
 */
export const destroy = (id: string, data: Destroy): DestroyConfig => {
  return {
    method: 'DELETE',
    url: `/exercises/${id}`,
    body: data,
    data,
  } as const;
};
