import { ChevronRight } from '@mui/icons-material';
import {
  LinearProgress,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { motion } from 'framer-motion';
import { position } from 'polished';
import {
  ComponentPropsWithRef,
  ElementType,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';

import { WorkItem } from '../utils';
import { WorkItemDescription } from '../work-item-description';

export type WorkListItemButtonProps<T extends ElementType> =
  ListItemButtonProps<T> & {
    collapsible?: boolean;
    displayName?: ReactNode;
    expanded?: boolean;
    item: WorkItem;
    showProgress?: boolean;
    startAdornment?: ReactNode;
    truncate?: boolean;
  };

export const WorkListItemButton = forwardRef(function WorkListItemButton<
  T extends ElementType,
>(
  {
    collapsible = false,
    component,
    displayName,
    expanded = false,
    item,
    showProgress = false,
    startAdornment,
    truncate,
    ...listItemButtonProps
  }: WorkListItemButtonProps<T>,
  ref: ForwardedRef<ComponentPropsWithRef<T>['ref']>,
) {
  return (
    <ListItemButton {...listItemButtonProps} component={component} ref={ref}>
      {startAdornment}
      <ListItemIcon sx={{ minWidth: 32 }}>
        {collapsible ? (
          <ChevronRight
            component={motion.svg}
            animate={{ rotate: expanded ? 90 : 0 }}
            initial={{ rotate: expanded ? 90 : 0 }}
            sx={{ display: 'block', transformOrigin: 'center' }}
            transition={{ type: 'linear' }}
          />
        ) : null}
      </ListItemIcon>
      <ListItemText
        primary={
          <WorkItemDescription
            displayName={displayName}
            item={item}
            truncate={truncate}
          />
        }
        primaryTypographyProps={{ variant: 'body1' }}
      />
      {showProgress ? (
        <LinearProgress
          sx={position('absolute', 'auto', 0, 0, 0)}
          variant="indeterminate"
        />
      ) : null}
    </ListItemButton>
  );
});
