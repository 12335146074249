import axios, { AxiosError, AxiosResponseTransformer } from 'axios';
import { Settings } from 'luxon';

import { RAILS_API } from '~/lib/constants';

export const isAxiosError = <T = unknown, D = any>(
  error: unknown,
): error is AxiosError<T, D> =>
  error instanceof Error && !!(error as AxiosError).isAxiosError;

export const is404 = (error: unknown): boolean =>
  isAxiosError(error) && error.response?.status === 404;

function configureClockSkew(serverTime: number): void {
  const now = Number(new Date());

  // Positive clockSkew --> client time is ahead of server time
  // clockSkew is measured in milliseconds
  const clockSkew = now - serverTime;

  Settings.now = () => Date.now() - clockSkew;

  window.clockSkew = clockSkew;
}

interface ConfigureAxios {
  authToken?: string | null;
  idpTest?: string | null;
}

export const configureAxios = ({
  authToken,
  idpTest,
}: ConfigureAxios): void => {
  axios.defaults.baseURL = process.env.BASE_URL || RAILS_API;
  axios.defaults.headers.common.Authorization = `Bearer ${
    authToken || process.env.JWT
  }`;

  if (idpTest) {
    axios.defaults.headers.common['x-idp'] = idpTest;
  }

  axios.interceptors.response.use(function axiosInterceptorResponseSuccess(
    response,
  ) {
    const serverTimeHeader = response.headers?.['x-server-time'];

    if (!serverTimeHeader) return response;

    const serverTime = Number(serverTimeHeader);

    configureClockSkew(serverTime);

    return response;
  });
};

export const addTransformResponse = (
  reponseTransformer: AxiosResponseTransformer,
): AxiosResponseTransformer[] => {
  if (axios.defaults.transformResponse) {
    return Array.isArray(axios.defaults.transformResponse)
      ? [...axios.defaults.transformResponse, reponseTransformer]
      : [axios.defaults.transformResponse, reponseTransformer];
  }

  return [reponseTransformer];
};
