import { AxiosRequestConfig } from 'axios';

import { KlassItem } from '../../../sdk/src/shared';
import { BASE_API } from '../utils';

export type ClassList = KlassItem[];

export type KlassesIndexResponse = KlassItem[];

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/teachers/${string}/classes`;
}

/**
 * GET /v1/teachers/{id}/classes
 */
export const list = (id: string): ListConfig => {
  return {
    baseURL: BASE_API,
    method: 'GET',
    url: `/teachers/${id}/classes`,
  };
};
