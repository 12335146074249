import { get } from 'lodash';
import * as yup from 'yup';
import { Message, MessageParams } from 'yup/lib/types';

const reRefsG = /Ref\(.*?\)/g;
const reRef = /Ref\((.*?)\)/;
const extractOneOfRef = (values: string): string | undefined => {
  const refs = values.match(reRefsG);

  if (!refs) return undefined;

  // we know it matches because it's the same regex from above
  return refs.map(ref => ref.match(reRef)![1])[0];
};

const makeOneOfMessage =
  (key: string): Message<{ values: string }> =>
  ({ values, ...opts }) => {
    const ref = extractOneOfRef(values);
    const extraOptions = ref
      ? { context: 'match', matchPath: ref }
      : { values };
    const options = { ...opts, ...extraOptions };

    return { key, options };
  };

yup.setLocale({
  mixed: {
    defined: (options: MessageParams) => ({ key: 'mixed.required', options }),
    notOneOf: makeOneOfMessage('mixed.notOneOf'),
    oneOf: makeOneOfMessage('mixed.oneOf'),
    required: (options: MessageParams) => ({ key: 'mixed.required', options }),
  },
  string: {
    email: (options: MessageParams) => ({ key: 'string.email', options }),
    matches: (options: MessageParams) => ({ key: 'string.matches', options }),
    max: (options: MessageParams) => ({ key: 'string.max', options }),
    min: (options: MessageParams) => ({ key: 'string.min', options }),
  },
  number: {
    max: (options: MessageParams) => ({ key: 'number.max', options }),
    min: (options: MessageParams) => ({ key: 'number.min', options }),
  },
});

const uniqueMessage: yup.UniqueValidatorOptions['message'] = options => ({
  key: 'array.unique',
  options,
});

yup.addMethod<yup.ArraySchema<any>>(
  yup.array,
  'unique',
  function (
    path?: string | string[],
    {
      excludeEmptyValues = false,
      message = uniqueMessage,
    }: yup.UniqueValidatorOptions = {},
  ) {
    return this.test(
      'unique',
      message,
      function unique(originalList: any[] | null | undefined) {
        const list = excludeEmptyValues
          ? originalList?.filter(item => path && get(item, path) && item) ?? []
          : originalList ?? [];
        const mapper = (item: any): any => (path ? get(item, path) : item);

        const set = [...new Set(list.map(mapper))];
        const isUnique = list.length === set.length;

        if (isUnique) return true;

        const index = list.findIndex(
          (item, index) => mapper(item) !== set[index],
        );

        return this.createError({
          path: `${this.path}[${index}].${path}`,
          message,
        });
      },
    );
  },
);
