import * as Sentry from '@sentry/browser';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Card, CardContent, Link, Typography } from '@gmm/ui';
import LoginErrorImage from '~/images/emptyStates/login/loginError.png';
import { ids as allIds } from '~/lib/constants';
import { EmptyStateImage } from '~/lib/emptyState';
import { useSnackbar } from '~/lib/hooks/useSnackbar';

import { useFeatureFlag } from '../launchDarkly';

import { useAuth, useProfile } from './authProvider';

const ids = allIds.auth.loginFail;

export const Login: FC = () => {
  const [t] = useTranslation(['account', 'auth']);
  const { signIn, signInCallback } = useAuth();
  const { isGoogleClassroom } = useProfile();
  const [error, setError] = useState<Error | null>(null);
  const { enqueueGenericError, enqueueSnackbar } = useSnackbar();
  const hideGoogleSnackbar = useFeatureFlag('Feature.HighlightClassChanges');

  useEffect(() => {
    const tryLogin = async (): Promise<void> => {
      try {
        await signInCallback();
      } catch (error) {
        if (!(error instanceof Error)) return;

        setError(error);
        Sentry.captureEvent(error);
      }
    };

    tryLogin();
  }, [signInCallback]);

  const showGoogleSnackbar = !hideGoogleSnackbar && isGoogleClassroom;
  const googleSnackbarText = t('account:sso.google.login');

  useEffect(() => {
    if (showGoogleSnackbar)
      enqueueSnackbar(googleSnackbarText, {
        persist: true,
      });
  }, [enqueueSnackbar, showGoogleSnackbar, googleSnackbarText]);

  if (!error) return null;

  const handleSignIn = async (): Promise<void> => {
    try {
      await signIn();
    } catch {
      enqueueGenericError();
    }
  };

  return (
    <Card data-testid={ids.root} id={ids.root}>
      <CardContent>
        <EmptyStateImage id={ids.image} image={LoginErrorImage} />
        <Typography
          align="center"
          data-testid={ids.headline}
          id={ids.headline}
          variant="h5"
        >
          {t('auth:emptyState.headline')}
        </Typography>
        <Typography
          align="center"
          data-testid={ids.subheading}
          id={ids.subheading}
          variant="subtitle1"
        >
          <Trans
            components={[
              <Link
                data-testid={ids.link}
                id={ids.link}
                key="link"
                onClick={handleSignIn}
                style={{ cursor: 'pointer' }}
              >
                Login
              </Link>,
            ]}
            i18nKey="auth:emptyState.subheading"
            t={t}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};
