import { trackIntercomEvent } from '~/lib/config/intercom';

import { Labels } from './common';
import { eventFactory } from './utils';

const createStudentAccountEvent = eventFactory('create student account');

export const addStudentRow = (): void =>
  createStudentAccountEvent({ action: 'add student row' });

export const back = (): void => createStudentAccountEvent({ action: 'back' });

export const cancel = (): void =>
  createStudentAccountEvent({ action: Labels.Cancel });

export const done = (): void => createStudentAccountEvent({ action: 'done' });

export const cont = (): void =>
  createStudentAccountEvent({ action: 'continue' });

export const print = (): void => createStudentAccountEvent({ action: 'print' });

export const removeStudentRow = (): void =>
  createStudentAccountEvent({ action: 'remove student row' });

export const createStudentAccount = (): void =>
  trackIntercomEvent('created-student-account');

export const submit = (): void =>
  createStudentAccountEvent({ action: 'onSubmit' });
