import {
  DateRange,
  DesktopDateRangePicker as MuiDateRangePicker,
  DateRangePickerProps as MuiDateRangePickerProps,
} from '@mui/lab';
import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';
// Probably dangerous, but better than reimplementing
import { useUtils } from '@mui/lab/internal/pickers/hooks/useUtils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useMountedRef } from '../use-mounted-ref';
import { WithOptional } from '../utils';

import { DateRangeInput } from './date-range-input';
interface BaseDateRangePickerProps<TDate>
  extends WithOptional<MuiDateRangePickerProps<TDate>, 'renderInput'> {
  maxDays?: number;
  toText?: string;
}

interface NoMaxDateRangePickerProps<TDate>
  extends BaseDateRangePickerProps<TDate> {
  maxDate?: never;
  maxDays?: never;
}

interface MaxDateDateRangePickerProps<TDate>
  extends BaseDateRangePickerProps<TDate> {
  maxDate: TDate;
  maxDays?: never;
}

interface MaxDaysDateRangePickerProps<TDate>
  extends BaseDateRangePickerProps<TDate> {
  maxDate?: never;
  maxDays: number;
}

export type DateRangePickerProps<TDate> =
  | NoMaxDateRangePickerProps<TDate>
  | MaxDaysDateRangePickerProps<TDate>
  | MaxDateDateRangePickerProps<TDate>;

export const DateRangePicker = <TDate,>({
  onAccept,
  onChange,
  onClose,
  maxDate: maxDateProp,
  maxDays,
  renderInput,
  toText = 'to',
  value,
  ...props
}: DateRangePickerProps<TDate>): JSX.Element => {
  const mountedRef = useMountedRef();
  const [isEndFocused, setIsEndFocused] = useState(false);
  const acceptedRef = useRef(false);
  const utils = useUtils<TDate>();
  const parseValue = useCallback(
    (
      value: unknown,
      method: `${'start' | 'end'}OfDay` = 'startOfDay',
    ): TDate | null =>
      !utils.isValid(value) || value === null
        ? // istanbul ignore next
          null
        : utils[method](utils.date(value) as TDate),
    [utils],
  );
  const parseRange = useCallback(
    ([start, end]: [unknown, unknown]): DateRange<TDate> => [
      parseValue(start),
      parseValue(end, 'endOfDay'),
    ],
    [parseValue],
  );
  const isRangeEqual = (
    range: DateRange<TDate>,
    comparing: RangeInput<TDate>,
  ): boolean => {
    const [start, end] = range;
    const [compareStart, compareEnd] = parseRange(comparing);

    return (
      !!start &&
      !!end &&
      !!compareStart &&
      !!compareEnd &&
      utils.isSameDay(start, compareStart) &&
      utils.isSameDay(end, compareEnd)
    );
  };
  const restrictRange = (value: DateRange<TDate>): DateRange<TDate> => {
    const [start, end] = value;

    if (
      maxDays &&
      !maxDateProp &&
      start &&
      end &&
      utils.getDiff(end, start, 'days') > maxDays
    ) {
      const dateValue = utils.addDays(start, maxDays);
      const endOfDayValue = utils.endOfDay(dateValue);

      return [start, endOfDayValue];
    }

    return value;
  };
  const [displayValue, setDisplayValue] = useState(() =>
    restrictRange(parseRange(value)),
  );
  const maxDate = useMemo(() => {
    if (maxDays && !maxDateProp) {
      if (isEndFocused) {
        const startDate = parseValue(displayValue[0]);

        // istanbul ignore else
        if (startDate) {
          return utils.addDays(startDate, maxDays);
        }
      }
    }

    return maxDateProp;
  }, [displayValue, isEndFocused, maxDateProp, maxDays, parseValue, utils]);

  useEffect(() => {
    const originalEndDate = parseValue(value[1], 'endOfDay');

    if (
      displayValue[1] &&
      originalEndDate &&
      !utils.isSameDay(displayValue[1], originalEndDate)
    ) {
      onChange?.(displayValue);
      onAccept?.(displayValue);
    }
    // We only want this to run initially
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // @ts-expect-error I have no idea what is happening here
    <MuiDateRangePicker
      {...props}
      maxDate={maxDate}
      onAccept={range => {
        acceptedRef.current = true;
        onAccept?.(range);
      }}
      onChange={(range, keyboardInputValue) => {
        const value = restrictRange(range);

        onChange?.(value, keyboardInputValue);
        setDisplayValue(value);
      }}
      onClose={() => {
        if (!mountedRef.current) return;

        setDisplayValue(prevValue => {
          const nextValue = parseRange([
            prevValue[0] || /* istanbul ignore next: hard to test */ value[0],
            prevValue[1] || /* istanbul ignore next: hard to test */ value[1],
          ]);

          window.requestAnimationFrame(() => {
            // istanbul ignore else
            if (
              acceptedRef.current ||
              /* istanbul ignore next: hard to test */ !mountedRef.current
            ) {
              acceptedRef.current = false;

              return;
            }

            onAccept?.(nextValue);
          });

          return isRangeEqual(prevValue, nextValue)
            ? prevValue
            : // istanbul ignore next
              nextValue;
        });

        setIsEndFocused(false);
        onClose?.();
      }}
      renderInput={(start, end) => (
        <DateRangeInput
          end={end}
          setIsEndFocused={setIsEndFocused}
          start={start}
          toText={toText}
        />
      )}
      value={displayValue}
    />
  );
};
