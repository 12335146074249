import { useLayoutEffect } from 'react';
import { SortingRule, TableState } from 'react-table';

import { useDisplayNameState } from '../use-display-name-state';
import { usePrevious } from '../use-previous';

interface Options<D extends object> {
  columnId: string;
  setSortBy: (sortBy: Array<SortingRule<D>>) => void;
  state: Pick<TableState<D>, 'sortBy'>;
}

export const useDisplayNameSort = <D extends object>({
  columnId,
  setSortBy,
  state,
}: Options<D>): void => {
  const [displayNameFormat] = useDisplayNameState();
  const previousDisplayNameFormat = usePrevious(displayNameFormat);

  useLayoutEffect(() => {
    if (displayNameFormat === previousDisplayNameFormat) return;
    if (!state.sortBy.some(({ id }) => id === columnId)) return;

    setSortBy([...state.sortBy]);
  }, [
    columnId,
    displayNameFormat,
    previousDisplayNameFormat,
    setSortBy,
    state.sortBy,
  ]);
};
