import { styled, Tooltip, TooltipProps } from '@mui/material';
import { ellipsis } from 'polished';
import { FC, HTMLAttributes, useEffect, useRef, useState } from 'react';

import { useMountedRef } from '../use-mounted-ref';
import { useResizeObserver } from '../use-resize-observer';

interface RootProps {
  inline?: boolean;
  lines?: number;
}

const Root = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'inline' && prop !== 'lines' && prop !== 'sx',
})<RootProps>(({ inline, lines = 1 }) =>
  lines === 1
    ? { ...ellipsis('100%'), ...(inline ? {} : { display: 'block' }) }
    : {
        display: '-webkit-box',
        WebkitLineClamp: lines,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
);

export interface TruncateProps
  extends RootProps,
    Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
}

export const Truncate: FC<TruncateProps> = ({
  children,
  tooltipProps,
  ...divProps
}) => {
  const mountedRef = useMountedRef();
  const ref = useRef<HTMLDivElement | null>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const { ref: setRef, width = 0 } = useResizeObserver(ref);

  useEffect(() => {
    /* istanbul ignore else */
    if (ref.current && mountedRef.current) {
      setIsTruncated(
        ref.current.clientWidth !== ref.current.scrollWidth ||
          ref.current.clientHeight !== ref.current.scrollHeight,
      );
    }
  }, [children, mountedRef, width]);

  return (
    <Tooltip {...tooltipProps} title={isTruncated ? children! : ''}>
      <Root
        {...divProps}
        data-truncated={isTruncated || undefined}
        ref={setRef}
      >
        {children}
      </Root>
    </Tooltip>
  );
};
