import { eventFactory } from './utils';

const event = eventFactory('todo list');

export const close = (): void => event({ action: 'close panel' });
export const open = (): void => event({ action: 'open panel' });

export const openList = (): void => event({ action: 'open list' });
export const closeList = (): void => event({ action: 'close list' });

export const clickedResource = (): void =>
  event({ action: 'clicked todo resource' });
