import { createSvgIcon, styled } from '@mui/material';

import { ExamHexagon } from './examHexagon';

const Path = styled('path')(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const FullCredit = createSvgIcon(
  <>
    <ExamHexagon color="secondary" component="g" fontSize="inherit" />
    <Path d="M7.58,6.53v9h1.8v-3.6h2.7v-1.8H9.38V8.33H13V6.53Z" />
  </>,
  'FullCredit',
);
