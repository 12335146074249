import { styled } from '@mui/material';
import clsx from 'clsx';
import { ComponentProps, memo } from 'react';

import { DataGridLevel2Context } from '../data-grid';

const Root = styled('header')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'sticky',
  fontWeight: 500,
  top: 0,
  willChange: 'transform',
  zIndex: 20,
  '& > *': { backgroundColor: 'inherit' },
}));

const contextValue = { variant: 'head' } as const;

export const DataGridHead = memo<ComponentProps<typeof Root>>(
  function DataGridHead({ role, ...props }) {
    return (
      <DataGridLevel2Context.Provider value={contextValue}>
        <Root {...props} role={clsx('rowgroup', role)} />
      </DataGridLevel2Context.Provider>
    );
  },
);
