import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DialogContentText, ModalDialog } from '@gmm/ui';
import { LayerProps } from '~/providers/layerProvider';

interface Props extends LayerProps {
  backPath?: string;
}

export const StudentRemovedModal: FC<Props> = ({ backPath, onClose, open }) => {
  const [t] = useTranslation(['classItem', 'app']);
  const history = useHistory();
  const handleClose = (): void => {
    onClose();
    if (backPath) history.replace(backPath);
  };

  return (
    <ModalDialog
      acknowledgementAction={{
        onClick: handleClose,
        text: t('app:buttons.goBack'),
      }}
      content={
        <>
          <DialogContentText>
            {t('classItem:studentRemovedModal.body1')}
          </DialogContentText>
          <DialogContentText>
            {t('classItem:studentRemovedModal.body2')}
          </DialogContentText>
        </>
      }
      maxWidth="xs"
      onClose={onClose}
      open={open}
      title={t('classItem:studentRemovedModal.title')}
    />
  );
};
