import { Labels } from './common';
import { eventFactory } from './utils';

const notification = 'notification';
const removeExamFromClass = 'remove exam from class';

const classExamEvent = eventFactory('class exam');

const gradingActions = [
  'give student problem no credit',
  'give student problem partial credit',
  'give student problem full credit',
];

export const allowBegin = (): void => {
  classExamEvent({
    action: 'allow to student to begin',
    label: notification,
  });
};

export const allowUnlock = (): void => {
  classExamEvent({
    action: 'allow student to unlock exam',
    label: notification,
  });
};

export const byGrade = (num: 0 | 1 | 2): void => {
  classExamEvent({ action: gradingActions[num] });
};

export const cancelPrint = (): void =>
  classExamEvent({ action: 'cancel print' });

export const cancelRemoveExam = (): void =>
  classExamEvent({
    action: removeExamFromClass,
    label: Labels.Cancel,
  });

export const clickPrint = (): void => classExamEvent({ action: 'print' });

export const confirmGrades = (numExams = 1): void => {
  classExamEvent({ action: 'confirm student grade changes', value: numExams });
};

export const confirmPrint = (
  includeAnswerKey: boolean,
  isPractice: boolean,
): void => {
  const action = isPractice ? 'print practice exam' : 'print exact exam';
  const label = includeAnswerKey ? 'include answer key' : 'no answer key';

  classExamEvent({ action, label });
};

export const confirmRemoveExam = (): void => {
  classExamEvent({
    action: 'remove exam from class',
    label: 'submit',
  });
};

export const denyBegin = (): void => {
  classExamEvent({
    action: 'decline student to begin',
    label: notification,
  });
};

export const denyUnlock = (): void => {
  classExamEvent({
    action: 'decline student to unlock exam',
    label: notification,
  });
};

export const disableAutoAccept = (): void => {
  classExamEvent({
    action: `disable auto-accept`,
  });
};

export const disableResumeProblems = (): void => {
  classExamEvent({
    action: 'disable resume problems',
  });
};

export const discardGrades = (): void => {
  classExamEvent({ action: 'discard student grade changes' });
};

export const enableAutoAccept = (value: number): void => {
  classExamEvent({
    action: `enable auto-accept`,
    label: notification,
    value,
  });
};

export const enableCorrections = (): void => {
  classExamEvent({
    action: 'enable corrections',
  });
};

export const enableResumeProblems = (): void => {
  classExamEvent({
    action: 'enable resume problems',
  });
};

export const handInStudentExam = (): void => {
  classExamEvent({ action: 'hand in student exam' });
};

export const hideExam = (): void => {
  classExamEvent({
    action: 'hide exam',
  });
};

export const showExam = (): void => {
  classExamEvent({
    action: 'show exam',
  });
};

export const stopExam = (): void => {
  classExamEvent({ action: 'stop exam' });
};

export const viewAnalysis = (): void => {
  classExamEvent({ action: 'view analysis' });
};

export const viewNextStudent = (): void =>
  classExamEvent({
    action: 'view next student',
  });

export const viewPreviousStudent = (): void =>
  classExamEvent({
    action: 'view previous student',
  });
