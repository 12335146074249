import { styled } from '@mui/material';

interface RootProps {
  striped?: boolean;
}

export const DataGridRow = styled('div', {
  shouldForwardProp: prop => prop !== 'striped',
})<RootProps>(({ striped, theme }) => ({
  background: striped ? theme.palette.grey[50] : theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));
