import { AxiosRequestConfig } from 'axios';

import { TagItem } from '../shared';

interface Show {
  state: string | null;
}

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  params?: Show;
  url: `/collections/${string}`;
}

// TODO: Use this response
export type CollectionsShowResponse = TagItem[];

/**
 * GET /api/v2/collections/{gradeName}
 */
export const show = (
  selectedGrade: string | null,
  state: string | null,
): ShowConfig => {
  return {
    method: 'GET',
    params: { state },
    url: `/collections/${selectedGrade}`,
  } as const;
};
