import { useCallback } from 'react';
import { Hooks } from 'react-table';

const reQuote = /"/g;

export const safeString = (
  value: string | number | null | undefined,
): string => {
  if (typeof value === 'undefined' || value === null) return '';

  const escaped = `${value}`.replace(reQuote, '""');
  const needsQuotes =
    escaped.indexOf('"') > -1 ||
    escaped.indexOf(',') > -1 ||
    escaped.charAt(0) === ' ' ||
    escaped.charAt(escaped.length - 1) === ' ';

  return needsQuotes ? `"${escaped}"` : escaped;
};

export const useToCSV = <D extends object>(hooks: Hooks<D>): void => {
  hooks.useInstance.push(instance => {
    const { allColumns, disableExport, rows } = instance;

    // TODO: Talk to Kalley about why this filters on if it has `accessor`.
    // It works even if you provide it and do a `accessor: 'whatever' as any`
    allColumns.forEach(column => {
      const disableExportWhenHidden =
        !column.isVisible && column.disableExportWhenHidden;

      column.canExport =
        !!column.accessor &&
        !column.disableExport &&
        !disableExport &&
        !disableExportWhenHidden;
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const exportData = useCallback(
      (filename: string) => {
        const exportableColumns = allColumns.filter(column => column.canExport);

        if (exportableColumns.length === 0) {
          console.warn('No exportable columns are available');
        }

        const data = rows.map(row =>
          exportableColumns.map(column => {
            const value = row.values[column.id];

            const unsafe = column.getCellExportValue?.({ row, value }) ?? value;

            return column.doNotEscapeCell ? unsafe : safeString(unsafe);
          }),
        );

        const headers = exportableColumns.map(column => {
          const unsafe = column.getColumnExportValue?.() ?? column.id;

          return column.doNotEscapeHeader ? unsafe : safeString(unsafe);
        });

        const csvString = [headers, ...data].join('\r\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const dataUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.download = filename.match(/\.csv$/) ? filename : `${filename}.csv`;
        link.href = dataUrl;
        link.click();

        window.requestAnimationFrame(() => {
          URL.revokeObjectURL(dataUrl);
        });
      },
      [allColumns, rows],
    );

    Object.assign(instance, { exportData });
  });
};

useToCSV.pluginName = 'useToCSV';
