import { AxiosRequestConfig } from 'axios';

interface ConfirmToken {
  email: string;
  token: string;
}

interface ConfirmTokenConfig extends AxiosRequestConfig {
  method: 'POST';
  params: ConfirmToken;
  url: '/verify_email/confirm_token';
}

export const confirmToken = (params: ConfirmToken): ConfirmTokenConfig => ({
  method: 'POST',
  params,
  url: '/verify_email/confirm_token',
});
