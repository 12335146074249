import { AxiosRequestConfig } from 'axios';

interface DestroyConfig extends AxiosRequestConfig {
  method: 'DELETE';
  url: `/student_in_classes/${string}/help_request`;
}

/**
 * DELETE /api/v2/student_in_classes/{id}/help_request
 */
export const destroy = (studentInClassId: string): DestroyConfig => {
  return {
    method: 'DELETE',
    url: `/student_in_classes/${studentInClassId}/help_request`,
  } as const;
};
