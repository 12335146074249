import { SxProps } from '@mui/system';

import { MobiusTheme } from '../theme';

/* istanbul ignore next */
export const sxToArray = (
  sx?: SxProps<MobiusTheme>,
): Extract<SxProps<MobiusTheme>, any[]> => {
  if (!sx) return [];

  return Array.isArray(sx) ? sx : [sx];
};
