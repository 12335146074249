import { styled, Toolbar } from '@mui/material';

export const DataGridToolbar = styled(Toolbar, { name: 'GmmDataGridToolbar' })(
  ({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    boxShadow: theme.shadows[1],
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    minHeight: 48,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up('sm')]: { minHeight: 48 },
  }),
);
