import { AccessType } from '@schema/gmm-schema/AvroSchema/GMM/CodeGen/Salesforce/AccessType';
import { Permissions } from '@schema/gmm-schema/AvroSchema/GMM/CodeGen/Salesforce/Contact';
import axios from 'axios';
import { LDUser } from 'launchdarkly-js-client-sdk';
import { snakeCase } from 'lodash';
import { User as BaseUser } from 'oidc-client-ts';
import {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { configureAxios, isAxiosError } from '~/lib/config/axios';
import { createNamedContext } from '~/lib/createNamedContext';

import { useCurrentTeacher } from '../hooks/useCurrentTeacher';

import {
  AccountType,
  Idps,
  LowercaseIdps,
  ProfileStudentSharing,
  signInSilent,
  signOut,
  signOutCallback,
  silentRenewCallback,
  User,
  userManager,
} from './utils';

interface IdentityState {
  ldUser: LDUser | null;
  user: User | null;
}

type SetIdentityStateContext = Dispatch<SetStateAction<IdentityState>>;

interface IdentityContext extends IdentityState {
  isSignedIn: () => boolean;
}

export interface ProfileContext {
  accountExpiration: string;
  accountType: AccountType;
  canSearchStudents: boolean;
  hasVerifiedEmail: boolean;
  idp: Lowercase<Idps>;
  isLocal: boolean;
  isClever: boolean;
  isClassLink: boolean;
  isGoogleClassroom: boolean;
  isNoQuote: boolean;
  isInvoiced: boolean;
  isQuoted: boolean;
  resetIdpTest: () => void;
  salesStage: string;
  setIdpTest: (idp: Idps | null) => void;
  studentSharingLevel: ProfileStudentSharing;
  canViewAdminReports: boolean;
  userPermissions?: string;
}

interface AuthContext {
  signIn: (args?: SignIn) => Promise<void>;
  signInCallback: () => Promise<void>;
  signInSilent: () => Promise<void>;
  signOut: () => Promise<void>;
  signOutCallback: () => Promise<void>;
  silentRenewCallback: () => Promise<void>;
}

const SetIdentityStateContext = createNamedContext<SetIdentityStateContext>(
  'SetIdentityStateContext',
  null as any,
);
const IdentityContext = createNamedContext<IdentityContext>(
  'IdentityContext',
  null as any,
);
const ProfileContext = createNamedContext<ProfileContext>(
  'ProfileContext',
  null as any,
);
const AuthContext = createNamedContext<AuthContext>('AuthContext', null as any);

interface Props {
  children?: ReactNode;
  user?: User;
}

interface SignIn {
  doNotRedirect?: boolean;
}

export const AuthProvider = memo<Props>(function AuthProvider({
  children,
  user: userProp = null,
}) {
  const [idpTest, setIdpTest] = useState<Idps | null>(null);
  const [identityState, setIdentityState] = useState<{
    ldUser: LDUser | null;
    user: User | null;
  }>({
    ldUser: null,
    user: userProp,
  });
  const { mutate: refreshMe } = useCurrentTeacher();

  const setIdentity = useCallback(
    async (user: BaseUser): Promise<void> => {
      const { access_token } = user;

      window.sessionStorage.removeItem('redirectRetried');
      // This stays for right now because of the call to `/me` below
      configureAxios({ authToken: access_token, idpTest });
      refreshMe();
      document.cookie =
        process.env.NODE_ENV === 'development'
          ? `mobius-socket-token=${access_token};path=/;samesite=lax`
          : `mobius-socket-token=${access_token};path=/;secure;samesite=lax`;

      window.clockSkew = 0;

      setIdentityState(state => ({ ...state, user }));
    },
    [idpTest, refreshMe],
  );

  useEffect(() => {
    userManager.events.addAccessTokenExpired(signOut);
    userManager.events.addUserLoaded(setIdentity);

    return () => {
      userManager.events.removeAccessTokenExpired(signOut);
      userManager.events.removeUserLoaded(setIdentity);
    };
  }, [setIdentity]);

  useEffect(() => {
    const authInterceptor = axios.interceptors.response.use(
      res => res,
      error => {
        if (isAxiosError(error) && error.response?.status === 401) {
          if (error.config.url !== '/presence') {
            signOut();
          }
        }

        return Promise.reject(error);
      },
    );

    return () => axios.interceptors.response.eject(authInterceptor);
  }, []);

  const identityValue = useMemo<IdentityContext>(
    () => ({
      ...identityState,
      isSignedIn: () =>
        !!identityState.user?.access_token && !identityState.user.expired,
    }),
    [identityState],
  );

  const authValue = useMemo<AuthContext>(() => {
    const signIn = async ({
      doNotRedirect = false,
    }: SignIn = {}): Promise<void> => {
      const storedUser = await userManager.getUser();

      if (storedUser && !storedUser.expired) {
        await setIdentity(storedUser);
      } else if (!doNotRedirect) {
        await userManager.signinRedirect();
      }
    };

    const signInCallback = async (): Promise<void> => {
      try {
        const user = await userManager.signinRedirectCallback();

        await userManager.storeUser(user);
      } catch (err) {
        if (!isAxiosError(err)) {
          const retried = window.sessionStorage.getItem('redirectRetried');

          if (retried === null) {
            window.sessionStorage.setItem('redirectRetried', 'true');

            return userManager.signinRedirect();
          }
        }

        throw err;
      }
    };

    return {
      signIn,
      signInCallback,
      signInSilent,
      signOut,
      signOutCallback,
      silentRenewCallback,
    };
  }, [setIdentity]);

  const resetIdpTest = useCallback(() => {
    setIdpTest(null);
  }, []);

  const profileValue = useMemo(() => {
    const user = identityState.user;
    const studentSharingLevel = user?.profile.student_sharing ?? 'None';
    const idp = (idpTest?.toLowerCase() ||
      user?.profile.idp?.toLowerCase() ||
      'local') as LowercaseIdps;
    const isLocal = idp === 'local';
    const isClever = idp === 'clever';
    const isClassLink = idp === 'classlink';
    const isGoogleClassroom = idp === 'google';
    const hasVerifiedEmail = !!user?.profile.email_verified;
    const accountType = user?.profile.acct_type || 'paid';
    const salesStageResponse = user?.profile.sales_stage || '';
    const salesStage = snakeCase(salesStageResponse);

    const permissions: Permissions = user?.profile.permissions
      ? JSON.parse(user?.profile.permissions)
      : {
          adminReports: AccessType.NONE,
        };

    const canViewAdminReports =
      permissions.adminReports === 'READ_ONLY' ||
      permissions.adminReports === 'READ_WRITE';

    // We should never use this, but we'll error on the side of accessibility
    const futureDate = new Date(
      Date.now() +
        1000 /* sec */ *
          60 /* min */ *
          60 /* hour */ *
          24 /* day */ *
          365 /* year */ *
          1,
    );

    const accountExpiration =
      user?.profile.acct_exp || futureDate.toISOString();

    return {
      accountExpiration,
      accountType,
      canSearchStudents: studentSharingLevel !== 'None',
      hasVerifiedEmail,
      idp,
      isLocal,
      isClever,
      isClassLink,
      isGoogleClassroom,
      isNoQuote: salesStage === 'no_quote',
      isInvoiced: salesStage === 'invoiced',
      isQuoted: salesStage === 'quoted',
      resetIdpTest,
      salesStage,
      setIdpTest,
      studentSharingLevel,
      canViewAdminReports,
      userPermissions: user?.profile.permissions,
    };
  }, [identityState.user, idpTest, resetIdpTest]);

  return (
    <SetIdentityStateContext.Provider value={setIdentityState}>
      <IdentityContext.Provider value={identityValue}>
        <ProfileContext.Provider value={profileValue}>
          <AuthContext.Provider value={authValue}>
            {children}
          </AuthContext.Provider>
        </ProfileContext.Provider>
      </IdentityContext.Provider>
    </SetIdentityStateContext.Provider>
  );
});

export function useSetIdentity(): SetIdentityStateContext {
  const setState = useContext(SetIdentityStateContext);

  if (process.env.NODE_ENV === 'development' && setState === null) {
    throw new Error(`useSetIdentity should only be used within Auth`);
  }

  return setState;
}

export function useIdentity(): IdentityContext {
  const identity = useContext(IdentityContext);

  if (process.env.NODE_ENV === 'development' && identity === null) {
    throw new Error(`useIdentity should only be used within Auth`);
  }

  return identity;
}

export function useProfile(): ProfileContext {
  const profile = useContext(ProfileContext);

  if (process.env.NODE_ENV === 'development' && profile === null) {
    throw new Error(`useProfile should only be used within Auth`);
  }

  return profile;
}

export function useAuth(): AuthContext {
  const oidc = useContext(AuthContext);

  if (process.env.NODE_ENV === 'development' && oidc === null) {
    throw new Error(`useAuth should only be used within Auth`);
  }

  return oidc;
}
