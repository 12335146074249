import {
  IconButton,
  IconButtonProps,
  InputBaseComponentProps,
  styled,
  useForkRef,
} from '@mui/material';
import { toFinite, uniqueId } from 'lodash';
import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ArrowDropDown, ArrowDropUp } from '@gmm/icons';

import { dispatchChangeEvent, restrictValue } from '../utils';

import {
  getContainedCounter,
  getEffectiveStep,
  getRegex,
  normalizeMaxMin,
} from './utils';

interface RootProps {
  isFocused: boolean;
}

const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'isFocused',
})<RootProps>(({ isFocused }) => ({
  '&&': {
    alignItems: 'center',
    display: 'inline-flex',
    flex: 1,
    ...(isFocused
      ? {
          '& > div': {
            opacity: 1,
            pointerEvents: 'all',
          },
        }
      : null),
  },
}));

const Input = styled('input')({
  '&&': {
    background: 'none',
    border: 0,
    font: 'inherit',
    letterSpacing: 'inherit',
    padding: 0,
    '&:focus': { outline: 0 },
  },
});

const Arrows = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  height: 'auto',
  opacity: 0,
  pointerEvents: 'none',
  position: 'relative',
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
    easing: theme.transitions.easing.easeInOut,
  }),
  width: theme.spacing(2),
}));

const UIButton: FC<Omit<IconButtonProps<'label'>, 'component'>> = props => (
  <IconButton {...props} component="label" />
);

const ArrowButton = styled(UIButton)(({ theme }) => ({
  display: 'block',
  fontSize: theme.typography.body1.fontSize,
  padding: 0,
  '& svg': { display: 'block' },
}));

export interface NumberInputProps extends InputBaseComponentProps {
  allowDecimals?: boolean;
  allowNegative?: boolean;
  max?: number | void;
  min?: number | void;
  step?: number;
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInput(
    {
      allowDecimals = false,
      allowNegative = false,
      className,
      id: idProp,
      max: maxProp,
      min: minProp,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      step: stepProp = 1,
      ...inputProps
    },
    inputRefProp,
  ) {
    const [isFocused, setFocused] = useState(false);
    const [id] = useState(() => idProp ?? uniqueId('number-input'));
    const inputRef = useRef<HTMLInputElement | null>(null);
    const ref = useForkRef(inputRefProp, inputRef);
    const previousValueRef = useRef<string>();

    useEffect(() => {
      previousValueRef.current = inputRef.current?.value;
    }, []);

    const { max, min } = normalizeMaxMin(allowNegative, maxProp, minProp);
    const step = getEffectiveStep(stepProp, allowDecimals);
    const reMatch = getRegex(allowDecimals, allowNegative);

    const setNextValue = (value: string): void => {
      /* istanbul ignore else */
      if (inputRef.current) {
        dispatchChangeEvent(inputRef.current, value);
        previousValueRef.current = value;
      }
    };

    const { increment: onIncrement, decrement: onDecrement } =
      getContainedCounter(setNextValue, { max, min, step });

    const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
      const { selectionEnd, value } = restrictValue(
        event.target,
        previousValueRef.current,
      );
      const numberValue = toFinite(value);

      if (value !== '' && (numberValue < min || numberValue > max)) {
        const { current: previousValue } = previousValueRef;

        event.target.value = previousValue ?? '';

        return onChange?.(event);
      }

      previousValueRef.current = value;
      event.target.value = value;
      event.target.selectionEnd = selectionEnd;

      return onChange?.(event);
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = event => {
      const { relatedTarget, target } = event;

      if (
        relatedTarget instanceof HTMLLabelElement &&
        relatedTarget.htmlFor === target.id
      ) {
        event.preventDefault();
        event.stopPropagation();

        return;
      }

      setFocused(false);
      onBlur?.(event);
    };

    const handleFocus: FocusEventHandler<HTMLInputElement> = event => {
      setFocused(true);
      onFocus?.(event);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
      onKeyDown?.(event);

      if (event.isDefaultPrevented()) return;

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        onIncrement(event);
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        onDecrement(event);
      }
    };

    return (
      <Root className={className} isFocused={isFocused}>
        <Input
          {...inputProps}
          aria-valuemax={isFinite(max) ? max : undefined}
          aria-valuemin={isFinite(min) ? min : undefined}
          autoComplete="off"
          className={className}
          id={id}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          pattern={reMatch.source}
          ref={ref}
          role="spinbutton"
        />
        <Arrows>
          <ArrowButton
            aria-label={`+${step}`}
            htmlFor={id}
            onClick={onIncrement}
            role=""
            tabIndex={-1}
          >
            <ArrowDropUp fontSize="inherit" />
          </ArrowButton>
          <ArrowButton
            aria-label={`-${step}`}
            htmlFor={id}
            onClick={onDecrement}
            role=""
            tabIndex={-1}
          >
            <ArrowDropDown fontSize="inherit" />
          </ArrowButton>
        </Arrows>
      </Root>
    );
  },
);
