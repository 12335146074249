import { RefObject, useEffect, useRef } from 'react';

/**
 * Used to make sure a component is still mounted.
 *
 * Useful when you attempt to update state from some other async action
 * ie. window.requestAnimationFrame or any AJAX call
 */
export const useMountedRef = (): RefObject<boolean> => {
  const isMountedRef = useRef(true);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    [],
  );

  return isMountedRef;
};
