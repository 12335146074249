import { findLastIndex } from 'lodash';
import { nanoid } from 'nanoid';
import { Key, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useLatestRef } from '@gmm/ui';
import { getEmptyArray } from '~/lib/type-utils';

import { LayerContext, LayerProps, Level, RenderLayer } from './layerProvider';

type LocationState = { layers?: Key[] };

interface Options {
  initialProps?: Partial<LayerProps>;
  key?: Key;
  level?: Level;
}

interface UseAddLayer {
  close: () => void;
  isOpen: () => boolean;
  open: () => void;
}

export const useAddLayer = (
  render: RenderLayer,
  { initialProps, key, level = 1 }: Options = {},
): UseAddLayer => {
  const renderRef = useLatestRef(render);
  const [layerKey] = useState(() => key ?? nanoid());
  const history = useHistory<LocationState>();
  const location = useLocation<LocationState>();
  const activeLayers = location.state?.layers ?? getEmptyArray<Key[]>();
  const setLayers = useContext(LayerContext);
  const close = (): void => {
    const layers = activeLayers.filter(key => layerKey !== key);

    history.replace(history.location.pathname, {
      ...history.location?.state,
      layers,
    });
  };
  // Used in the effect, but we don't to re-render when it changes
  const closeRef = useLatestRef(close);
  const open = (): void =>
    history.replace(history.location.pathname, {
      ...history.location.state,
      layers: [...activeLayers, layerKey],
    });
  // Used in the effect, but we don't to re-render when it changes
  const openRef = useLatestRef(open);
  const initialPropsRef = useRef({
    onClose: () => {
      initialProps?.onClose?.();
      closeRef.current();
    },
    open: false,
  });
  const [shouldOpenOnMount] = useState(() => initialProps?.open ?? false);

  // This is essentially a registration process. It only reruns if `level` changes
  useEffect(() => {
    setLayers(draft => {
      const lastIndexForLevel = findLastIndex(draft, ['level', level]);

      draft.splice(lastIndexForLevel, 0, {
        render: props => renderRef.current(props),
        key: layerKey,
        level,
        props: initialPropsRef.current,
      });
    });

    if (shouldOpenOnMount) openRef.current();

    return () =>
      setLayers(draft => {
        const index = draft.findIndex(({ key }) => key === layerKey);

        /* istanbul ignore else: should always find it */
        if (index > -1) draft.splice(index, 1);
      });
  }, [renderRef, layerKey, level, openRef, setLayers, shouldOpenOnMount]);

  // Use an effect to open and close so we can prevent transition without opening
  useEffect(() => {
    const open = activeLayers.some(key => layerKey === key);

    setLayers(draft => {
      const def = draft.find(({ key }) => key === layerKey);

      /* istanbul ignore else: should always find it */
      if (def) def.props.open = open;
    });
  }, [activeLayers, layerKey, setLayers]);

  return {
    close,
    isOpen: () => activeLayers.some(key => layerKey === key),
    open,
  };
};
