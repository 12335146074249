import { isSkillType } from '@gmm/ui';

// Needed since a skill can be in multiple folders in the same collection
// especially prevelant in colleagues/work
const skillIdentifierDelimiter = ':::';

export const EXAM_PATH = '/create/exam';
export interface IdentifiableItem {
  id: string;
  parentId: string | null;
  type: string;
}

export const getIdentifier = <T extends IdentifiableItem>(item: T): string =>
  isSkillType(item)
    ? `${item.parentId}${skillIdentifierDelimiter}${item.id}`
    : item.id;

export const isSkillIdentifier = (id: string): boolean =>
  id.indexOf(skillIdentifierDelimiter) > -1;
