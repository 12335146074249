import { AxiosRequestConfig } from 'axios';

import { StudentShared } from '../shared';

interface StudentSearchResultItem extends StudentShared {
  lastLoggedInAt: string;
  mostRecentSchoolName: string;
  mostRecentTeacherName: string;
}

export type StudentsIndexResponse = StudentSearchResultItem[];

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  params: { query: string };
  url: '/students';
}

/**
 * GET /api/v2/students
 */
export const list = (query: string): ListConfig => {
  return {
    method: 'GET',
    params: { query },
    url: '/students',
  };
};
