import { Button, ButtonProps, Tooltip, TooltipProps } from '@mui/material';
import { FC, ReactNode } from 'react';

import { DestructiveButton } from '../../../destructive-button';

export interface ModalDialogActionProps
  extends Omit<ButtonProps, 'children' | 'color' | 'variant'> {
  color?: ButtonProps['color'] | 'destructive';
  disallowDisabled?: boolean;
  text: ReactNode;
  tooltip?: string;
  toolipPlacement?: TooltipProps['placement'];
}

export const ModalDialogAction: FC<ModalDialogActionProps> = ({
  color: colorProp,
  disabled = false,
  disallowDisabled = false,
  text,
  tooltip = '',
  toolipPlacement = 'top',
  ...buttonProps
}) => {
  const isDestructive = colorProp === 'destructive';
  const [ButtonComponent, color] =
    !disallowDisabled && isDestructive
      ? [DestructiveButton]
      : [Button, disallowDisabled || isDestructive ? undefined : colorProp];

  return (
    <Tooltip placement={toolipPlacement} title={tooltip}>
      <div>
        <ButtonComponent
          {...buttonProps}
          color={color}
          disabled={disallowDisabled ? false : disabled}
          variant="text"
        >
          {text}
        </ButtonComponent>
      </div>
    </Tooltip>
  );
};
