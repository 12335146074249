import { AxiosRequestConfig } from 'axios';

import { ProblemAndAnswer } from '../shared';
import {
  CurrentStatusAssignment,
  CurrentStatusExam,
} from '../ws/classCurrentStatusChannel';

export interface CurrentStatusIndexItem {
  exercise?: CurrentStatusAssignment | CurrentStatusExam;
  help?: number;
  helpRequestedProblem?: ProblemAndAnswer & {
    condensed: boolean;
    overrideId?: number;
  };
  firstName: string;
  isInIndividualMixedReview: boolean;
  lastCorrect?: string;
  lastName: string;
  online: boolean;
  score?: number;
  studentInClassId: string;
  studentName: string;
  totalToday: number;
  type?: 'HandLowered' | 'HandRaised';
}

// TODO: Define types that are used in WS
// TODO: Clean up CurrentStatus calls
// TODO: Use this response
export type CurrentStatusIndexResponse = CurrentStatusIndexItem[];

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `classes/${string}/current_status`;
}

/**
 * GET /api/v2/classes/{id}/current_status
 */
export const list = (classId: string): ListConfig => {
  return {
    method: 'GET',
    url: `classes/${classId}/current_status`,
  } as const;
};
