import {
  ChangeEvent,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import { createNamedContext } from '~/lib/createNamedContext';

import { commonEvents } from '../analytics/events';
import { useToggle } from '../hooks';
import { useLocationEffect } from '../hooks/useLocationEffect';
import { MobiusProblemAndAnswer } from '../problemPreview/hooks/utils';

import { Sibling } from './utils';

enum SkillPreviewTab {
  Preview = 'preview',
  Answer = 'answer',
  Students = 'students',
}

interface OpenFullscreenWith {
  studentProblemData: MobiusProblemAndAnswer | null;
  description?: string;
  siblingSkills: Sibling[];
  title?: ReactNode;
  type: string;
}

interface Context {
  currentIndex: number;
  studentProblemData: MobiusProblemAndAnswer | null;
  currentType: string;
  description?: string;
  handleTabChange: (
    event: ChangeEvent<unknown>,
    newTab: SkillPreviewTab,
  ) => void;
  isModalOpen: boolean;
  onClose: () => void;
  openFullscreenWith: (args: OpenFullscreenWith) => void;
  setOnFullscreenClose: (
    onFullscreenClose: (() => OnFullscreenClose) | undefined,
  ) => void;
  setTitle: Dispatch<SetStateAction<ReactNode | undefined>>;
  showNextSibling: () => void;
  showPreviousSibling: () => void;
  siblingSkills: Sibling[];
  tab: string;
  title: ReactNode | undefined;
}

type OnFullscreenClose = (skillType: string) => void;

const FullscreenContext = createNamedContext<Context>(
  'FullscreenContext',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  null as any,
);

export const FullscreenProvider: FC = ({ children }) => {
  const [siblingSkills, setSiblingSkills] = useState<Sibling[]>([]);
  const [currentType, setCurrentType] = useState('');
  const [description, setDescription] = useState<string | undefined>();
  const [title, setTitle] = useState<ReactNode | undefined>();
  const [isModalOpen, showModal, closeModal] = useToggle();
  const [tab, setTab] = useState(SkillPreviewTab.Preview);
  const onFullscreenCloseRef = useRef<
    ((skillType: string) => void) | undefined
  >();
  const setOnFullscreenClose = (
    onFullscreenClose: (() => OnFullscreenClose) | undefined,
  ): void => {
    onFullscreenCloseRef.current =
      typeof onFullscreenClose === 'function'
        ? onFullscreenClose()
        : onFullscreenClose;
  };

  const [studentProblemData, setCurrentProblemData] =
    useState<MobiusProblemAndAnswer | null>(null);

  const handleTabChange = (
    event: ChangeEvent<unknown>,
    newTab: SkillPreviewTab,
  ): void => {
    event.stopPropagation();
    commonEvents.previewSkill(currentType, newTab);
    setTab(newTab);
  };

  const openFullscreenWith = ({
    studentProblemData,
    description,
    siblingSkills,
    title,
    type,
  }: OpenFullscreenWith): void => {
    if (tab === SkillPreviewTab.Students) {
      setTab(SkillPreviewTab.Preview);
    }

    showModal();
    setCurrentProblemData(studentProblemData);
    setCurrentType(type);
    setDescription(description);
    setSiblingSkills(siblingSkills);
    setTitle(title);
  };

  // skills can have a type, an id, or both, depending on context
  // in skill discovery, type refers to the Tag type, not the Skill type
  const hasType = siblingSkills.length && !!Number(siblingSkills[0].type);

  const currentIndex = hasType
    ? siblingSkills.findIndex(skill => skill.type === currentType)
    : siblingSkills.findIndex(skill => skill.id === currentType);

  const showNextSibling = (): void => {
    if (currentIndex === -1) return;

    if (currentIndex === siblingSkills.length - 1) return;

    const next = siblingSkills[currentIndex + 1];

    setDescription(next.description || next.name);
    setCurrentType(hasType ? next.type! : next.id!);
    setTab(SkillPreviewTab.Preview);
  };

  const showPreviousSibling = (): void => {
    if (currentIndex === -1) return;

    if (currentIndex === 0) return;

    const next = siblingSkills[currentIndex - 1];

    setDescription(next.description || next.name);
    setCurrentType(hasType ? next.type! : next.id!);
    setTab(SkillPreviewTab.Preview);
  };

  const onClose = useCallback((): void => {
    closeModal();
    onFullscreenCloseRef.current?.(currentType);
    setOnFullscreenClose(undefined);
  }, [closeModal, currentType]);

  useLocationEffect(() => {
    setTab(SkillPreviewTab.Preview);
    setOnFullscreenClose(undefined);
  });

  useLocationEffect((_, action) => {
    if (action === 'POP') {
      onClose();
    }
  });

  const value = {
    currentIndex,
    currentType,
    description,
    handleTabChange,
    studentProblemData,
    isModalOpen,
    openFullscreenWith,
    onClose,
    setOnFullscreenClose,
    setTitle,
    showNextSibling,
    showPreviousSibling,
    siblingSkills,
    tab,
    title,
  };

  return (
    <FullscreenContext.Provider value={value}>
      {children}
    </FullscreenContext.Provider>
  );
};

export const useFullscreenContext = (): Context =>
  useContext(FullscreenContext);
