import { Checkbox, styled } from '@mui/material';
import { FC } from 'react';
import { Row } from 'react-table';

const Root = styled(Checkbox)(({ theme }) => ({
  margin: theme.spacing(-1, 0),
}));

interface Props {
  row: Row<any>;
}

export const SelectableCell: FC<Props> = ({ row }) => (
  <Root
    {...row.getToggleRowSelectedProps()}
    inputProps={{ 'aria-label': `Select Row ${row.index}` }}
  />
);
