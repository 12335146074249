import {
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  KeyboardSensor,
  MeasuringStrategy,
  Modifier,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Updater, useImmer } from 'use-immer';

import { useLatestRef } from '../use-latest-ref';

import { RenderItemProps, SortableTreeItem } from './components';
import { Position, useAnnouncements } from './hooks';
import type { SensorContext, TreeItem, TreeItems } from './types';
import {
  sortableTreeKeyboardCoordinates,
  removeChildrenOf,
  getProjection,
  getChildCount,
  buildTree,
  findItemDeep,
  flattenTree,
  removeItem,
} from './utils';
import {
  defaultMakeAnnouncements,
  MakeAnnouncements,
} from './utils/default-make-announcements';

const EXPAND_TIMEOUT = 500;

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimation: DropAnimation = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5,
};

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

interface State {
  activeId: string | null;
  overId: string | null;
  offsetLeft: number;
}

const defaultState: State = {
  activeId: null,
  overId: null,
  offsetLeft: 0,
};

interface Props<T> {
  accepts?: (parent: T, child: T) => boolean;
  collapsedIds?: Record<string, boolean>;
  indentationWidth?: number;
  indicator?: boolean;
  items: TreeItems<T>;
  makeAnnouncements?: MakeAnnouncements<T>;
  onCollapse?: (id: string) => void;
  onUpdate: Updater<TreeItems<T>>;
  removable?: boolean;
  renderItem: (props: RenderItemProps<TreeItem<T>>) => ReactNode;
}

export const SortableTree = <T,>({
  accepts = () => true,
  collapsedIds = {},
  indentationWidth = 16,
  indicator = false,
  items,
  makeAnnouncements = defaultMakeAnnouncements,
  onCollapse,
  onUpdate,
  removable,
  renderItem,
}: Props<T>): JSX.Element => {
  const acceptsRef = useLatestRef(accepts);
  const onCollapseRef = useLatestRef(onCollapse);
  const [{ activeId, overId, offsetLeft }, setState] = useImmer<State>(
    () => defaultState,
  );
  const [currentPosition, setCurrentPosition] = useState<Position | null>(null);
  const flattenedItems = useMemo(() => {
    const flattened = flattenTree<T>(items);
    const collapsedItems = flattened.reduce<string[]>(
      (acc, { children, id }) => {
        if (collapsedIds[id] && children.length) acc.push(id);

        return acc;
      },
      [],
    );

    return removeChildrenOf(
      flattened,
      // istanbul ignore next: not testing drag and drop
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    );
  }, [activeId, collapsedIds, items]);

  // istanbul ignore next: not testing drag and drop
  const projected = useMemo(
    () =>
      activeId && overId
        ? getProjection<T>({
            accepts: acceptsRef.current,
            activeId,
            dragOffset: offsetLeft,
            indentationWidth,
            items: flattenedItems,
            overId,
          })
        : null,
    [
      acceptsRef,
      activeId,
      flattenedItems,
      indentationWidth,
      offsetLeft,
      overId,
    ],
  );

  const sensorContext: SensorContext<T> = useRef({
    accepts: acceptsRef.current,
    items: flattenedItems,
    offset: offsetLeft,
  });

  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth),
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter }),
  );

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems],
  );
  // istanbul ignore next: not testing drag and drop
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      accepts: acceptsRef.current,
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [acceptsRef, flattenedItems, offsetLeft]);

  // istanbul ignore next: not testing drag and drop
  useEffect(() => {
    const parentId = projected?.parentId;

    if (!parentId || !collapsedIds[parentId]) return;

    const timer = window.setTimeout(() => {
      const item = findItemDeep(items, parentId);

      if (!item) return;

      onCollapseRef.current?.(item.id);
    }, EXPAND_TIMEOUT);

    return () => window.clearTimeout(timer);
  }, [collapsedIds, flattenedItems, items, onCollapseRef, projected?.parentId]);

  // istanbul ignore next: not testing drag and drop
  const announcements = useAnnouncements({
    collapsedIds,
    currentPosition,
    items,
    makeAnnouncements,
    projected,
    setCurrentPosition,
  });

  // istanbul ignore next: not testing drag and drop
  const resetState = useCallback((): void => {
    setState(() => defaultState);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  }, [setState]);

  // istanbul ignore next: not testing drag and drop
  const handleDragStart = useCallback(
    ({ active: { id: activeId } }: DragStartEvent): void => {
      setState(draft => {
        draft.activeId = activeId;
        draft.overId = activeId;
      });

      const activeItem = flattenedItems.find(({ id }) => id === activeId);

      if (activeItem) {
        setCurrentPosition({
          parentId: activeItem.parentId,
          overId: activeId,
        });
      }

      document.body.style.setProperty('cursor', 'grabbing');
    },
    [flattenedItems, setState],
  );

  // istanbul ignore next: not testing drag and drop
  const handleDragMove = useCallback(
    ({ delta }: DragMoveEvent): void =>
      setState(draft => {
        draft.offsetLeft = delta.x;
      }),
    [setState],
  );

  // istanbul ignore next: not testing drag and drop
  const handleDragOver = useCallback(
    ({ over }: DragOverEvent): void =>
      setState(draft => {
        draft.overId = over?.id ?? null;
      }),
    [setState],
  );

  // istanbul ignore next: not testing drag and drop
  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent): void => {
      resetState();

      if (!projected || !over) return;

      const { depth, parentId } = projected;
      const clonedItems = [...flattenTree(items)];
      const parentItem = clonedItems.find(({ id }) => id === parentId);
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(
        clonedItems,
        activeIndex,
        // If the folder is collapsed, add as first item
        overIndex - (parentItem && collapsedIds[parentItem.id] ? 1 : 0),
      );

      if (parentItem && collapsedIds[parentItem.id]) {
        onCollapseRef.current?.(parentItem.id);
      }

      const newItems = buildTree(sortedItems);

      onUpdate(() => newItems);
    },
    [collapsedIds, items, onCollapseRef, onUpdate, projected, resetState],
  );

  // istanbul ignore next: not testing drag and drop
  const handleDragCancel = useCallback((): void => resetState(), [resetState]);

  // istanbul ignore next: not testing drag and drop
  const handleCollapse = useCallback(
    (id: string): void => {
      const item = findItemDeep(items, id);

      if (!item) return;

      onCollapseRef.current?.(item.id);
    },
    [items, onCollapseRef],
  );

  // istanbul ignore next: not testing drag and drop
  const handleRemove = useCallback(
    (id: string): void => onUpdate(draft => removeItem(draft, id)),
    [onUpdate],
  );

  return (
    <DndContext
      announcements={announcements}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(({ id, depth }) => {
          const item = findItemDeep(items, id);

          if (!item) return null;

          return (
            <SortableTreeItem
              collapsed={Boolean(collapsedIds[id])}
              depth={
                // istanbul ignore next: not testing drag and drop
                id === activeId && projected ? projected.depth : depth
              }
              id={id}
              indicator={indicator}
              indentationWidth={indentationWidth}
              item={item}
              key={id}
              onCollapse={onCollapse ? handleCollapse : undefined}
              onRemove={removable ? handleRemove : undefined}
              renderItem={renderItem}
              value={id}
            />
          );
        })}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimation}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {
              // istanbul ignore next: not testing drag and drop
              activeId && activeItem ? (
                <SortableTreeItem
                  childCount={getChildCount(items, activeId) + 1}
                  clone
                  depth={activeItem.depth}
                  id={activeId}
                  indentationWidth={indentationWidth}
                  item={activeItem}
                  renderItem={renderItem}
                  value={activeId}
                />
              ) : null
            }
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  );
};
