import { AxiosRequestConfig } from 'axios';

interface Override {
  restoreId?: number;
}

interface OverrideConfig extends AxiosRequestConfig {
  body: Override;
  data: Override;
  method: 'POST';
  url: `/student_in_classes/${string}/current_problem`;
}

/**
 * POST /api/v2/student_in_classes/{id}/current_problem
 *
 * { restoreId?: number }
 */
export const override = (
  studentInClassId: string,
  data: Override,
): OverrideConfig => {
  return {
    method: 'POST',
    url: `/student_in_classes/${studentInClassId}/current_problem`,
    body: data,
    data,
  } as const;
};
