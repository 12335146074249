import produce, { Draft, enablePatches } from 'immer';
import { useCallback } from 'react';
import {
  DraftMutatorOpions,
  Middleware,
  MutatorCallback,
  ValidRecipeReturnType,
} from 'swr';

enablePatches();

export const mutateDraft: Middleware = useSWRNext => {
  return (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config);
    const { mutate } = swr;
    const mutateDraft = useCallback(
      async (
        producer: (draft: Draft<unknown>) => ValidRecipeReturnType<unknown>,
        options: boolean | DraftMutatorOpions<any> = {},
      ) => {
        const { patchListener = undefined, ...opts } =
          typeof options === 'boolean' ? { revalidate: options } : options;
        const mutator: MutatorCallback | undefined = producer
          ? data => (data ? produce(data, producer, patchListener) : data)
          : undefined;

        return mutate(mutator, opts);
      },
      [mutate],
    );

    return Object.defineProperty(swr, 'mutateDraft', {
      enumerable: true,
      value: mutateDraft,
    });
  };
};
