import { trackIntercomEvent } from '~/lib/config/intercom';

import { Labels } from './common';
import { eventFactory } from './utils';

const publish = 'publish';

const examTemplateEvent = eventFactory('exam');

export const cancelPublish = (): void =>
  examTemplateEvent({
    action: publish,
    label: Labels.Cancel,
  });

export const confirmCreated = (totalSkillInstances: number): void => {
  examTemplateEvent({
    action: 'create',
    label: Labels.Submit,
    value: totalSkillInstances,
  });
  trackIntercomEvent('created-exam');
};

export const confirmEdited = (totalSkillInstances: number): void => {
  examTemplateEvent({
    action: 'edit',
    label: Labels.Submit,
    value: totalSkillInstances,
  });
};

export const confirmPublish = (classesToPublishToCount: number): void => {
  examTemplateEvent({
    action: publish,
    label: Labels.Submit,
    value: classesToPublishToCount,
  });
};

export const print = (includeAnswerKey: boolean): void => {
  const label = includeAnswerKey ? 'include answer key' : 'no answer key';

  examTemplateEvent({ action: 'print practice exam', label });
};

export const shuffleSkills = (): void =>
  examTemplateEvent({
    action: 'publish',
    label: 'shuffle-skills',
  });
