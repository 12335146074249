import {
  Channel,
  ChannelNameWithParams,
  CreateMixin,
} from '@rails/actioncable';
import { bindAll } from 'lodash';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';

import { useLatestRef } from '@gmm/ui';

import { useCableContext } from './cable';

const EMPTY_CHANNEL: Channel<any> = {};

export const useActionCable = <M extends CreateMixin>(
  params: string | ChannelNameWithParams | null | undefined,
  handlers: M = {} as M,
): Channel<M> => {
  const consumer = useCableContext();
  const [channel, setChannel] = useState<Channel<M> | null>(null);
  const [channelParams, setChannelParams] = useState(params);
  const latestHandlers = useLatestRef(handlers);

  useEffect(() => {
    setChannelParams(currentParams => {
      if (isEqual(currentParams, params)) return currentParams;

      return params;
    });
  }, [params]);

  useEffect(() => {
    if (!consumer || !channelParams) return;

    const subscription = consumer.subscriptions.create<M>(
      channelParams,
      latestHandlers.current,
    );

    if (subscription) {
      bindAll(subscription, [
        'send',
        'perform',
        ...Object.keys(latestHandlers.current),
      ]);
    }

    setChannel(subscription || null);

    return () => {
      subscription.unsubscribe();
    };
  }, [channelParams, consumer, latestHandlers]);

  return channel || EMPTY_CHANNEL;
};
