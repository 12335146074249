import { SchoolInterface } from '@schema/gmm-schema/AvroSchema/GMM/CodeGen/Salesforce/School';
import { AxiosRequestConfig } from 'axios';

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: '/claim_school/schools';
}

interface List {
  zip: string;
}

export type ClaimSchoolSchoolsIndexResponse = SchoolInterface[];

export const list = (params: List): ListConfig => ({
  method: 'GET',
  params,
  url: '/claim_school/schools',
});
