import { AxiosRequestConfig } from 'axios';

import { FolderWorkItem } from '../shared';
import { transformResponseToJSON, UnknownError } from '../utils';

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: '/my_work';
}

export type MyWorkIndexResponse = FolderWorkItem['children'];

const hasChildren = (
  data: unknown,
): data is { children: MyWorkIndexResponse } =>
  typeof data === 'object' && data !== null && 'children' in data;

/**
 * GET /api/v2/my_work
 */
export const list = (): ListConfig => {
  return {
    method: 'GET',
    transformResponse: [
      (stringData: string): MyWorkIndexResponse | UnknownError => {
        const data = transformResponseToJSON<FolderWorkItem>(stringData);

        if (!hasChildren(data)) return data;

        return data.children;
      },
    ],
    url: '/my_work',
  };
};
