import { AxiosRequestConfig } from 'axios';

import { StudentInKlassBase, StudentShared } from '../../shared';

interface Scores {
  earned: number;
  required: number;
}

export interface StudentDataExamsItem {
  counts: {
    followUpScores: Scores;
    graded: number;
    initialScores: Scores;
    notStarted: number;
    total: number;
  };
  student: StudentShared;
  studentInClass: StudentInKlassBase;
  studentInClassId: string;
}

export interface StudentDataExamsIndexResponse {
  students: StudentDataExamsItem[];
}

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/classes/${string}/student_data/exams`;
}

export const listExams = (classId: string): ListConfig => ({
  method: 'GET',
  url: `/classes/${classId}/student_data/exams`,
});
