import {
  Cable,
  Connection,
  ConnectionMonitor,
  createConsumer,
} from '@rails/actioncable';
import * as Sentry from '@sentry/browser';
import { FC, useContext, useEffect, useState } from 'react';

import { createNamedContext } from '~/lib/createNamedContext';

ConnectionMonitor.staleThreshold = 20;

const connectedTagKey = 'ws_connected';

const originalOpenEvent = Connection.prototype.events.open;
const originalCloseEvent = Connection.prototype.events.close;

Connection.prototype.events.open = function (event) {
  Sentry.setTag(connectedTagKey, 'true');
  originalOpenEvent?.call(this, event);
};

Connection.prototype.events.close = function (event) {
  Sentry.setTag(connectedTagKey, 'false');
  originalCloseEvent?.call(this, event);
};

interface Props {
  url: string;
}

const CableContext = createNamedContext<Cable | null>('CableContext', null);

const getWebsocketUrl = (url: string): string => {
  const newUrl = new URL(url, location.href);

  newUrl.protocol = newUrl.protocol.replace(/^http/, 'ws');

  return newUrl.href;
};

export const CableProvider: FC<Props> = ({ children, url }) => {
  const [consumer, setConsumer] = useState<Cable | null>(null);

  useEffect(() => {
    const cable = createConsumer(getWebsocketUrl(url));

    setConsumer(cable);

    return () => {
      setConsumer(null);
      cable.disconnect();
    };
  }, [url]);

  return (
    <CableContext.Provider value={consumer}>{children}</CableContext.Provider>
  );
};

export const useCableContext = (): Cable | null => useContext(CableContext);
