import { eventFactory } from './utils';

const classAssignmentsEvent = eventFactory('class assignments');

export const archiveAssignment = (): void => {
  classAssignmentsEvent({ action: 'archive assignment' });
};

export const toggleAvailable = (available: boolean): void => {
  const asWord = available ? 'available' : 'unavailable';

  classAssignmentsEvent({ action: `made-assignment-${asWord}` });
};

export const toggleDefault = (isDefault: boolean): void => {
  const asWord = isDefault ? 'set' : 'clear';

  classAssignmentsEvent({ action: `${asWord} default assignment` });
};

export const undoArchiveAssignment = (): void => {
  classAssignmentsEvent({ action: 'undo archive assignment' });
};
