import { AxiosRequestConfig } from 'axios';

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/skills/${string}`;
}

/**
 * GET /api/v2/skills/{id}
 */
export const show = (
  id: string,
  config: Omit<AxiosRequestConfig, 'method' | 'url'> = {},
): ShowConfig => {
  return {
    method: 'GET',
    url: `/skills/${id}`,
    ...config,
  } as const;
};
