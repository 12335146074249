import { AxiosRequestConfig } from 'axios';

import { KlassAssignmentItem } from '../shared';

export interface KlassAssignmentsIndexResponse {
  classAssignments: KlassAssignmentItem[];
}

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/classes/${string}/class_assignments`;
}

/**
 * GET /api/v2/classes/${id}/class_assignments
 */
export const list = (classId: string): ListConfig => {
  return {
    method: 'GET',
    url: `/classes/${classId}/class_assignments`,
  } as const;
};
