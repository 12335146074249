import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogContentText,
  DialogProps,
  DialogTitleProps,
  PaperProps,
  styled,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import {
  ComponentType,
  CSSProperties,
  FC,
  HTMLAttributes,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { isElement } from 'react-is';

import {
  ModalDialogAction,
  ModalDialogActionProps,
  ModalDialogAlert,
  ModalDialogAlertProps,
  ModalDialogSecondaryActions,
  ModalDialogTitle,
} from './components';

const noStyles = {};

const PREFIX = 'GmmModalDialog';

export const modalDialogClasses = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
};

const Dialog = styled(MuiDialog, {
  name: PREFIX,
})(({ theme }) => ({
  [`& .${modalDialogClasses.title} + .${modalDialogClasses.content}`]: {
    paddingTop: theme.spacing(1),
  },
}));

const ModalDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
}));

interface BaseModalDialogProps
  extends Omit<DialogProps, 'PaperComponent' | 'title' | 'TransitionProps'>,
    TransitionProps,
    Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  alertProps?: ModalDialogAlertProps;
  contentProps?: DialogContentProps;
  content: ReactNode;
  disableBackdropClick?: boolean;
  hideCloseButton?: boolean;
  onEscapeKeyDown?: KeyboardEventHandler;
  PaperComponent?: ComponentType<PaperProps<any>>;
  secondaryActions?: ModalDialogActionProps[];
  titleProps?: Omit<DialogTitleProps, 'children'>;
  title?: DialogTitleProps['children'];
}

interface SimpleDialogProps extends BaseModalDialogProps {
  acknowledgementAction?: never;
  confirmationAction?: never;
  dismissiveAction?: never;
}

interface AcknowledgementDialogProps extends BaseModalDialogProps {
  acknowledgementAction: ModalDialogActionProps;
  confirmationAction?: never;
  dismissiveAction?: never;
}

export interface ConfirmationDialogProps extends BaseModalDialogProps {
  acknowledgementAction?: never;
  confirmationAction: ModalDialogActionProps;
  dismissiveAction: Omit<ModalDialogActionProps, 'color' | 'disabled'>;
}

export type ModalDialogProps =
  | SimpleDialogProps
  | AcknowledgementDialogProps
  | ConfirmationDialogProps;

export const ModalDialog: FC<ModalDialogProps> = ({
  acknowledgementAction,
  alertProps,
  'aria-labelledby': ariaLabelledby,
  'aria-describedby': ariaDescribedby,
  className,
  confirmationAction,
  dismissiveAction,
  contentProps,
  content,
  disableBackdropClick,
  disableEscapeKeyDown,
  hideCloseButton,
  onBackdropClick,
  onClose,
  onEnter,
  onEntered,
  onEntering,
  onEscapeKeyDown,
  onExit,
  onExited,
  onExiting,
  PaperProps,
  secondaryActions,
  titleProps,
  title,
  ...dialogProps
}) => {
  const [titleId] = useState(() => {
    if (!title) return;
    if (titleProps?.id) return titleProps.id;
    if (ariaLabelledby) return ariaLabelledby;

    return uniqueId('modal-dialog-title');
  });
  const [contentId] = useState(() => {
    if (contentProps?.id) return contentProps.id;
    if (ariaDescribedby) return ariaDescribedby;

    return uniqueId('modal-dialog-content');
  });
  const [positionStyles, setPositionStyles] = useState<CSSProperties>(
    () => PaperProps?.style ?? noStyles,
  );

  const paperRef = useCallback(
    (node: HTMLDivElement) => {
      if (typeof PaperProps?.ref === 'function') {
        PaperProps.ref(node);
      } else if (PaperProps?.ref) {
        // @ts-expect-error I don't really think this would be read-only
        // but I also don't see us really setting this.
        PaperProps.ref.current = node;
      }

      // If there's no alert, we don't want to worry about the positioning
      if (!alertProps) return;

      if (!node) return;

      // We already measured. Let's not do it again
      if (positionStyles.position === 'absolute') return;

      const { left, top } = node.getBoundingClientRect();
      const { marginLeft, marginTop } = window.getComputedStyle(node);
      const floatMarginLeft = parseFloat(marginLeft);
      const floatMarginTop = parseFloat(marginTop);

      setPositionStyles({
        ...PaperProps?.style,
        left: left - floatMarginLeft,
        position: 'absolute',
        top: top - floatMarginTop,
      });
    },
    [alertProps, PaperProps, positionStyles],
  );

  const handleExited = (node: HTMLElement): void => {
    setPositionStyles({});
    onExited?.(node);
  };

  const handleClose: DialogProps['onClose'] = (event, reason) => {
    if (reason === 'backdropClick') {
      if (disableBackdropClick) return;

      onBackdropClick?.(event as MouseEvent);
    }

    if (reason === 'escapeKeyDown') {
      if (disableEscapeKeyDown) return;

      onEscapeKeyDown?.(event as KeyboardEvent);
    }

    onClose?.(event, reason);
  };

  return (
    <Dialog
      {...dialogProps}
      aria-describedby={contentId}
      aria-labelledby={titleId}
      BackdropProps={{ role: 'none' }}
      className={clsx(modalDialogClasses.root, className)}
      onClose={handleClose}
      PaperProps={{ ...PaperProps, ref: paperRef, style: positionStyles }}
      TransitionProps={{
        onEnter,
        onEntered,
        onEntering,
        onExit,
        onExited: handleExited,
        onExiting,
      }}
    >
      <ModalDialogTitle
        {...titleProps}
        className={clsx(modalDialogClasses.title, titleProps?.className)}
        id={titleId}
      >
        {title}
      </ModalDialogTitle>
      <DialogContent
        {...contentProps}
        className={clsx(modalDialogClasses.content, contentProps?.className)}
        id={contentId}
      >
        {isElement(content) ? (
          content
        ) : (
          <DialogContentText>{content}</DialogContentText>
        )}
      </DialogContent>
      <ModalDialogActions>
        <ModalDialogSecondaryActions actions={secondaryActions} />
        {dismissiveAction && (
          <ModalDialogAction disallowDisabled {...dismissiveAction} />
        )}
        {confirmationAction && <ModalDialogAction {...confirmationAction} />}
        {acknowledgementAction && (
          <ModalDialogAction {...acknowledgementAction} />
        )}
      </ModalDialogActions>
      <ModalDialogAlert {...alertProps} />
    </Dialog>
  );
};
