import { AxiosRequestConfig } from 'axios';

import { ExamProblemItem, ExamSettingsType, StudentShared } from '../shared';

export type { ExamSettingsType };

interface Show {
  format?: 'pdf';
}

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/student_exams/${string}.pdf` | `/student_exams/${string}`;
}

export enum StudentExamStatus {
  Unknown = 'Unknown',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Paused = 'Paused',
  TurnedIn = 'TurnedIn',
  Graded = 'Graded',
  NotGenerated = 'NotGenerated',
}

export interface StudentExamsShowResponse {
  id: string;
  hasFollowUpBeenStarted: boolean;
  isFollowUp: boolean;
  problems: ExamProblemItem[];
  status: StudentExamStatus;
  student: StudentShared;
  type: ExamSettingsType;
}

/**
 * GET /api/v2/student_exams/{id}
 */
export const show = (id: string, { format }: Show = {}): ShowConfig => {
  const url =
    format === 'pdf'
      ? (`/student_exams/${id}.pdf` as const)
      : (`/student_exams/${id}` as const);

  return {
    method: 'GET',
    url,
  };
};
