import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from './useSnackbar';

const hardRefresh = (): void => location.reload();

export function useAppUpdateSnackbar(): () => void {
  const [t] = useTranslation(['app']);
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    () =>
      enqueueSnackbar(t('app:error.codeUpdate'), {
        action: { label: t('app:refresh'), onClick: hardRefresh },
        persist: true,
        preventDuplicate: true,
      }),
    [enqueueSnackbar, t],
  );
}
