import { Channel, CreateMixin } from '@rails/actioncable';

import { useProfile } from '~/lib/auth';
import { useActionCable } from '~/lib/webSocket';

export const useLazyRosteringNotifications = <M extends CreateMixin>(
  handlers: M = {} as M,
): Channel<M> => {
  const { isGoogleClassroom, isClassLink, isClever } = useProfile();
  const isSSO = isGoogleClassroom || isClassLink || isClever;

  const channelConfig = isSSO ? { channel: 'LazyRosteringChannel' } : null;

  return useActionCable(channelConfig, handlers);
};
