import { AxiosRequestConfig } from 'axios';

export interface ClassAssignmentResponse {
  newMixedReviewSkills: number;
}
interface Create {
  assignmentId: string;
  releaseDate?: string;
}

interface CreateConfig extends AxiosRequestConfig {
  body: Create;
  data: Create;
  method: 'POST';
  url: `/classes/${string}/class_assignments`;
}

/**
 * Create or set as default
 *
 * POST /api/v2/classes/{id}/class_assignments
 *
 * { assignmentId: string, releaseDate: string }
 */
export const create = (classId: string, data: Create): CreateConfig => {
  return {
    method: 'POST',
    url: `/classes/${classId}/class_assignments`,
    body: data,
    data,
  } as const;
};
