import {
  Options,
  useVirtual as useReactVirtual,
  VirtualItem,
} from 'react-virtual';

import { UseResizeObserver, useResizeObserver } from '../use-resize-observer';

export type { VirtualItem };

// Taken from react-virtual's types since they don't export them...:eyeroll:
type ScrollAlignment = 'start' | 'center' | 'end' | 'auto';

interface ScrollToOptions {
  align: ScrollAlignment;
}

type ScrollToOffsetOptions = ScrollToOptions;
type ScrollToIndexOptions = ScrollToOptions;

const useObserver = <T extends Element>(
  ref: React.RefObject<T>,
): UseResizeObserver<any> => useResizeObserver(ref);

// Omits `useObserver`
export type VirtualOptions<T> = Omit<Options<T>, 'useObserver'>;

export interface UseVirtual {
  virtualItems: VirtualItem[];
  totalSize: number;
  scrollToOffset: (index: number, options?: ScrollToOffsetOptions) => void;
  scrollToIndex: (index: number, options?: ScrollToIndexOptions) => void;
  measure: () => void;
}

export const useVirtual = <T extends Element>(
  options: VirtualOptions<T>,
): UseVirtual =>
  useReactVirtual({
    ...options,
    useObserver,
  });
