import { AxiosRequestConfig } from 'axios';

interface DestroyConfig extends AxiosRequestConfig {
  method: 'DELETE';
  url: `/exams/${string}`;
}

/**
 * DELETE /api/v2/exams/{id}
 */
export const destroy = (id: string): DestroyConfig => {
  return {
    method: 'DELETE',
    url: `/exams/${id}`,
  } as const;
};
