import { Button, buttonClasses, styled, alpha } from '@mui/material';

export const DestructiveButton = styled(Button, { name: 'DestructiveButton' })(
  ({ theme }) => ({
    [`&.${buttonClasses.contained}`]: {
      color: theme.palette.getContrastText(theme.palette.error.main),
      backgroundColor: theme.palette.error.main,
      boxShadow: theme.shadows[2],
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
        boxShadow: theme.shadows[4],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: theme.shadows[2],
          backgroundColor: theme.palette.error.main,
        },
        [`&.${buttonClasses.disabled}`]: {
          backgroundColor: theme.palette.action.disabledBackground,
        },
      },
      [`&.${buttonClasses.focusVisible}`]: {
        boxShadow: theme.shadows[6],
      },
      '&:active': {
        boxShadow: theme.shadows[8],
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.action.disabled,
        boxShadow: theme.shadows[0],
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
    [`&.${buttonClasses.outlined}`]: {
      color: theme.palette.error.main,
      border: `1px solid ${alpha(theme.palette.error.main, 0.5)}`,
      '&:hover': {
        border: `1px solid ${theme.palette.error.main}`,
        backgroundColor: alpha(
          theme.palette.error.main,
          theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      [`&.${buttonClasses.disabled}`]: {
        border: `1px solid ${theme.palette.action.disabled}`,
      },
    },
    [`&.${buttonClasses.text}`]: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.error.main,
          theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.action.disabled,
      },
    },
  }),
);
