import { AxiosRequestConfig } from 'axios';

import { Cadence, KlassShared, StudentShared, Subject } from '../shared';

export interface ReportsKlassResponse {
  klass: KlassShared;
  results: ReportsKlassTier;
}

export interface ReportsKlassTier {
  key: string;
  name: string;
  cadence?: Cadence;
  children: ReportsKlassTier[];
  skills: ReportsKlassSkill[];
  students: ReportsKlassStudent[];
}

interface ReportsKlassSkill {
  id: string;
  name: string;
  strandKey: string;
  statementKey: string;
  standardKey: string;
}

export interface ReportsKlassStudent extends StudentShared {
  rating: number;
}

interface KlassConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/reports/klass/${string}`;
  params: { subject: Subject };
}

/**
 * GET /api/v2/reports/klass/{id}?subject={subject}
 */
export const klass = (id: string, subject: Subject): KlassConfig => {
  return {
    method: 'GET',
    params: { subject },
    url: `/reports/klass/${id}`,
  } as const;
};
