import { Checkbox, styled } from '@mui/material';
import { FC } from 'react';
import { HeaderProps } from 'react-table';

const Root = styled(Checkbox)(({ theme }) => ({
  margin: theme.spacing(-1, 0),
}));

export const SelectableHeader: FC<
  Pick<HeaderProps<any>, 'getToggleAllPageRowsSelectedProps'>
> = ({ getToggleAllPageRowsSelectedProps }) => (
  <Root {...getToggleAllPageRowsSelectedProps()} />
);
