import { AxiosRequestConfig } from 'axios';

interface Create {
  message?: string;
}

interface ToStudentInClassConfig extends AxiosRequestConfig {
  body: Create;
  data: Create;
  method: 'POST';
  url: `/student_in_classes/${string}/messages`;
}

/**
 * POST /api/v2/student_in_classes/{id}/messages
 *
 * { message: string }
 */
export const toStudentInClass = (
  studentInClassId: string,
  data: Create,
): ToStudentInClassConfig => {
  return {
    method: 'POST',
    url: `/student_in_classes/${studentInClassId}/messages`,
    body: data,
    data,
  } as const;
};
