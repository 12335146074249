import { FC } from 'react';

import { Grid } from '@gmm/ui';
import EmptyStateImage from '~/images/genericError.png';
import EmptyState from '~/lib/emptyState';

const emptyState = (
  <EmptyState
    headline="app:error.notFound.header"
    image={EmptyStateImage}
    subHeading="app:error.notFound.body"
  />
);

const NotFound: FC = () => (
  <Grid container data-testid="not-found" justifyContent="center">
    <Grid item xs={10}>
      {emptyState}
    </Grid>
  </Grid>
);

export default NotFound;
