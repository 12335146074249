/* istanbul ignore file */
/* eslint-disable */
import { User } from '~/lib/auth';

declare global {
  interface Window {
    intercomSettings: any;
    Intercom: any;
  }
}

const defaultSettings = {
  app_id: process.env.INTERCOM_APP_ID,
  current_version: process.env.GIT_TAG,
};

function init(): void {
  window.intercomSettings = { ...defaultSettings };

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        // TODO: Replace @ts-ignore (single-line) to block once this PR is merged in
        // https://github.com/Microsoft/TypeScript/issues/19573
        // @ts-ignore
        i.c(arguments);
      };
      // @ts-ignore
      i.q = [];
      // @ts-ignore
      i.c = function (args) {
        // @ts-ignore
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/xjwu5q6c';
        var x = d.getElementsByTagName('script')[0];
        // @ts-ignore
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
}

export function instantiateIntercom(): void {
  if (process.env.NODE_ENV === 'production') {
    init();
  }
}

export function setIntercomUser(user: User): void {
  const {
    acct_exp: accountExpiration,
    acct_type: accountType,
    email,
    name,
    idp,
    student_sharing,
    sub: id,
  } = user.profile;

  window.intercomSettings = {
    accountExpiration,
    accountType,
    email,
    id,
    idp,
    name,
    user_id: id,
    student_sharing,
    ...window.intercomSettings,
  };

  window.Intercom?.('boot', window.intercomSettings);
}

export function showIntercom(): void {
  window.Intercom?.('show');
}

export function shutdownIntercom(): void {
  window.intercomSettings = { ...defaultSettings };

  window.Intercom?.('shutdown');
}

export function trackIntercomEvent(event: string, metadata?: object): void {
  window.Intercom?.('trackEvent', event, metadata);
}

export function updateIntercomPageView(): void {
  /* istanbul ignore if */
  if (window.Intercom) {
    // This is the suggested strategy according to Intercom docs.
    // See GMM-1882 ticket for more info.
    const now = new Date().getTime() / 1000;
    window.Intercom('update', { last_request_at: now });
  }
}

export function updateUserAttributes(attributes: object): void {
  window.Intercom?.('update', attributes);
}
