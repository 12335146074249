import { FC, HTMLAttributes, ReactNode } from 'react';

import { ModalDialog, ConfirmationDialogProps } from '@gmm/ui';
import { idProps, ids as allIds } from '~/lib/constants';

const ids = allIds.modals.confirmation;
const confirmationIdProps = idProps(ids.confirm);
const dialogIdProps = idProps(ids.dialog);
const dismissiveIdProps = idProps(ids.deny);
const titleIdProps = idProps(ids.title);

interface Props
  extends Omit<
      ConfirmationDialogProps,
      'confirmationAction' | 'dismissiveAction'
    >,
    HTMLAttributes<HTMLDivElement> {
  confirmText: string;
  content: ReactNode;
  denyText: string;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  title: string;
}

export const ConfirmationModal: FC<Props> = ({
  confirmText,
  content,
  denyText,
  onClose,
  onConfirm,
  title,
  ...dialogProps
}) => (
  <ModalDialog
    {...dialogIdProps}
    {...dialogProps}
    confirmationAction={{
      ...confirmationIdProps,
      color: 'destructive',
      onClick: onConfirm,
      text: confirmText,
    }}
    content={content}
    dismissiveAction={{
      ...dismissiveIdProps,
      onClick: onClose,
      text: denyText,
    }}
    fullWidth
    onClose={onClose}
    title={title}
    titleProps={titleIdProps}
  />
);
