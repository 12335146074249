import { ExamTemplate } from '@gmm/sdk-voodoo/exercises';
import { isExamTemplateType } from '@gmm/ui';
import { ReadOnlyOptions } from '~/lib/sdk';
import { augmentSWR } from '~/lib/swr';

import { UseExercise, useExercise } from './useExercise';

interface Options extends ReadOnlyOptions {
  examTemplateId?: string;
}

interface UseExamTemplate extends UseExercise<ExamTemplate> {
  exam: ExamTemplate | undefined;
}

export const useExamTemplate = ({
  examTemplateId: exerciseId,
  readOnly,
}: Options = {}): UseExamTemplate => {
  return useExercise<ExamTemplate>({
    exerciseId,
    readOnly,
    use: [
      augmentSWR<ExamTemplate>('exam', ({ data }) =>
        isExamTemplateType(data) ? data : undefined,
      ),
    ],
  }) as UseExamTemplate;
};
