import { AxiosRequestConfig } from 'axios';

export type CorrectionsStatus =
  | 'COMPLETED'
  | 'IN_PROGRESS'
  | 'NOT_APPLICABLE'
  | 'NOT_AVAILABLE';

export enum CorrectionsStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotApplicable = 'NOT_APPLICABLE',
  NotAvailable = 'NOT_AVAILABLE',
}

export interface CorrectionsItem {
  sicId: number;
  firstName: string;
  lastName: string;
  status: CorrectionsStatus;
  errorsFixed?: number;
  errorsRequired?: number;
  practicePoints?: number;
  practiceRequired?: number;
}

export type CorrectionsResponse = CorrectionsItem[];

interface CorrectionsConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/exams/${string}/corrections`;
}

export const corrections = (id: string): CorrectionsConfig => {
  return {
    method: 'GET',
    url: `/exams/${id}/corrections`,
  } as const;
};
