import ReactGA from 'react-ga4';

import { DisplayNameFormat } from '~/lib/types';

// Common events, used across multiple categories
export const getNewSkillSample = (category: string): void => {
  previewSkill(category, 'get new sample');
};

export const setNameFormat = (
  format: DisplayNameFormat,
  category: string,
): void =>
  ReactGA.event({
    action: 'set name format',
    category,
    label: format,
  });

export const previewSkill = (category: string, label: string): void =>
  ReactGA.event({ action: 'preview skill', category, label });

export enum Labels {
  Cancel = 'cancel',
  Click = 'click',
  Submit = 'submit',
}
