import { LocationListener, LocationState } from 'history';
import { DependencyList, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useLatestRef } from '@gmm/ui';

import { getEmptyArray } from '../type-utils';

export const useLocationEffect = <S = LocationState>(
  callback: LocationListener<S>,
  deps: DependencyList = getEmptyArray(),
): void => {
  const history = useHistory<S>();
  const callbackRef = useLatestRef(callback);

  useEffect(() => {
    callbackRef.current(history.location, history.action);

    return history.listen(callbackRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackRef, history, ...deps]);
};
