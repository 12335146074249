import { AxiosRequestConfig } from 'axios';

import { SkillDiscoveryItem } from '../shared';

interface ShowConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/collections/${string}/collection_details/${string}`;
}

// TODO: Use this response
export type CollectionDetailsShowResponse = SkillDiscoveryItem[];

/**
 * GET /api/v2/collections/{gradeName}/collection_details/{collectionName}
 */
export const show = (
  selectedGrade: string,
  selectedCollection: string,
): ShowConfig => {
  return {
    method: 'GET',
    url: `/collections/${selectedGrade}/collection_details/${selectedCollection}`,
  } as const;
};
