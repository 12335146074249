import { eventFactory } from './utils';

const collapseSection = 'collapse section';
const expandSection = 'expand section';

const individualStudentDataEvent = eventFactory('individual student data');

export const toggleActiveSkillsSection = (expanded: boolean): void =>
  individualStudentDataEvent({
    action: expanded ? collapseSection : expandSection,
    label: 'active skills',
  });

export const toggleAllTimeSection = (expanded: boolean): void =>
  individualStudentDataEvent({
    action: expanded ? collapseSection : expandSection,
    label: 'all time',
  });

export const toggleColumn = (value: number, label?: string): void =>
  individualStudentDataEvent({ action: 'toggle column', label, value });
