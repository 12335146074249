interface ReportConfig {
  method: 'GET';
  url: `/classes/${string}/report`;
}

interface StudentReportSummaryPercentages {
  accuracy: number;
  mixedReview: number;
  proficiency: number;
}

export interface StudentReportSummary {
  attempts: number;
  errorsFixed: number;
  firstName: string;
  isInIndividualMixedReview: boolean;
  lastName: string;
  percentages: StudentReportSummaryPercentages;
  pointsEarned: number;
  studentInClassId: string;
  username: string;
}

export type KlassesReportResponse = StudentReportSummary[];

/**
 * GET /api/v2/classes/{id}/report
 */
export const report = (classId: string): ReportConfig => {
  return {
    method: 'GET',
    url: `/classes/${classId}/report`,
  } as const;
};
