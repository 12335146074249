import { LDUser } from 'launchdarkly-js-client-sdk';

import { TeacherMe } from '@gmm/sdk/shared';

/**
 * This converts the teacher to a Launch Darkly User format
 *
 * @see https://docs.launchdarkly.com/docs/js-sdk-reference#section-users
 */
export const toLDUser = (teacher?: TeacherMe | null): LDUser | undefined => {
  if (teacher) {
    return {
      custom: {
        org: teacher.organization.name,
        username: teacher.username,
      },
      email: teacher.email,
      key: teacher.id,
      name: `${teacher.firstName} ${teacher.lastName}`,
    };
  }

  // undefined so the default implementation will work
  return undefined;
};

// This is a constant for multivariate flags when it should be off
const NONE = 'none';

export const isFlagOff = (value?: any): boolean => {
  switch (typeof value) {
    case 'string':
      return value.toLowerCase() === NONE;
    case 'boolean':
      return false;
    default:
      return !value;
  }
};

const regexCoerce = /(^|[^\d])(\d{1,16})/;

export const coerce = (version: string | null): number => {
  const versionMatch = version && version.match(regexCoerce);

  return versionMatch === null ? 0 : Number(versionMatch[2]);
};
