import { AxiosRequestConfig } from 'axios';

import { StudentInKlassBase } from '../shared';

export type StudentInClass = StudentInKlassBase;

interface ListConfig extends AxiosRequestConfig {
  method: 'GET';
  url: `/classes/${string}/student_in_classes`;
}

export type StudentInKlassesIndexResponse = StudentInKlassBase[];

/**
 * GET /api/v2/classes/{id}/student_in_classes
 */
export const list = (classId: string): ListConfig => {
  return {
    method: 'GET',
    url: `/classes/${classId}/student_in_classes`,
  } as const;
};
