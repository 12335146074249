import { useMemo, useRef, useState } from 'react';
import {
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  PluginHook,
  Hooks,
  ColumnWithLooseAccessor,
  useFlexLayout,
  defaultOrderByFn,
  Row,
  SortByFn,
  useExpanded,
} from 'react-table';
// @ts-expect-error these are, for some reason, not public
import * as sortTypes from 'react-table/src/sortTypes';

import { ExpandButton, SelectableCell, SelectableHeader } from './components';
import { makePersistenceHook, useToCSV } from './hooks';
import { GridInstance, UseDataGridOptions } from './types';

export const expanderColumn: ColumnWithLooseAccessor<any> = {
  Cell: ExpandButton,
  disableSortBy: true,
  Header: '',
  hideable: false,
  id: 'expander',
  maxWidth: 54,
  width: 54,
};

const selectableColumn: ColumnWithLooseAccessor<any> = {
  Cell: SelectableCell,
  disableSortBy: true,
  Header: SelectableHeader,
  hideable: false,
  id: 'selection',
  maxWidth: 54,
  width: 54,
};

export const useDataGrid = <D extends object>({
  columns,
  data,
  forceSortBy,
  initialState = {},
  isDownloadable,
  isExpandable,
  isSelectable,
  isSortable,
  orderByFn = defaultOrderByFn,
  manualSortBy,
  sortTypes: userSortTypes,
  tableId,
  ...useTableOptions
}: UseDataGridOptions<D>): GridInstance<D> => {
  const initialStateRef = useRef({ ...initialState });
  const [usePersistence] = useState<PluginHook<D> | false>(() => {
    if (!tableId) return false;

    const { hiddenColumns, sortBy } = initialStateRef.current;

    delete initialStateRef.current.hiddenColumns;
    delete initialStateRef.current.sortBy;

    return makePersistenceHook(tableId, { hiddenColumns, sortBy });
  });
  const [hooks] = useState<Array<PluginHook<D>>>(() =>
    [
      useFlexLayout,
      isSortable ? useSortBy : false,
      isExpandable ? useExpanded : false,
      ...(isSelectable
        ? [
            usePagination,
            useRowSelect,
            (hooks: Hooks<D>) => {
              hooks.visibleColumns.push(columns => {
                const index = columns[0].id === expanderColumn.id ? 1 : 0;

                return [
                  ...columns.slice(0, index),
                  selectableColumn,
                  ...columns.slice(index),
                ];
              });
            },
          ]
        : []),
      usePersistence,
      isDownloadable ? useToCSV : false,
    ].filter((hook: unknown): hook is PluginHook<D> => !!hook),
  );

  const { allColumns, flatRows, rows, ...table } = useTable<D>(
    {
      disableSortRemove: true,
      ...useTableOptions,
      columns,
      data,
      initialState: {
        ...initialStateRef.current,
        pageSize: data.length || 1,
      },
      manualSortBy: manualSortBy || !!forceSortBy,
      orderByFn,
      sortTypes: userSortTypes,
    },
    ...hooks,
  );

  // Adapted from https://github.com/tannerlinsley/react-table/blob/8c77b4ad97353a0b1f0746be5b919868862a9dcc/src/plugin-hooks/useSortBy.js#L272-L358
  const [sortedRows, sortedFlatRows] = useMemo(() => {
    if (!forceSortBy) return [rows, flatRows];

    const sortedFlatRows: Array<Row<D>> = [];

    // Filter out sortBys that correspond to non existing columns
    const availableSortBy = forceSortBy.filter(sort =>
      allColumns.find(col => col.id === sort.id),
    );

    const sortData = (rows: Array<Row<D>>): Array<Row<D>> => {
      // Use the orderByFn to compose multiple sortBy's together.
      // This will also perform a stable sorting using the row index
      // if needed.
      const sortedData = orderByFn(
        rows,
        availableSortBy.map(sort => {
          // Support custom sorting methods for each column
          const column = allColumns.find(d => d.id === sort.id);

          /* istanbul ignore if */
          if (!column) {
            throw new Error(
              `React-Table: Could not find a column with id: ${sort.id} while sorting`,
            );
          }

          const { sortType } = column;

          // Look up sortBy functions in this order:
          // column function
          // column string lookup on user sortType
          // column string lookup on built-in sortType
          // default function
          // default string lookup on user sortType
          // default string lookup on built-in sortType
          /* istanbul ignore next */
          const sortMethod =
            typeof sortType === 'function'
              ? sortType
              : sortType
              ? (userSortTypes || {})[sortType] ||
                (sortTypes as Record<string, SortByFn<D>>)[sortType]
              : null;

          /* istanbul ignore if */
          if (!sortMethod) {
            throw new Error(
              `React-Table: Could not find a valid sortType of '${sortType}' for column '${sort.id}'.`,
            );
          }

          // Return the correct sortFn.
          // This function should always return in ascending order
          return (a, b) => sortMethod(a, b, sort.id, sort.desc);
        }),
        // Map the directions
        availableSortBy.map(sort => {
          // Detect and use the sortInverted option
          const column = allColumns.find(d => d.id === sort.id);

          /* istanbul ignore if */
          if (column && column.sortInverted) {
            return !!sort.desc;
          }

          return !sort.desc;
        }),
      );

      // If there are sub-rows, sort them
      sortedData.forEach(row => {
        sortedFlatRows.push(row);

        /* istanbul ignore else */
        if (!row.subRows || row.subRows.length === 0) {
          return;
        }

        row.subRows = sortData(row.subRows);
      });

      return sortedData;
    };

    return [sortData(rows), sortedFlatRows];
  }, [forceSortBy, rows, flatRows, allColumns, orderByFn, userSortTypes]);

  return {
    ...table,
    allColumns,
    flatRows: sortedFlatRows,
    rows: sortedRows,
    sortedRows,
    sortedFlatRows,
  };
};
