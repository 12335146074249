import { createSvgIcon } from '@mui/material';
import { FC } from 'react';

interface WrapperProps {
  text?: string;
}

const Wrapper: FC<WrapperProps> = ({ text }) => {
  return (
    <g width="30" height="30">
      <circle cx="11" cy="11" r="11" />
      <text x="7" y="15" fill="black" fontSize="14px">
        {text}
      </text>
    </g>
  );
};

export const ReadinessIcon = createSvgIcon(<Wrapper text="R" />, 'Readiness');
export const SupportingIcon = createSvgIcon(<Wrapper text="S" />, 'Supporting');
export const NeverIcon = createSvgIcon(<Wrapper />, 'Never');
