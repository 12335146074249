import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { createTheme, ThemeProvider } from '@mui/material';
import { match } from 'css-mediaquery';
import { SnackbarProvider } from 'notistack';
import { FC, FunctionComponent } from 'react';

import { mobiusTheme } from '../theme/theme';

export const testTheme = createTheme(mobiusTheme, {
  components: {
    MuiDateRangePicker: {
      defaultProps: {
        desktopModeMediaQuery: '@media (min-width:1px)',
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 0,
        enterNextDelay: 0,
        leaveDelay: 0,
      },
    },
  },
});
export type TestTheme = typeof testTheme;

const NoTransition: FC = ({ children }) => <>{children}</>;

const cache = createCache({ key: 'css', prepend: true, stylisPlugins: [] });

/**
 * @see https://github.com/mui-org/material-ui/issues/24894
 *
 * Turns off warnings that are specific to server-side rendering
 */
cache.compat = true;

export const TestProvider: FunctionComponent = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterLuxon}>
    <CacheProvider value={cache}>
      <ThemeProvider theme={testTheme}>
        <SnackbarProvider TransitionComponent={NoTransition}>
          {children}
        </SnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
  </LocalizationProvider>
);

if (process.env.NODE_ENV === 'test') {
  const createMatchMedia = (
    width: number,
  ): ((query: string) => MediaQueryList) => {
    return query =>
      ({
        matches: match(query, {
          width,
        }),
        addListener: () => {},
        removeListener: () => {},
      } as unknown as MediaQueryList);
  };

  const previousMatchMedia = window.matchMedia;

  beforeAll(() => {
    window.matchMedia = createMatchMedia(100);
  });

  afterAll(() => {
    window.matchMedia = previousMatchMedia;
  });
}
