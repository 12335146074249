import { AxiosRequestConfig } from 'axios';

export interface StudentWithPassword {
  firstName: string;
  id: string;
  lastName: string;
  password: string;
  studentSourced: boolean;
  username: string;
}

export interface CreateStudentPasswordsResponse {
  activateBy: string;
  students: StudentWithPassword[];
}

interface CreateConfig extends AxiosRequestConfig {
  method: 'POST';
  url: `/classes/${string}/student_passwords`;
}

/**
 * POST /api/v2/classes/{id}/student_passwords
 */
export const create = (classId: string): CreateConfig => {
  return {
    method: 'POST',
    url: `/classes/${classId}/student_passwords`,
  } as const;
};
