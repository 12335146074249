import { Assignment } from '@gmm/sdk-voodoo/exercises';
import { isAssignmentType } from '@gmm/ui';
import { ReadOnlyOptions } from '~/lib/sdk';
import { augmentSWR } from '~/lib/swr';

import { UseExercise, useExercise } from './useExercise';

interface Options extends ReadOnlyOptions {
  assignmentId?: string;
}

interface UseAssignment extends UseExercise<Assignment> {
  assignment: Assignment | undefined;
}

export const useAssignment = ({
  assignmentId: exerciseId,
  readOnly,
}: Options = {}): UseAssignment => {
  return useExercise<Assignment>({
    exerciseId,
    readOnly,
    use: [
      augmentSWR<Assignment>('assignment', ({ data }) =>
        isAssignmentType(data) ? data : undefined,
      ),
    ],
  }) as UseAssignment;
};
