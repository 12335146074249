import { AxiosRequestConfig } from 'axios';

interface Update {
  firstName: string;
  lastName: string;
  schoolAssignedId: string;
  username: string;
}

interface UpdateConfig extends AxiosRequestConfig {
  body: Update;
  data: Update;
  method: 'PATCH';
  url: `/students/${string}`;
}

/**
 * PATCH /api/v2/students/{id}
 *
 * { firstName: string, lastName: string, schoolAssignedId: string, username: string, }
 */
export const update = (id: string, data: Update): UpdateConfig => {
  return {
    method: 'PATCH',
    url: `/students/${id}`,
    body: data,
    data,
  } as const;
};
