import React, { forwardRef, FC } from 'react';

import { Dialog, Slide, SlideProps } from '@gmm/ui';
import { ExamplePreview } from '~/work/lib/examplePreview';

import { useFullscreenContext } from '../context';

import { FullscreenHeading } from './heading';
import { StudentDetailFullscreenPreview } from './studentDetailFullscreenPreview';

const Transition = forwardRef(function Transition(
  props: SlideProps,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FullscreenPreview: FC = () => {
  const {
    currentType,
    description,
    isModalOpen,
    showNextSibling,
    showPreviousSibling,
    studentProblemData,
    title,
  } = useFullscreenContext();

  return (
    <Dialog fullScreen open={isModalOpen} TransitionComponent={Transition}>
      <FullscreenHeading
        description={description}
        showNextSibling={showNextSibling}
        showPreviousSibling={showPreviousSibling}
        title={title}
      />
      {studentProblemData ? (
        <StudentDetailFullscreenPreview problemData={studentProblemData} />
      ) : (
        <ExamplePreview skillType={currentType} isPreviewModal />
      )}
    </Dialog>
  );
};
