import {
  CircularProgress,
  circularProgressClasses,
  IconButton,
  IconButtonProps,
  InputBaseComponentProps,
  styled,
  useForkRef,
} from '@mui/material';
import { uniqueId } from 'lodash';
import {
  ChangeEventHandler,
  FC,
  forwardRef,
  MouseEventHandler,
  useRef,
  useState,
} from 'react';

import { Close, Search } from '@gmm/icons';

import { dispatchChangeEvent } from '../utils';

const Root = styled('div')({
  '&&': {
    alignItems: 'center',
    display: 'inline-flex',
    flex: 1,
  },
});

const Icon = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
  position: 'relative',
  top: theme.spacing(0.25),
}));

const Loading = styled(CircularProgress)({
  [`& .${circularProgressClasses.svg}`]: {
    height: '100%',
    width: '100%',
  },
});

const Input = styled('input')({
  '&&': {
    background: 'none',
    border: 0,
    font: 'inherit',
    letterSpacing: 'inherit',
    padding: 0,
    '&:focus': { outline: 0 },
  },
});

const UIButton: FC<Omit<IconButtonProps<'label'>, 'component'>> = props => (
  <IconButton {...props} component="label" />
);

interface CloseButtonProps {
  isVisible: boolean;
}

const CloseButton = styled(UIButton, {
  shouldForwardProp: prop => prop !== 'isVisible',
})<CloseButtonProps>(({ isVisible, theme }) => ({
  opacity: isVisible ? 1 : 0,
  pointerEvents: isVisible ? 'all' : 'none',
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
    easing: theme.transitions.easing.easeInOut,
  }),
}));

export interface SearchInputProps extends InputBaseComponentProps {
  classes?: Partial<Record<'searchIcon', string>>;
  closeButtonProps?: Omit<IconButtonProps<'label'>, 'component'>;
  isSearching?: boolean;
  persistClose?: boolean;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  function SearchInput(
    {
      className,
      classes,
      closeButtonProps,
      id: idProp,
      isSearching = false,
      onChange,
      persistClose = false,
      ...inputProps
    },
    inputRefProp,
  ) {
    const [id] = useState(() => idProp ?? uniqueId('search-input'));
    const inputRef = useRef<HTMLInputElement | null>(null);
    const ref = useForkRef(inputRefProp, inputRef);
    const [showClose, setShowClose] = useState(
      () => !!inputRef.current?.value || persistClose,
    );

    const handleClear: MouseEventHandler<HTMLLabelElement> = event => {
      closeButtonProps?.onClick?.(event);

      /* istanbul ignore else */
      if (inputRef.current) {
        // This will call the `onChange` handler. It will add a `detail` to the
        // event of 'clear-search', so that, if you need to know, you can tell
        // the difference between a user-initiated keystroke change and the
        // clear search button was clicked
        dispatchChangeEvent(inputRef.current, '', 'clear-search');
      }
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
      if (!persistClose) {
        setShowClose(!!event.currentTarget.value);
      }

      onChange?.(event);
    };

    return (
      <Root className={className}>
        <Icon>
          {isSearching ? (
            <Loading size={24} />
          ) : (
            <Search className={classes?.searchIcon} />
          )}
        </Icon>

        <Input
          {...inputProps}
          autoComplete="off"
          className={className}
          id={id}
          onChange={handleChange}
          ref={ref}
          role="searchbox"
        />
        <CloseButton
          aria-hidden={!showClose}
          aria-label="x"
          {...closeButtonProps}
          className={closeButtonProps?.className}
          isVisible={showClose}
          onClick={handleClear}
          role=""
        >
          <Close />
        </CloseButton>
      </Root>
    );
  },
);
